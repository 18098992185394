import { FC } from 'react';

import { EmptyCellContentStyled } from './EmptyCellRenderer.styled';

interface IEmptyCellProps {
  contentText?: string;
}

export const EmptyCell: FC<IEmptyCellProps> = ({ contentText = '-' }) => (
  <EmptyCellContentStyled>{contentText}</EmptyCellContentStyled>
);
