import type { TRootState } from 'store';
import type { ITableSelector } from 'interfaces/Table/selector.interface';
import {
  itemsPerPageDefault,
  currentPageDefault,
} from 'redux/table/consts/tableDefaults.consts';
import { createTableSelectorSlice } from 'redux/table/helper/createTableSelector.slice';

export const clientLoginMissions = createTableSelectorSlice(
  'paginatedClientLoginMissions',
  currentPageDefault,
  itemsPerPageDefault,
  { columnKey: 'start', direction: 'desc' }
);

const { actions, reducer } = clientLoginMissions;

export const {
  setPagination,
  setNextPage,
  setSorting,
  setItemsPerPage,
  setFilters,
  setInitialFiltersSet,
  setMenuColumnList,
} = actions;

export const paginatedClientLoginMissionsSelector = (
  state: TRootState
): ITableSelector => state.tableClientLoginMissions;

export default reducer;
