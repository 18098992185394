import {
  EndpointBuilder,
  QueryDefinition,
  MutationDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type { IWTAEntry } from 'interfaces/WTAEntry.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import type {
  IGetWTAEntriesCollectionArgs,
  IGetWTAEntriesCollectionResponse,
  IGetWTAEntryItemArgs,
  IWTAEntryItemDeleteArgs,
  IWTAEntryItemPostArgs,
  IWTAEntryItemPutArgs,
} from './wtaEntries.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';

const defaultTotalCount = 0;
const defaultItems: IWTAEntry[] = [];

export const getWTAEntries = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetWTAEntriesCollectionArgs,
  BaseQueryFn,
  string,
  IGetWTAEntriesCollectionResponse
> =>
  build.query<IGetWTAEntriesCollectionResponse, IGetWTAEntriesCollectionArgs>({
    providesTags: ['WTAEntry'],
    query: (queryArg: IGetWTAEntriesCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);
      return {
        url: `api/wta_entries${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IWTAEntry>
    ): IGetWTAEntriesCollectionResponse => {
      const totalCount = response['hydra:totalItems'] || defaultTotalCount;
      const items = response['hydra:member'] || defaultItems;
      return { items, totalCount };
    },
  });

export const getWTAEntryItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<IGetWTAEntryItemArgs, BaseQueryFn, string, IWTAEntry> =>
  build.query<IWTAEntry, IGetWTAEntryItemArgs>({
    providesTags: ['WTAEntry'],
    query: (queryArg: IGetWTAEntryItemArgs) => ({
      url: `api/wta_entries/${queryArg.id}`,
    }),
  });

export const postWTAEntries = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IWTAEntryItemPostArgs, BaseQueryFn, string, IWTAEntry> =>
  build.mutation<IWTAEntry, IWTAEntryItemPostArgs>({
    invalidatesTags: ['WTAEntry'],
    query: (queryArg) => ({
      url: `api/wta_entries`,
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const putWTAEntries = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IWTAEntryItemPutArgs, BaseQueryFn, string, IWTAEntry> =>
  build.mutation<IWTAEntry, IWTAEntryItemPutArgs>({
    invalidatesTags: ['WTAEntry'],
    query: (queryArg) => ({
      url: `api/wta_entries/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });

export const deleteWTAEntryItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IWTAEntryItemDeleteArgs, BaseQueryFn, string, unknown> =>
  build.mutation<unknown, IWTAEntryItemDeleteArgs>({
    invalidatesTags: ['WTAEntry'],
    query: (queryArg) => ({
      url: `api/wta_entries/${queryArg.id}`,
      method: 'DELETE',
    }),
  });
