import { useCallback, useState } from 'react';

import { IGroupKeyState } from 'components/Timeline/Timeline.type';

export interface IUseGroupKeysReturn {
  groupToggle: () => void;
  groupKeys: IGroupKeyState;
}

export const useGroupKeys = (
  initialValue?: IGroupKeyState
): IUseGroupKeysReturn => {
  const defaultGroupKeys: IGroupKeyState = {
    mainGroupKey: 'area',
    subGroupKey: 'position',
  };

  const [groupKeys, setGroupKeys] = useState<IGroupKeyState>(
    initialValue || defaultGroupKeys
  );

  const groupToggle = useCallback(() => {
    setGroupKeys((prevState) => ({
      mainGroupKey: prevState.mainGroupKey === 'area' ? 'position' : 'area',
      subGroupKey: prevState.subGroupKey === 'area' ? 'position' : 'area',
    }));
  }, []);

  return {
    groupToggle,
    groupKeys,
  };
};
