import { useCallback } from 'react';

interface IUseOpenMapsReturn {
  getMapDirUrl: (address: string, mapsType?: TMapsUrlType) => URL;
  openMapDirection: (address: string) => void;
  openMapSearch: (address: string) => void;
}

type TMapsUrlType = 'dir' | 'search';

export const useOpenMaps = (): IUseOpenMapsReturn => {
  const getMapDirUrl = useCallback(
    (address: string, mapsType: TMapsUrlType = 'dir'): URL => {
      const mapsUrl = new URL(`https://www.google.com/maps/${mapsType}/`);
      mapsUrl.searchParams.append('api', '1');
      if (mapsType === 'search') {
        mapsUrl.searchParams.append('query', address);
      }
      if (mapsType === 'dir') {
        mapsUrl.searchParams.append('destination', address);
      }
      return mapsUrl;
    },
    []
  );

  const openMapDirection = useCallback(
    (address: string) => {
      const newWindow = window.open(
        getMapDirUrl(address),
        '_blank',
        'noopener,noreferrer'
      );
      if (newWindow) newWindow.opener = null;
    },
    [getMapDirUrl]
  );

  const openMapSearch = useCallback(
    (address: string) => {
      const newWindow = window.open(
        getMapDirUrl(address, 'search'),
        '_blank',
        'noopener,noreferrer'
      );
      if (newWindow) newWindow.opener = null;
    },
    [getMapDirUrl]
  );

  return {
    openMapDirection,
    openMapSearch,
    getMapDirUrl,
  };
};
