import { useCallback, useEffect, useRef, useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Icons, Input } from 'team-hero-ui';

import { searchSelector } from 'redux/search/search.slice';
import { useAppSelector } from 'store/hooks';
import useKey from 'hooks/keys/useKey.hook';
import { Tabs, TTabsView } from './components/Tabs/Tabs';
import {
  CloseStyled,
  ContainerWrapper,
  InputStyled,
  WrapperStyled,
} from './SearchPopup.styled';
import { MissionsTab } from './SearchTabs/Missions.tab';
import { useSearchDebounce } from './hooks/useDebounce';

interface ISearchPopupProps {
  onClose: () => void;
  displayOpenAnimation: boolean;
  displayCloseAnimation: boolean;
}

export const SearchPopup: FC<ISearchPopupProps> = ({
  displayCloseAnimation,
  displayOpenAnimation,
  onClose,
}) => {
  const { t } = useTranslation('general');
  const { initialSearch } = useAppSelector(searchSelector);

  const [selectedTabValue, onSelectTab] = useState<TTabsView>('new');
  const [searchValue, setSearchValue] = useState(initialSearch);

  const [viewportHeight, setViewportHeight] = useState(
    window.visualViewport?.height
  );
  const [viewportMargin, setViewportMargin] = useState(0);

  const debouncedSearch = useSearchDebounce(searchValue);
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (displayOpenAnimation) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 400); // wait for animation to finish
      setSearchValue(initialSearch);
    }
  }, [displayOpenAnimation, initialSearch]);

  useEffect(() => {
    if (displayCloseAnimation) {
      setSearchValue('');
      inputRef.current?.blur();
    }
  }, [displayCloseAnimation]);

  const handleClose = useCallback(() => {
    setSearchValue('');
    onClose();
  }, [onClose]);

  const { isOpen } = useAppSelector(searchSelector);

  useKey('Escape', handleClose, isOpen);

  // shrink the viewport when virtual keyboard opens
  useEffect(() => {
    const updateViewportHeight = () => {
      setViewportHeight(window.visualViewport?.height);
    };

    const updateViewportMargin = () => {
      setViewportMargin(window.visualViewport?.offsetTop || 0);
    };

    window.visualViewport?.addEventListener('resize', updateViewportHeight);
    window.visualViewport?.addEventListener('scroll', updateViewportMargin);

    return () => {
      window.visualViewport?.removeEventListener(
        'resize',
        updateViewportHeight
      );
      window.visualViewport?.removeEventListener(
        'resize',
        updateViewportMargin
      );
    };
  }, []);

  return (
    <WrapperStyled
      $displayCloseAnimation={displayCloseAnimation}
      $displayOpenAnimation={displayOpenAnimation}
      data-test-id={'search-popup'}
      style={{
        height: viewportHeight || '100%',
        marginTop: `${viewportMargin || 0}px`,
      }}
      ref={wrapperRef}
    >
      <CloseStyled>
        <IconButton
          styleType='white'
          onClick={handleClose}
          data-test-id={'close-button'}
        >
          <Icons.CloseIcon svgSize={24} />
        </IconButton>
      </CloseStyled>
      <ContainerWrapper>
        <InputStyled>
          <Tabs onSelectTab={onSelectTab} selectedTabValue={selectedTabValue} />
          <Input
            name='search'
            type='search'
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={t('searchJobs')}
            width='100%'
            inputRef={inputRef}
            data-test-id={'search-input'}
          />
        </InputStyled>
        <MissionsTab
          searchValue={debouncedSearch}
          selectedTab={selectedTabValue}
          onClose={handleClose}
        />
      </ContainerWrapper>
    </WrapperStyled>
  );
};
