import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const RangeTimelineControlsStyled = styled.div`
  ${flexFn()}
  gap: ${pxToRem(12)};
  width: auto;
`;
