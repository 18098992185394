import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

export const OuterWrapperStyled = styled.div`
  overflow-x: auto;
  flex: 1;
  width: 100%;
  max-height: 100%;
  padding-right: ${pxToRem(8)};
`;

export const InnerWrapperStyled = styled.div`
  min-height: ${pxToRem(320)};
  gap: ${pxToRem(4)};
  ${flexFn('flex-start', 'stretch', 'column')}
`;

export const LoadMoreStyled = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: ${pxToRem(112)};
  width: 100%;
  z-index: 10;
`;

export const LoadMoreTopStyled = styled(LoadMoreStyled)`
  top: 0;
  bottom: auto;
`;

export const TriggerDivStyled = styled.div`
  height: ${pxToRem(1)};
`;

export const ButtonWrapper = styled.div`
  ${flexFn('center', 'center', 'column')}
  margin: ${pxToRem(16)};
`;

export const MessageInnerWrapperStyled = styled.div`
  min-height: 100%;
  ${flexFn('flex-end', 'stretch', 'column')};
  padding: ${pxToRem(16)};
  width: 100%;
`;

export const ScrollToBottomAnchor = styled.div`
  height: ${pxToRem(1)};
`;
