import { createAction, PayloadAction } from '@reduxjs/toolkit';

import type { TRootState } from 'store';
import type { ISelector } from 'interfaces/Selector.interface';
import type { ISimpleFilter } from 'hooks/useFilters.hook';
import type { ISorting } from 'hooks/useSort.hook';
import { createSelectorSlice } from 'redux/collections/helper/createSelector.slice';
import {
  currentPageDefault,
  itemsPerPageDefault,
} from 'redux/collections/consts/selectorDefaults.consts';

export const setNestedFilters = createAction<
  ISimpleFilter[],
  'projectMissions/setNestedFilters'
>('projectMissions/setNestedFilters');

export const setNestedSorting = createAction<
  ISorting,
  'projectMissions/setNestedSorting'
>('projectMissions/setNestedSorting');

export const projectMissionSlice = createSelectorSlice(
  'projectMissions',
  currentPageDefault,
  itemsPerPageDefault,
  undefined,
  (builder) => {
    builder.addCase(
      setNestedFilters,
      (state: ISelector, action: PayloadAction<ISimpleFilter[]>) => {
        const param = new URL(location.href).searchParams.get('view') || 'new';

        state.nestedFilters[param] = action.payload;
        state.initialFiltersSet = true;
      }
    );
    builder.addCase(
      setNestedSorting,
      (state: ISelector, action: PayloadAction<ISorting>) => {
        const param = new URL(location.href).searchParams.get('view') || 'new';

        state.nestedSorting[param] = action.payload;
      }
    );
  }
);

const { actions, reducer } = projectMissionSlice;

export const {
  setPagination,
  setNextPage,
  setSorting,
  setItemsPerPage,
  setFilters,
} = actions;

export const paginatedProjectMissionSelector = (
  state: TRootState
): ISelector => ({
  ...state.projectMission,
  filters: !state.projectMission.filters.length
    ? [
        ...state.projectMission.filters,
        {
          key: 'heroStatus',
          operator: 'AND',
          value: new URL(location.href).searchParams.get('view') || 'new',
        },
      ]
    : state.projectMission.filters,
});

export default reducer;
