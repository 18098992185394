import {
  EndpointBuilder,
  MutationDefinition,
  QueryDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type { IJobAd } from 'interfaces/JobAd.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';
import type {
  IGetJobAdCollectionArgs,
  IGetJobAdCollectionResponse,
  IJobAdArgs,
  IJobAdDeleteArgs,
  IJobAdPostArgs,
  IJobAdPutArgs,
  IJobAdPutStatusArgs,
} from './jobAds.api.type';

export const getPublicApplicationJobAds = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetJobAdCollectionArgs,
  BaseQueryFn,
  string,
  IGetJobAdCollectionResponse
> =>
  build.query<IGetJobAdCollectionResponse, IGetJobAdCollectionArgs>({
    providesTags: ['JobAd'],
    query: (queryArg: IGetJobAdCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);
      return {
        url: `api/public_jobads${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IJobAd>
    ): IGetJobAdCollectionResponse =>
      transformCollectionResponse<IJobAd>(response),
  });

export const getJobAdItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<IJobAdArgs, BaseQueryFn, string, IJobAd> =>
  build.query<IJobAd, IJobAdArgs>({
    providesTags: ['JobAd'],
    query: (queryArg: IJobAdArgs) => ({
      url: `api/application_job_ads/${queryArg.id}`,
    }),
  });

export const postJobAdItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IJobAdPostArgs, BaseQueryFn, string, IJobAd> =>
  build.mutation<IJobAd, IJobAdPostArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['JobAd'],
    query: (queryArg) => ({
      url: `api/application_job_ads`,
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const putJobAdItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IJobAdPutArgs, BaseQueryFn, string, IJobAd> =>
  build.mutation<IJobAd, IJobAdPutArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['JobAd'],
    query: (queryArg) => ({
      url: `api/application_job_ads/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });

export const putJobAdItemStatus = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IJobAdPutStatusArgs, BaseQueryFn, string, IJobAd> =>
  build.mutation<IJobAd, IJobAdPutStatusArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['JobAd'],
    query: (queryArg) => ({
      url: `api/application_job_ads/${queryArg.id}`,
      method: 'PUT',
      body: {
        status: queryArg.status,
      },
    }),
  });

export const deleteJobAdItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IJobAdDeleteArgs, BaseQueryFn, string, unknown> =>
  build.mutation<unknown, IJobAdDeleteArgs>({
    invalidatesTags: ['JobAd'],
    query: (queryArg) => ({
      url: `api/application_job_ads/${queryArg.id}`,
      method: 'DELETE',
    }),
  });
