import { FC, useEffect } from 'react';

import { useCurrentUser } from 'hooks/useCurrentUser';
import { useNativePushNotifications } from './useNativePushNotifications.hooks';

const NativePushNotificationsProvider: FC = () => {
  const user = useCurrentUser();
  const { registerNotifications, addEventListeners } =
    useNativePushNotifications();

  // register event listeners for push notifications
  useEffect(() => {
    if (!user.isLoggedIn) {
      return;
    }
    addEventListeners().then(() => {
      registerNotifications();
    });
  }, [addEventListeners, registerNotifications, user.isLoggedIn]);

  return null;
};

export default NativePushNotificationsProvider;
