import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import createFileUrl from 'helpers/file/getFile.helper';
import useDownloadFile from 'hooks/useDownloadFile.hook';
import { useErrorHandler } from 'hooks/useDisplayApiError.hook';

interface IUseGetFileReturn {
  isFetching: boolean;
  getFile: () => void;
}

const useGetFile = (
  fileName?: string | null,
  fileSource?: string | null
): IUseGetFileReturn => {
  const { t } = useTranslation('errors');
  const [isFetching, setIsFetching] = useState(false);
  const { triggerFileUrlDownload } = useDownloadFile();
  const { handleError } = useErrorHandler();

  const getFile = useCallback(async () => {
    if (fileSource && fileName) {
      setIsFetching(true);
      const url = fileSource.replace('/api/', 'api/');
      try {
        const fileUrl = await createFileUrl({ apiRequestUrl: url });
        await triggerFileUrlDownload(fileUrl, fileName);
      } catch (e) {
        handleError(e, t('action.downloadFileError'));
      } finally {
        setIsFetching(false);
      }
    } else {
      handleError(t('action.downloadFileError'));
    }
  }, [fileName, fileSource, handleError, t, triggerFileUrlDownload]);

  return { isFetching, getFile };
};

export default useGetFile;
