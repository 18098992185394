import {
  EndpointBuilder,
  MutationDefinition,
  QueryDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type { IStopwatch } from 'interfaces/Stopwatch.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import type {
  IGetStopwatchesCollectionArgs,
  IGetStopwatchesCollectionResponse,
  IGetStopwatchItemArgs,
  IStopwatchPostArgs,
  IStopwatchPutArgs,
} from './stopwatches.types';

const defaultTotalCount = 0;
const defaultItems: IStopwatch[] = [];

export const getStopwatches = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetStopwatchesCollectionArgs,
  BaseQueryFn,
  string,
  IGetStopwatchesCollectionResponse
> =>
  build.query<IGetStopwatchesCollectionResponse, IGetStopwatchesCollectionArgs>(
    {
      providesTags: ['Stopwatch'],
      query: (queryArg: IGetStopwatchesCollectionArgs) => {
        const paginationParamObject = {
          page: queryArg.page,
          itemsPerPage: queryArg.itemsPerPage,
          pagination: queryArg.pagination,
          ...queryArg.order,
        };
        const paginationQuery = serializeFlatObject(paginationParamObject, '?');
        const filterQuery = serializeFilters(queryArg.filters);
        return {
          url: `/api/project_stopwatches${paginationQuery}${filterQuery}`,
        };
      },
      transformResponse: (
        response: ICollectionApiResponse<IStopwatch>
      ): IGetStopwatchesCollectionResponse => {
        const totalCount =
          (response['hydra:totalItems'] as number) || defaultTotalCount;
        const items =
          (response['hydra:member'] as IStopwatch[]) || defaultItems;
        return { items, totalCount };
      },
    }
  );

export const getStopwatchItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<IGetStopwatchItemArgs, BaseQueryFn, string, IStopwatch> =>
  build.query<IStopwatch, IGetStopwatchItemArgs>({
    providesTags: ['Stopwatch'],
    query: (queryArg: IGetStopwatchItemArgs) => ({
      url: `/api/project_stopwatches/${queryArg.id}`,
    }),
  });

export const postStopwatchItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IStopwatchPostArgs, BaseQueryFn, string, IStopwatch> =>
  build.mutation<IStopwatch, IStopwatchPostArgs>({
    invalidatesTags: (_result, _error, args) =>
      !!args.skipInvalidateTags ? [] : ['Stopwatch', 'ProjectShift'],
    query: (queryArg) => ({
      url: '/api/project_stopwatches',
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const putStopwatchItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IStopwatchPutArgs, BaseQueryFn, string, IStopwatch> =>
  build.mutation<IStopwatch, IStopwatchPutArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['Stopwatch', 'ProjectShift'],
    query: (queryArg) => ({
      url: `/api/project_stopwatches/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });
