import { ReactElement, useCallback, useMemo, useState } from 'react';
import { SidePopup } from 'team-hero-ui';

import { useGetContactItemQuery } from 'services/teamHeroApi.service';
import { useToggle } from 'hooks/useToggle.hook';
import ApplicationSedcard from './ApplicationSedcard';
import { IShiftApplication } from 'interfaces/ShiftApplication.interface';

interface IOpenApplicationSedcardModalArgs {
  application: IShiftApplication;
  missionId: number;
  contactConnectedApplicationIds: number[];
}

interface IUseApplicationSedcardProps {
  allApplications: IShiftApplication[];
}

interface IUseApplicationSedcardReturn {
  applicationSedcardModal: ReactElement;
  openApplicationSedcardModal: (args: IOpenApplicationSedcardModalArgs) => void;
}

const useApplicationSedcard = ({
  allApplications,
}: IUseApplicationSedcardProps): IUseApplicationSedcardReturn => {
  const { toggleValue: isOpen, on: openModal, off: closeModal } = useToggle();
  const [application, setApplication] = useState<IShiftApplication>();
  const [missionId, setMissionId] = useState<number>();
  const [contactId, setContactId] = useState<number>();
  const [connectedApplicationIds, setConnectedApplicationIds] = useState<
    number[]
  >([]);

  const { data: contact, isLoading } = useGetContactItemQuery(
    { id: contactId },
    { skip: !isOpen || !contactId }
  );

  const openApplicationSedcardModal = useCallback(
    ({
      application: openApplication,
      contactConnectedApplicationIds,
      missionId: openMissionId,
    }: IOpenApplicationSedcardModalArgs) => {
      setContactId(openApplication.contact.id);
      setApplication(openApplication);
      setConnectedApplicationIds(contactConnectedApplicationIds);
      setMissionId(openMissionId);

      openModal();
    },
    [openModal]
  );

  const closeHandler = useCallback(() => {
    setContactId(undefined);
    closeModal();
  }, [closeModal]);

  const selectedApplication = allApplications?.find(
    (el) => el.id === application?.id
  );

  const applicationSedcardModal = useMemo(() => {
    return (
      <SidePopup
        isOpen={isOpen}
        onClose={closeHandler}
        dataTestId='application-sedcard-modal'
      >
        <ApplicationSedcard
          application={selectedApplication}
          missionId={missionId}
          contact={contact}
          connectedApplicationIds={connectedApplicationIds}
          onClose={closeHandler}
          isLoading={isLoading}
        />
      </SidePopup>
    );
  }, [
    closeHandler,
    connectedApplicationIds,
    contact,
    isLoading,
    isOpen,
    missionId,
    selectedApplication,
  ]);

  return {
    applicationSedcardModal,
    openApplicationSedcardModal,
  };
};

export default useApplicationSedcard;
