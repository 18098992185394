import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { IProject } from 'interfaces/Project.interface';
import type { IProjectMission } from 'interfaces/ProjectMission.interface';
import type { ISimpleFilter } from 'hooks/useFilters.hook';
import type { IProjectShiftWorkplanner } from 'interfaces/ProjectShift.interface';
import type { IContact } from 'interfaces/Contact.interface';
import {
  useLazyGetContactItemQuery,
  useLazyGetProjectItemQuery,
  useLazyGetProjectMissionItemQuery,
  useLazyGetProjectMissionsQuery,
  useLazyGetProjectShiftsWorkplannerQuery,
} from 'services/teamHeroApi.service';
import { useDownload } from 'components/TableView/hooks/useDownload.hook';
import {
  IProjectShiftWorkplannerWithMission,
  IShiftExportRowData,
  shiftsExportColumns,
} from './ShiftsExportColumns.config';
import { useErrorHandler } from 'hooks/useDisplayApiError.hook';
import useClientLoginMissionSettings from 'hooks/useClientLoginMissionSettings.hook';
import { getContactIdFromIriOrContact } from 'helpers/contact/getContactId.helper';

interface IUseGetProjectShiftsExportProps {
  missionId: number;
}

const useGetProjectShiftsExport = ({
  missionId,
}: IUseGetProjectShiftsExportProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [startDownload, setStartDownload] = useState(false);

  const { handleError } = useErrorHandler();

  const [triggerShifts] = useLazyGetProjectShiftsWorkplannerQuery();
  const [triggerProject] = useLazyGetProjectItemQuery();
  const [triggerMission] = useLazyGetProjectMissionItemQuery();
  const [triggerMissions] = useLazyGetProjectMissionsQuery();
  const [triggerContact] = useLazyGetContactItemQuery();

  const [exportData, setExportData] = useState<IShiftExportRowData[]>();
  const { t } = useTranslation('general');

  const { enableClientContacts } = useClientLoginMissionSettings({ missionId });

  const { downloadXLS } = useDownload<IShiftExportRowData>(
    exportData || [],
    shiftsExportColumns({ t, enableClientContacts }),
    t
  );

  useEffect(() => {
    if (startDownload) {
      downloadXLS();
      setStartDownload(false);
      setIsLoading(false);
    }
  }, [downloadXLS, startDownload]);

  const getShifts = async (
    filters: ISimpleFilter[]
  ): Promise<IProjectShiftWorkplanner[]> => {
    const result = await triggerShifts({
      filters,
      pagination: false,
    }).unwrap();

    return result.items;
  };

  const getMissions = async (projectId: number) => {
    const result = await triggerMissions({
      filters: [{ key: 'project', operator: 'AND', value: projectId }],
      pagination: false,
    }).unwrap();
    return result.items;
  };

  const getMission = async (id: number) => {
    const result = await triggerMission({ id }).unwrap();
    return result;
  };

  const getContacts = async (shifts: IProjectShiftWorkplannerWithMission[]) => {
    const contacts = shifts
      .filter((shift) => !!shift.contact)
      .map(async (shift) => {
        const contactId = getContactIdFromIriOrContact(shift.contact);
        return triggerContact({ id: contactId }).unwrap();
      });
    return Promise.all(contacts);
  };

  const getProject = async (projectId: number) => {
    const result = await triggerProject({
      id: projectId,
    }).unwrap();
    return result;
  };

  const getExportData = (
    project: IProject,
    shiftsWithMission: IProjectShiftWorkplannerWithMission[],
    contacts: IContact[]
  ) => {
    const result: IShiftExportRowData[] = [];

    shiftsWithMission.forEach((shift) => {
      const exportRow: IShiftExportRowData = {} as IShiftExportRowData;
      exportRow.shift = shift;
      exportRow.project = project;
      exportRow.contact = contacts.find(
        (contact) => shift.contact === contact['@id']
      );

      result.push(exportRow);
    });

    return result;
  };

  const getShiftsWithMission = async (
    missions: IProjectMission[],
    filters?: ISimpleFilter[]
  ) => {
    const shiftsWithMission: IProjectShiftWorkplannerWithMission[] = [];

    Promise.all(
      missions.map(async (mission) => {
        const shiftWithMission = await getShifts([
          { key: 'mission', operator: 'AND', value: mission.id },
          ...(filters || []),
        ]);

        shiftsWithMission.push(
          ...shiftWithMission.map((shift) => ({
            ...shift,
            mission,
          }))
        );
      })
    );

    return shiftsWithMission;
  };

  const getProjectShiftsExport = async (projectId: number) => {
    setIsLoading(true);
    try {
      const missions = await getMissions(projectId);

      const shiftsWithMission = await getShiftsWithMission(missions);

      const project = await getProject(projectId);

      const contacts = await getContacts(shiftsWithMission);

      const result = getExportData(project, shiftsWithMission, contacts);

      setExportData(result);
      setStartDownload(true);
    } catch (error) {
      handleError(error);
      setIsLoading(false);
    }
  };

  const getMissionShiftsExport = async (during: string) => {
    setIsLoading(true);

    try {
      const mission = await getMission(missionId);

      const shiftsWithMission = await getShiftsWithMission(
        [mission],
        [{ key: 'during', operator: 'AND', value: during }]
      );

      const project = await getProject(mission.project.id);

      const contacts = await getContacts(shiftsWithMission);

      const result = getExportData(project, shiftsWithMission, contacts);

      setExportData(result);
      setStartDownload(true);
    } catch (error) {
      handleError(error);
      setIsLoading(false);
    }
  };

  return {
    getMissionShiftsExport,
    getProjectShiftsExport,
    isLoading,
  };
};

export default useGetProjectShiftsExport;
