import {
  BaseQueryFn,
  MutationDefinition,
  EndpointBuilder,
  QueryDefinition,
} from '@reduxjs/toolkit/query';

import type {
  IGetMagicCredentialsArgs,
  IPostCredentialsArgs,
  IPostCredentialsResponse,
  IPostPasswordArgs,
  IPostRefreshTokenArgs,
  IUserPutArgs,
  IUserPutEmailChangeConfirmArgs,
  IUserPutLocaleArgs,
  IUserPutRequestPasswordMailArgs,
} from './user.types';
import type { IUser } from 'interfaces/User.interface';
import { serializeFlatObject } from '../helper/getPaginationParams.helper';

export const postCredentials = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostCredentialsArgs,
  BaseQueryFn,
  string,
  IPostCredentialsResponse
> =>
  build.mutation<IPostCredentialsResponse, IPostCredentialsArgs>({
    query: (queryArg) => ({
      url: `api/login_check`,
      method: 'POST',
      body: queryArg.credentials,
    }),
  });

export const putUserRequestPasswordMail = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IUserPutRequestPasswordMailArgs,
  BaseQueryFn,
  string,
  unknown
> =>
  build.mutation<unknown, IUserPutRequestPasswordMailArgs>({
    query: ({ email }) => ({
      url: `api/users/request-password`,
      method: 'PUT',
      body: {
        email,
      },
    }),
  });

export const postRefreshToken = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostRefreshTokenArgs,
  BaseQueryFn,
  string,
  IPostCredentialsResponse
> =>
  build.mutation<IPostCredentialsResponse, IPostRefreshTokenArgs>({
    query: (queryArg) => ({
      url: `api/token/refresh`,
      method: 'POST',
      body: {
        refresh_token: queryArg.refresh_token,
      },
    }),
  });

export const getUserMe = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<null, BaseQueryFn, string, IUser> =>
  build.query<IUser, null>({
    providesTags: ['UserMe'],
    query: () => ({
      url: `api/me`,
      method: 'GET',
    }),
  });

export const getMagicLoginCredentials = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetMagicCredentialsArgs,
  BaseQueryFn,
  string,
  IPostCredentialsResponse
> =>
  build.query<IPostCredentialsResponse, IGetMagicCredentialsArgs>({
    query: (queryArg: IGetMagicCredentialsArgs) => {
      const credentialsQuery = serializeFlatObject(queryArg.credentials, '?');
      return {
        url: `api/login_link_check${credentialsQuery}`,
        method: 'GET',
      };
    },
  });

export const putUserItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IUserPutArgs, BaseQueryFn, string, IUser> =>
  build.mutation<IUser, IUserPutArgs>({
    invalidatesTags: ['User', 'UserMe'],
    query: (queryArg) => ({
      url: `api/users/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });

export const postUserItemLocale = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IUserPutLocaleArgs, BaseQueryFn, string, IUser> =>
  build.mutation<IUser, IUserPutLocaleArgs>({
    invalidatesTags: ['UserMe'],
    query: (queryArg) => ({
      url: `api/me`,
      method: 'POST',
      body: {
        locale: queryArg.locale,
      },
    }),
  });

export const postPassword = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IPostPasswordArgs, BaseQueryFn, string, IUser> =>
  build.mutation<IUser, IPostPasswordArgs>({
    query: (queryArg) => ({
      url: `api/change_password`,
      method: 'POST',
      body: {
        oldPassword: queryArg.oldPassword,
        newPassword: queryArg.newPassword,
        newPasswordRetyped: queryArg.newPasswordRetyped,
      },
    }),
    extraOptions: { maxRetries: 0 },
  });

export const putUserEmailChangeConfirm = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IUserPutEmailChangeConfirmArgs,
  BaseQueryFn,
  string,
  IUser
> =>
  build.mutation<IUser, IUserPutEmailChangeConfirmArgs>({
    invalidatesTags: ['User', 'UserMe'],
    query: (queryArg) => ({
      url: `api/users/${queryArg.id}/confirm-email-change`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });
