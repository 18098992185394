import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

import { CommonCellContentStyled } from '../Common.styled';

const { pxToRem } = Utils;

export const EmptyCellContentStyled = styled(CommonCellContentStyled)`
  text-transform: lowercase;
  padding-left: ${pxToRem(8)};
`;
