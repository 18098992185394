import { styled, css } from 'styled-components';
import { device, Theme, Utils } from 'team-hero-ui';

const { pxToRem } = Utils;

interface ITabStyledProps {
  $isSelected: boolean;
}

interface ITabsWrapperStyledProps {
  $sliderStartValue: string;
}

export const SliderStyled = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 25%;
  height: ${pxToRem(3)};
  background-color: ${Theme.colors.primary.white};
  transition: left 0.2s ease-in-out;
  border-radius: ${pxToRem(4)};
`;

export const TabsWrapperStyled = styled.div<ITabsWrapperStyledProps>`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  text-align: center;
  position: relative;
  ${SliderStyled} {
    ${({ $sliderStartValue }) => `left: ${$sliderStartValue}`}
  }
`;

export const TabStyled = styled.div<ITabStyledProps>`
  font-size: ${pxToRem(12)};

  @media ${device.sm} {
    font-size: ${pxToRem(14)};
  }

  cursor: pointer;
  text-transform: uppercase;
  padding-bottom: ${pxToRem(6)};

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      font-weight: 700;
    `};
`;
