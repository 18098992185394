import { FC, useCallback } from 'react';
import { IconButton, Icons, Theme } from 'team-hero-ui';

import LocalizedDatePicker from 'components/LocalizedDatePicker';
import { IFilterRendererProps } from 'components/FilterPanel/FilterPanel.type';
import {
  FilterElementStyled,
  FullWidthWrapperStyled,
} from 'components/FilterPanel/filterRenderers/FilterElement.styled';
import { parseDate } from '../helpers/date.helper';

export const DatePickerRenderer: FC<IFilterRendererProps> = ({
  label,
  onChange,
  value,
  styleType,
}) => {
  const handleClearValue = useCallback(() => {
    onChange(null);
  }, [onChange]);

  const handleChangeValue = useCallback(
    (date: Date) => {
      onChange(parseDate(date));
    },
    [onChange]
  );

  return (
    <FilterElementStyled>
      <FullWidthWrapperStyled>
        <LocalizedDatePicker
          value={value}
          onChange={handleChangeValue}
          placeholder={label}
          label={label}
          type={styleType === 'modal' ? 'dateModal' : 'date'}
        />
      </FullWidthWrapperStyled>
      <IconButton size='small' onClick={handleClearValue}>
        <Icons.CloseIcon
          svgSize={16}
          svgColor={
            styleType === 'modal'
              ? Theme.colors.primary.white
              : Theme.colors.primary.grey
          }
        />
      </IconButton>
    </FilterElementStyled>
  );
};
