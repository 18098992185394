import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { TLocale } from 'interfaces/Locale.interface';
import {
  CustomFieldValue,
  getCustomFieldReadableValue,
} from 'helpers/customFields/CustomFieldValue';
import { ICustomField } from 'interfaces/CustomFields.interface';
import { useGetCustomFieldsQuery } from 'services/teamHeroApi.service';
import { useDateFormat } from './useDateFormat.hook';
import { IColumnDefinitionType } from 'interfaces/Table/DataTableColumn.interface';
import { customFieldsService } from 'services/customFields/customFields.service';

export type TCustomFieldEntityKey = 'Contact';

interface IUseGetCustomFieldsReturn {
  getDisplayValue: (fieldKey: string, unformattedValue: unknown) => ReactNode;
  isInCustomFields: (fieldKey: string) => boolean;
  customFields: ICustomField[];
  isFetching: boolean;
  getFieldName: (fieldKey: string) => string;
  getDisplayValueString: (
    fieldKey: string,
    unformattedValue: unknown
  ) => string;

  getCustomFieldsColumns: <T>(
    exportHandler?: (key: string, row: T) => void
  ) => IColumnDefinitionType<T>[];
}

const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const useGetCustomFields = (
  entityKey: TCustomFieldEntityKey = 'Contact'
): IUseGetCustomFieldsReturn => {
  const { t, i18n } = useTranslation('general');
  const { formatDate } = useDateFormat();

  const { currentData: customFieldsData, isFetching } =
    useGetCustomFieldsQuery();

  const entityPath = `App\\Entity\\${entityKey}`;
  const customFields = customFieldsData?.[entityPath]?.fields || [];

  const getFieldName = (fieldKey: string) => {
    const field = customFields.find(
      (customField) => customField.key === fieldKey
    );

    const name = field?.displayName[i18n.language as TLocale];

    return name ? capitalizeFirstLetter(name) : '';
  };

  const isInCustomFields = (fieldKey: string): boolean => {
    return !!customFields.find((customField) => customField.key === fieldKey);
  };

  const getDisplayValueString = (
    fieldKey: string,
    unformattedValue: unknown
  ) => {
    const field = customFields.find(
      (customField) => customField.key === fieldKey
    );

    if (!field || !unformattedValue) {
      return '';
    }

    return getCustomFieldReadableValue({
      language: i18n.languages[0],
      t,
      formatDate,
      unformattedValue,
      field,
      customFields: customFieldsData,
    });
  };

  const getCustomFieldsColumns = <T,>(
    exportHandler?: (key: string, row: T) => void
  ): IColumnDefinitionType<T>[] => {
    return customFieldsService({
      customFields: customFieldsData,
      language: i18n.languages[0] as TLocale,
    }).getCustomFieldsColumns(getDisplayValueString, exportHandler);
  };

  const getDisplayValue = (
    fieldKey: string,
    unformattedValue: unknown
  ): ReactNode => {
    const field = customFields.find(
      (customField) => customField.key === fieldKey
    );

    return (
      <CustomFieldValue
        field={field}
        unformattedValue={unformattedValue}
        customFields={customFieldsData}
      />
    );
  };

  return {
    isInCustomFields,
    getDisplayValue,
    customFields,
    isFetching,
    getFieldName,
    getCustomFieldsColumns,
    getDisplayValueString,
  };
};
