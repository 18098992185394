import { styled } from 'styled-components';
import { Button, device, Theme, Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

export const ModalStyled = styled.div`
  height: 100%;
  width: 100vw;
  overflow: auto;
  @media ${device.md} {
    width: unset;
    min-width: 45vw;
  }
`;

export const ModalContentStyled = styled.div`
  padding: ${pxToRem(12)};
  height: 100%;
  overflow: auto;
`;

export const FooterStyled = styled.div`
  ${flexFn('flex-start', 'center')};
  box-shadow: 0 ${pxToRem(-3)} ${pxToRem(6)} 0 ${Theme.colors.greyShades.grey4};
  height: ${pxToRem(80)};
  button {
    margin-left: ${pxToRem(24)};
  }
`;

export const PaginationStyled = styled.div`
  width: 100%;
  padding-top: ${pxToRem(40)};
  ${flexFn('center')}
`;

export const ListItemContainerStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')};
  gap: ${pxToRem(8)};
  * {
    font-size: ${pxToRem(14)};
  }
`;

export const ButtonStyled = styled(Button)`
  &.MuiButtonBase-root {
    width: fit-content;
    &.MuiButton-text {
      font-size: ${pxToRem(12)};
    }
  }
`;

export const NoResultGraphicWrapperStyled = styled.div`
  ${flexFn('center', 'center', 'column')};
  max-width: ${pxToRem(600)};
  margin: auto;
`;
