import { FC } from 'react';
import { Icons, theme } from 'team-hero-ui';

import type { ISimpleFilter } from 'hooks/useFilters.hook';
import type { IMessageConversationParticipant } from 'interfaces/MessageConversationParticipant.interface';
import type { IMessageConversation } from 'interfaces/MessageConversation.interface';
import { useGetMessageConversationParticipantsQuery } from 'services/teamHeroApi.service';
import useCurrentUserContact from 'hooks/useCurrentUserContact.hook';
import {
  NumberOfParticipantsStyled,
  WrapperStyled,
} from './ParticipantMinCellRenderer.styled';
import ContactCellRenderer from 'components/cellRenderers/ContactCellRenderer';
import ParticipantMinCellRendererSkeleton from './ParticipantMinCellRendererSkeleton';

interface IParticipantMinCellRendererProps {
  conversation: IMessageConversation;
}

const ParticipantMinCellRenderer: FC<IParticipantMinCellRendererProps> = ({
  conversation,
}) => {
  const { currentUserContact, isLoading: isLoadingCurrentUser } =
    useCurrentUserContact();

  const conversationParticipantFilters: ISimpleFilter[] = [
    {
      key: 'conversation',
      operator: 'AND',
      value: conversation.id,
    },
  ];

  const { data: participants, isLoading } =
    useGetMessageConversationParticipantsQuery(
      {
        filters: conversationParticipantFilters,
        page: 1,
        itemsPerPage: 20,
      },
      {
        skip: isLoadingCurrentUser,
      }
    );

  const participantsFiltered: IMessageConversationParticipant[] =
    participants?.items?.filter(
      (participant) => participant.participant.id !== currentUserContact?.id
    ) || [];

  const avatarSize = 40;

  if (isLoading) {
    return <ParticipantMinCellRendererSkeleton avatarWidth={avatarSize} />;
  }

  if (participantsFiltered?.length > 1) {
    return (
      <WrapperStyled>
        {conversation.isGroupChat ? (
          <div>
            <Icons.PeopleIcon
              svgColor={theme.colors.greyShades.grey2}
              svgSize={avatarSize}
            />
          </div>
        ) : (
          <div>
            <NumberOfParticipantsStyled $size={avatarSize}>
              {participantsFiltered.length}
            </NumberOfParticipantsStyled>
          </div>
        )}
      </WrapperStyled>
    );
  }

  if (participantsFiltered?.length === 1) {
    return (
      <WrapperStyled>
        {conversation.isGroupChat ? (
          <div>
            <Icons.PeopleIcon
              svgColor={theme.colors.greyShades.grey2}
              svgSize={avatarSize}
            />
          </div>
        ) : (
          <ContactCellRenderer
            size={avatarSize}
            contactData={participantsFiltered[0].participant}
            displayName={false}
          />
        )}
      </WrapperStyled>
    );
  }

  return <WrapperStyled>-</WrapperStyled>;
};

export default ParticipantMinCellRenderer;
