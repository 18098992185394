import { FC, useCallback, useMemo } from 'react';
import { Accordion, Button } from 'team-hero-ui';
import { useTranslation } from 'react-i18next';

import { FilterItem } from 'components/FilterPanel/helpers/FilterItem';
import {
  FilterPanelStyled,
  FiltersActionsWrapperStyled,
  FiltersWrapperStyled,
} from './FilterInputs.styled';
import { IFilterInputProps } from './FiterInputs.types';

const FilterInputs: FC<IFilterInputProps> = ({
  filterDefinitionList,
  displayType,
  maxHeight = '100%',
  styleType,
  control,
  handleClearFilters,
  handleSubmit,
  additionalSubmitCallback,
}) => {
  const { t } = useTranslation('general');

  const baseFilters = useMemo(
    () =>
      filterDefinitionList.filter(
        (f) =>
          (!f.filterCategory || f.filterCategory === 'default') && !f.isHidden
      ),
    [filterDefinitionList]
  );

  const extraFilters = useMemo(
    () =>
      filterDefinitionList.filter(
        (f) => f.filterCategory === 'extra' && !f.isHidden
      ),
    [filterDefinitionList]
  );

  const customFieldsFilters = useMemo(
    () =>
      filterDefinitionList.filter(
        (f) => f.filterCategory === 'customField' && !f.isHidden
      ),
    [filterDefinitionList]
  );

  const existsFilters = useMemo(
    () =>
      filterDefinitionList.filter(
        (definition) =>
          definition.filterCategory === 'existsFilter' && !definition.isHidden
      ),
    [filterDefinitionList]
  );

  const availabilityFilters = useMemo(
    () =>
      filterDefinitionList.filter(
        (definition) =>
          definition.filterCategory === 'availability' && !definition.isHidden
      ),
    [filterDefinitionList]
  );

  const onClickSubmit = useCallback(() => {
    handleSubmit();
    if (additionalSubmitCallback) {
      additionalSubmitCallback();
    }
  }, [additionalSubmitCallback, handleSubmit]);

  return (
    <FilterPanelStyled
      $styleType={styleType}
      $displayType={displayType}
      data-test-id='filters-panel'
    >
      <FiltersWrapperStyled $maxHeight={maxHeight} $displayType={displayType}>
        {baseFilters.map((filterDefinition) => {
          return FilterItem({ filterDefinition, control, styleType });
        })}
        {!!customFieldsFilters.length && (
          <Accordion
            title={t('filters.customFieldsFilters')}
            defaultExpanded={false}
          >
            {customFieldsFilters.map((filterDefinition) => {
              return FilterItem({ filterDefinition, control, styleType });
            })}
          </Accordion>
        )}
        {!!existsFilters.length && (
          <Accordion title={t('filters.existsFilters')} defaultExpanded={false}>
            {existsFilters.map((filterDefinition) => {
              return FilterItem({ filterDefinition, control, styleType });
            })}
          </Accordion>
        )}
        {!!availabilityFilters.length && (
          <Accordion
            title={t('filters.availabilityFilters')}
            defaultExpanded={false}
          >
            {availabilityFilters.map((filterDefinition) => {
              return FilterItem({ filterDefinition, control, styleType });
            })}
          </Accordion>
        )}
        {!!extraFilters.length && (
          <Accordion title={t('filters.otherFilters')} defaultExpanded={false}>
            {extraFilters.map((filterDefinition) => {
              return FilterItem({ filterDefinition, control, styleType });
            })}
          </Accordion>
        )}
      </FiltersWrapperStyled>
      <FiltersActionsWrapperStyled>
        <Button
          color='primary'
          onClick={onClickSubmit}
          data-test-id='apply-filters-button'
        >
          {t('filters.applyFilters')}
        </Button>
        <Button
          onClick={handleClearFilters}
          data-test-id='clear-filters-button'
        >
          {t('filters.clearFilters')}
        </Button>
      </FiltersActionsWrapperStyled>
    </FilterPanelStyled>
  );
};

export default FilterInputs;
