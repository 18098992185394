import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const ApplicationGroupStyled = styled.div`
  cursor: pointer;
  background-color: ${Theme.colors.primary.white};
  border-radius: ${pxToRem(4)};
  padding: ${pxToRem([12, 16])};
`;

export const PositionStyled = styled.div`
  font-weight: 600;
  font-size: ${pxToRem(15)};
`;

export const AreaStyled = styled.div`
  font-size: ${pxToRem(14)};
`;

export const ApplicationCountStyled = styled.div`
  height: 100%;
  ${flexFn('flex-end', 'center', 'row')};
  gap: ${pxToRem(8)};
  color: ${Theme.colors.primary.red};
  p {
    font-weight: 700;
    font-size: ${pxToRem(13)};
  }
`;
