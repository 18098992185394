import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { pxToRem } = Utils;

export const FromNumberRendererStyled = styled.div`
  .MuiInputBase-input {
    padding: ${pxToRem(12)};
  }
`;
