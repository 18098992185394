import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

import { CommonCellContentStyled } from 'components/cellRenderers/Common.styled';

const { flexFn, pxToRem } = Utils;

export const CellContainerStyled = styled(CommonCellContentStyled)`
  ${flexFn()}
  gap: ${pxToRem(5)};
`;
