import { FC, useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { theme } from 'team-hero-ui';

const NativeStatusBarProvider: FC = () => {
  useEffect(() => {
    // this is only for android, in ios, the status bar is always light set in Info.plist
    // on web, this would cause an error
    if (Capacitor.getPlatform() !== 'android') {
      return;
    }
    StatusBar.setStyle({ style: Style.Light });
    StatusBar.setBackgroundColor({ color: theme.colors.primary.white });
  }, []);
  return null;
};

export default NativeStatusBarProvider;
