import { TMomentFormats, useDateFormat } from 'hooks/useDateFormat.hook';
import { dateService, TDateFormatTypes } from 'services/date/date.service';
import {
  DateRendererContainerStyled,
  DateRendererDayOfWeekStyled,
} from './DateCellRenderer.styled';
import { EmptyCell } from '../EmptyCellRenderer/EmptyCellRenderer';
import { CommonCellContentStyled } from '../Common.styled';

interface IDateCellRendererProps {
  date?: string;
  showTime?: boolean;
  withDayOfWeek?: boolean;
  color?: string;
  showDate?: boolean;
  noDateMessage?: string;
  format?: TMomentFormats;
}

export const DateCellRenderer = ({
  date,
  showTime = false,
  withDayOfWeek = true,
  color,
  showDate = true,
  noDateMessage,
  format: formatOverride,
}: IDateCellRendererProps) => {
  const { dateFormat, timeFormat, dateTimeFormat, formatDate } =
    useDateFormat();
  if (date) {
    const value = dateService(date);
    let format: TDateFormatTypes = dateFormat;
    if (showTime && showDate) {
      format = dateTimeFormat;
    }
    if (showTime && !showDate) {
      format = timeFormat;
    }
    const valueToDisplay = value.isValid()
      ? formatOverride
        ? formatDate(date, formatOverride)
        : value.format(format)
      : '-';
    const dayOfWeek = value.isValid()
      ? `${formatDate(date, 'dayTwoLetters')}`
      : '';

    return (
      <DateRendererContainerStyled>
        {withDayOfWeek && !formatOverride && (
          <DateRendererDayOfWeekStyled>{dayOfWeek}</DateRendererDayOfWeekStyled>
        )}
        <CommonCellContentStyled $color={color}>
          {valueToDisplay}
        </CommonCellContentStyled>
      </DateRendererContainerStyled>
    );
  }

  return <EmptyCell contentText={noDateMessage} />;
};
