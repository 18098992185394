import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'team-hero-ui';

import {
  ActionCardIconStyled,
  ActionCardTitleStyled,
  NavigationActionCardStyled,
} from './NavigationActionCard.styled';
import { INavigationActionCardProps } from './NavigationActionCard.types';

const NavigationActionCard: FC<INavigationActionCardProps> = ({
  icon,
  title,
  buttonStyle = 'cardWhite',
  onClick,
}) => {
  const { t } = useTranslation('general');

  return (
    <Button color={buttonStyle} onClick={onClick}>
      <NavigationActionCardStyled>
        <ActionCardIconStyled>{icon}</ActionCardIconStyled>
        <ActionCardTitleStyled>
          {t(`navigation.${title}`)}
        </ActionCardTitleStyled>
      </NavigationActionCardStyled>
    </Button>
  );
};

export default NavigationActionCard;
