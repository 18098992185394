import { styled, css } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

interface ITimelineHeadBarItemStyledProps {
  $width?: number;
}

interface ITimelineHeadBarItemInnerStyledProps {
  $isHighlighted?: boolean;
}

const getItemColors = (isHighlighted?: boolean) => {
  if (isHighlighted) {
    return css`
      background-color: ${({ theme }) => theme.colors.greyShades.grey3};
      color: ${({ theme }) => theme.colors.primary.white};
    `;
  }
  return css`
    background-color: ${({ theme }) => theme.colors.primary.white};
    color: ${({ theme }) => theme.colors.greyShades.grey2};
  `;
};

export const TimelineHeadBarOuterStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')}
  flex: 0;
`;

export const TimelineHeadBarInnerStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'row')}
  height: ${pxToRem(36)};
  white-space: nowrap;
  overflow: hidden;
`;

export const TimelineHeadBarItemStyled = styled.span<ITimelineHeadBarItemStyledProps>`
  text-align: center;
  display: inline-flex;
  white-space: nowrap;
  padding: ${pxToRem([3, 2])};
  height: 100%;
  overflow: hidden;
  width: ${({ $width }) => ($width ? pxToRem($width) : null)};
`;

export const TimelineHeadBarItemInnerStyled = styled.span<ITimelineHeadBarItemInnerStyledProps>`
  ${flexFn('center', 'center', 'column')}
  flex: 1;
  padding: ${pxToRem([2, 0])};
  height: 100%;
  align-self: center;
  border-radius: ${pxToRem(3)};
  font-size: ${pxToRem(11)};
  ${({ $isHighlighted }) => getItemColors($isHighlighted)};
`;

export const TimelineHeadbarItemInnerTextStyled = styled.span`
  color: inherit;
  font-size: ${pxToRem(11)};
  font-weight: bold;
`;

export const TimelineHeadbarItemInnerTopBarTextStyled = styled(
  TimelineHeadbarItemInnerTextStyled
)`
  font-size: ${pxToRem(9)};
  font-weight: normal;
  text-transform: uppercase;
`;

export const TimelineLeftSettingsWrapperStyled = styled(
  TimelineHeadBarItemStyled
)`
  padding: 0;
  gap: ${pxToRem(16)};
`;

export const TimelineHeadBarLegendStyled = styled(TimelineHeadBarItemStyled)`
  padding-right: 0;
`;

export const TimelineHeadBarItemInnerAltStyled = styled(
  TimelineHeadBarItemInnerStyled
)`
  text-transform: uppercase;
`;
