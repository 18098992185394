import {
  EndpointBuilder,
  MutationDefinition,
  QueryDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type { IApplicationInterviewInvite } from 'interfaces/ApplicationInterviewInvite.interface';
import type {
  IApplicationInterviewInvitesCollectionArgs,
  IApplicationInterviewInvitesCollectionResponse,
  IGetApplicationInterviewInviteItemArgs,
  IPostApplicationInterviewInviteItemArgs,
  IPutApplicationInterviewInviteItemArgs,
} from './applicationInterviewInvites.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import type { ICollectionApiResponse } from 'services/api/api.types';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';

export const getApplicationInterviewInvites = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IApplicationInterviewInvitesCollectionArgs,
  BaseQueryFn,
  string,
  IApplicationInterviewInvitesCollectionResponse
> =>
  build.query<
    IApplicationInterviewInvitesCollectionResponse,
    IApplicationInterviewInvitesCollectionArgs
  >({
    providesTags: (result) =>
      result
        ? [
            ...result.items.map(({ id }) => ({
              type: 'ApplicationInterviewInvite' as const,
              id,
            })),
            'ApplicationInterviewInvite',
          ]
        : ['ApplicationInterviewInvite'],
    query: (queryArg: IApplicationInterviewInvitesCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);

      return {
        url: `api/application_interview_invites${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IApplicationInterviewInvite>
    ): IApplicationInterviewInvitesCollectionResponse =>
      transformCollectionResponse<IApplicationInterviewInvite>(response),
  });

export const getApplicationInterviewInviteItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetApplicationInterviewInviteItemArgs,
  BaseQueryFn,
  string,
  IApplicationInterviewInvite
> =>
  build.query<
    IApplicationInterviewInvite,
    IGetApplicationInterviewInviteItemArgs
  >({
    providesTags: ['ApplicationInterviewInvite'],
    query: (queryArg: IGetApplicationInterviewInviteItemArgs) => ({
      url: `api/application_interview_invites/${queryArg.id}`,
    }),
  });

export const postApplicationInterviewInviteItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostApplicationInterviewInviteItemArgs,
  BaseQueryFn,
  string,
  IApplicationInterviewInvite
> =>
  build.mutation<
    IApplicationInterviewInvite,
    IPostApplicationInterviewInviteItemArgs
  >({
    invalidatesTags: ['ApplicationInterviewInvite'],
    query: (queryArg) => ({
      url: 'api/application_interview_invites',
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const putApplicationInterviewInviteItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPutApplicationInterviewInviteItemArgs,
  BaseQueryFn,
  string,
  IApplicationInterviewInvite
> =>
  build.mutation<
    IApplicationInterviewInvite,
    IPutApplicationInterviewInviteItemArgs
  >({
    invalidatesTags: (_result, _error, arg) => [
      { type: 'ApplicationInterviewInvite', id: arg.id },
    ],
    query: (queryArg) => ({
      url: `api/application_interview_invites/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });
