import { styled, css } from 'styled-components';
import { Button, DropdownItem, FormControlLabel, Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const DropdownItemLabelStyled = styled.div`
  ${flexFn('space-between')}
`;

export const ButtonWithoutStyle = css`
  &&& {
    background: none;
    height: auto;
    border: none;
    &:hover {
      background: none;
      box-shadow: none;
    }
    & .MuiButton-label {
      font-size: ${pxToRem(11)};
      color: ${({ theme }) => theme.colors.greyShades.grey3};
    }

    &&& .MuiTouchRipple-rippleVisible {
      color: transparent;
    }
  }
`;

export const ShowAllButtonStyled = styled(Button)`
  ${ButtonWithoutStyle}
`;

export const ShowColumnsTitleStyled = styled.li`
  padding: ${pxToRem([11, 0, 8, 10])};
  font-size: ${pxToRem(11)};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.greyShades.grey3};
`;

export const DropdownItemStyled = styled(DropdownItem)`
  .MuiFormControlLabel-root {
    width: 100%;
    gap: ${pxToRem(5)};
  }
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: ${pxToRem(11)};
    color: ${({ theme }) => theme.colors.greyShades.grey3};
    width: 100%;
  }
`;
