import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { IViolation } from 'interfaces/Error.interface';
import {
  isApiErrorData,
  isFetchBaseQueryError,
  isGeneralApiErrorData,
} from 'services/helper/error.helper';
import useNotification from './useNotification.hook';

export interface IUseDisplayApiReturn {
  handleError: (error: unknown, customGeneralMessage?: string) => void;
}

export const useErrorHandler = (): IUseDisplayApiReturn => {
  const { t } = useTranslation(['errors', 'general']);
  const { set: setNotification } = useNotification();

  const handleViolationErrors = useCallback(
    (violations: IViolation[]): void => {
      violations.forEach((violation) => {
        if (violation.propertyPath && violation.message) {
          setNotification({
            type: 'error',
            message: `${t(violation.propertyPath)}: ${t(violation.message, { ...violation.parameters })}`,
          });
        } else {
          setNotification({
            type: 'error',
            message: `${t(violation.message, { ...violation.parameters })}`,
          });
        }
      });
    },
    [setNotification, t]
  );

  const handleError = useCallback(
    (error: unknown, customGeneralMessage?: string) => {
      // check for fetchbaseError with type predicate
      if (isFetchBaseQueryError(error)) {
        // handle ApiErrors
        if (error.data && isApiErrorData(error.data)) {
          if (error.data.violations && error.data.violations.length > 0) {
            handleViolationErrors(error.data.violations);
            return;
          }
          setNotification({
            type: 'error',
            message: `${t(error.data['hydra:description'])}`,
          });
          return;
        }
        if (error.data && isGeneralApiErrorData(error.data)) {
          setNotification({
            type: 'error',
            message: `${t(error.data.message)}`,
          });
          return;
        }
      }
      // if the error type can not be detected, show general error message instead
      setNotification({
        type: 'error',
        message: customGeneralMessage
          ? customGeneralMessage
          : t('errors:general'),
      });
      return;
    },
    [handleViolationErrors, setNotification, t]
  );

  return { handleError };
};
