import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { logout } from 'redux/user/user.thunk';
import { useAppDispatch } from 'store/hooks';
import useNotification from 'hooks/useNotification.hook';

const LogoutPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { set: setNotification } = useNotification();

  const [searchParams] = useSearchParams();
  const silentParam = searchParams.get('silent');

  const onLogout = useCallback(async () => {
    await dispatch(logout());
    if (!silentParam) {
      setNotification({
        type: 'success',
        message: t('logout.successMessage'),
      });
    }
    navigate('/login');
  }, [t, dispatch, navigate, setNotification, silentParam]);

  useEffect(() => {
    onLogout();
  }, [onLogout]);

  return <div>{t('logout.logging_out')}</div>;
};

export default LogoutPage;
