import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

interface ITextCellRendererProps {
  $leftTab?: number;
  $color?: string | null;
  $isBold?: boolean;
}

export const TextCellRendererStyled = styled.div<ITextCellRendererProps>`
  width: 100%;
  height: 100%;
  ${flexFn('flex-start')}
  font-size: ${pxToRem(14)};
  padding-left: ${({ $leftTab }) => ($leftTab ? pxToRem($leftTab) : 0)};
  color: ${({ $color }) => $color};
  font-weight: ${({ $isBold }) => ($isBold ? 600 : 400)};
`;
