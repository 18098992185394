import { FC } from 'react';

import type { IProjectShiftWorkplanner } from 'interfaces/ProjectShift.interface';
import {
  getSummedUpHoursByShifts,
  getSummedUpHoursByTimelineDisplayItems,
} from 'components/Timeline/helper/timelineItemsCalculate.helper';
import { IRightLegendProps } from 'components/Timeline/Timeline.type';
import ShiftRightLegendContent from './ShiftRightLegendContent';

export const ShiftRightLegend: FC<
  IRightLegendProps<IProjectShiftWorkplanner>
> = ({ items, width }: IRightLegendProps<IProjectShiftWorkplanner>) => {
  const hoursTotal = getSummedUpHoursByTimelineDisplayItems(items);

  return <ShiftRightLegendContent width={width} hoursTotal={hoursTotal} />;
};

export const ShiftCollectionRightLegend: FC<
  IRightLegendProps<IProjectShiftWorkplanner[]>
> = ({ items, width }) => {
  const shiftItems: IProjectShiftWorkplanner[] = [];
  items.forEach((item) => {
    shiftItems.push(...item.item);
  });
  const hoursTotal = getSummedUpHoursByShifts(shiftItems);

  return <ShiftRightLegendContent width={width} hoursTotal={hoursTotal} />;
};
