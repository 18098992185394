import { styled, css } from 'styled-components';
import { device, Theme, Utils } from 'team-hero-ui';

import type { IPaginationStyledProps } from '../ResultsContainer/ResultsContainer.styled';

const { pxToRem } = Utils;

export const ResultsSectionWrapperStyled = styled.div<IPaginationStyledProps>`
  padding: ${pxToRem([8, 4, 0, 4])};
  @media ${device.sm} {
    padding: ${pxToRem([10, 24])};
  }
  width: 100%;
  ${({ $withPagination }) =>
    $withPagination &&
    css`
      height: inherit;
    `}
`;

export const ResultsSectionTitleStyled = styled.div`
  color: ${Theme.colors.primary.grey};
  margin-bottom: ${pxToRem(8)};
`;

export const ResultsSectionStyled = styled.div<IPaginationStyledProps>`
  @media ${device.sm} {
    padding: ${pxToRem([0, 8])};
    margin: ${pxToRem([0, -8])};
  }
  ${({ $withPagination }) =>
    $withPagination &&
    css`
      overflow-y: auto;
    `}
`;

export const ResultsSectionContentStyled = styled.div<IPaginationStyledProps>`
  ${({ $withPagination }) =>
    $withPagination &&
    css`
      height: inherit;
      display: grid;
      grid-template-rows: auto ${pxToRem(40)};
      grid-template-areas: 'content' 'pagination';
    `}
`;
