import type { ISorting } from 'components/TableView/hooks/useSort.hook';

export const itemsPerPageDefault = 20;

export const currentPageDefault = 1;

export const createdAtSortingDefault: ISorting = {
  columnKey: 'createdAt',
  direction: 'desc',
};
