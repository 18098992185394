import { FC, useState } from 'react';
import { Dropdown, Icons } from 'team-hero-ui';
import { useTranslation } from 'react-i18next';

import { TTimelineSize } from 'components/Timeline/interfaces/timelineResolutions.interface';
import { TimelineSizeDropdownStyled } from './TimelineSizeDropdown.styled';

interface IResolutionDropdownProps {
  value: string;
  handleChange: (timelineSize: TTimelineSize) => void;
  timelineSizeItems: TTimelineSize[];
}

const TimelineSizeDropdown: FC<IResolutionDropdownProps> = ({
  value = '',
  handleChange,
  timelineSizeItems,
}) => {
  const [closed, setClosed] = useState(false);
  const { t } = useTranslation('general');
  const onClickItem = (timelineSize: TTimelineSize) => {
    handleChange(timelineSize);
    setClosed(true);
  };

  return (
    <Dropdown
      color='white'
      borderColor='grey'
      iconColor='grey'
      label={t(`workplanner.timeline.size.${value}`)}
      startIcon={<Icons.CalendarIcon svgColor='currentColor' svgSize={18} />}
      closed={closed}
      styleType='toolbar'
      handleCloseCb={() => setClosed(false)}
      dataTestId={'timeline-size-dropdown'}
    >
      {timelineSizeItems.map((timelineSizeItem) => (
        <TimelineSizeDropdownStyled
          onClick={() => onClickItem(timelineSizeItem)}
          key={timelineSizeItem}
        >
          {t(`workplanner.timeline.size.${timelineSizeItem}`)}
        </TimelineSizeDropdownStyled>
      ))}
    </Dropdown>
  );
};

export default TimelineSizeDropdown;
