import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import type { IApiError, IGeneralApiError } from 'interfaces/Error.interface';

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export const isFetchBaseQueryError = (
  error: unknown
): error is FetchBaseQueryError => {
  return typeof error === 'object' && error != null && 'status' in error;
};

/**
 * Type predicate to narrow an unknown error to an ApiError
 */
export const isApiErrorData = (errorData: unknown): errorData is IApiError => {
  return (
    typeof errorData === 'object' &&
    errorData != null &&
    'hydra:title' in errorData &&
    typeof (errorData as never)['hydra:title'] === 'string' &&
    'hydra:description' in errorData &&
    typeof (errorData as never)['hydra:description'] === 'string'
  );
};

export const isGeneralApiErrorData = (
  errorData: unknown
): errorData is IGeneralApiError => {
  return (
    typeof errorData === 'object' &&
    errorData != null &&
    'code' in errorData &&
    'message' in errorData
  );
};
