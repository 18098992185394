import type { TRootState } from 'store';
import type { ISelector } from 'interfaces/Selector.interface';
import { createSelectorSlice } from 'redux/collections/helper/createSelector.slice';
import {
  currentPageDefault,
  itemsPerPageDefault,
} from 'redux/collections/consts/selectorDefaults.consts';

export const expensesSlice = createSelectorSlice(
  'paginatedExpenses',
  currentPageDefault,
  itemsPerPageDefault,
  { columnKey: 'createdAt', direction: 'desc' }
);

const { actions, reducer } = expensesSlice;

export const {
  setPagination,
  setNextPage,
  setSorting,
  setItemsPerPage,
  setFilters,
} = actions;

export const paginatedExpensesSelector = (state: TRootState): ISelector =>
  state.collectionExpenses;

export default reducer;
