import { FC } from 'react';
import { Icons, Tooltip } from 'team-hero-ui';

import type { IRatingProps } from './Rating.types';
import { RatingStyled, ScoreTextStyled } from './Rating.styled';
import { getStarColor } from './utils';

const Rating: FC<IRatingProps> = ({ score, tooltipText, size }) => {
  const { color, fill } = getStarColor(score);

  const renderScoreText = () => {
    if (tooltipText) {
      return (
        <Tooltip title={tooltipText}>
          <ScoreTextStyled size={size}>{score}</ScoreTextStyled>
        </Tooltip>
      );
    }

    return <ScoreTextStyled size={size}>{score}</ScoreTextStyled>;
  };

  return (
    <RatingStyled $fill={fill}>
      <Icons.StarIcon svgColor={color} svgSize={size} />
      {renderScoreText()}
    </RatingStyled>
  );
};

export default Rating;
