import {
  EndpointBuilder,
  MutationDefinition,
  QueryDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type {
  IContactInvoicesEntityArgs,
  IGetContactInvoiceItemArgs,
  IGetContactInvoicesResponse,
  IPostContactInvoiceArgs,
  IPostContactInvoiceFileArgs,
  IPutContactInvoiceArgs,
} from './contactInvoices.api.types';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import type { IContactInvoice } from 'interfaces/ContactInvoice.interface';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';
import type { IContactInvoiceFileResource } from 'interfaces/File.interface';

export const getContactInvoices = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IContactInvoicesEntityArgs,
  BaseQueryFn,
  string,
  IGetContactInvoicesResponse
> =>
  build.query<IGetContactInvoicesResponse, IContactInvoicesEntityArgs>({
    providesTags: ['ContactInvoice'],
    query: (queryArg: IContactInvoicesEntityArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);
      return {
        url: `/api/contact_invoices${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IContactInvoice>
    ): IGetContactInvoicesResponse =>
      transformCollectionResponse<IContactInvoice>(response),
  });

export const getContactInvoiceItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetContactInvoiceItemArgs,
  BaseQueryFn,
  string,
  IContactInvoice
> =>
  build.query<IContactInvoice, IGetContactInvoiceItemArgs>({
    providesTags: ['ContactInvoice'],
    query: (queryArg: IGetContactInvoiceItemArgs) => ({
      url: `api/contact_invoices/${queryArg.id}`,
    }),
  });

export const postContactInvoice = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostContactInvoiceArgs,
  BaseQueryFn,
  string,
  IContactInvoice
> =>
  build.mutation<IContactInvoice, IPostContactInvoiceArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['ContactInvoice'],
    query: (queryArg) => ({
      url: '/api/contact_invoices',
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const putContactInvoice = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPutContactInvoiceArgs,
  BaseQueryFn,
  string,
  IContactInvoice
> =>
  build.mutation<IContactInvoice, IPutContactInvoiceArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['ContactInvoice'],
    query: (queryArg) => ({
      url: `api/contact_invoices/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });

export const postContactInvoiceFileItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostContactInvoiceFileArgs,
  BaseQueryFn,
  string,
  IContactInvoiceFileResource
> =>
  build.mutation<IContactInvoiceFileResource, IPostContactInvoiceFileArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags
        ? []
        : ['ContactInvoice', 'ContactInvoiceFile', 'ProjectTimesheet'],

    query: (queryArg) => ({
      url: `api/contact_invoices/${queryArg.id}/file`,
      method: 'POST',
      body: queryArg.body,
      extraOptions: { maxRetries: 0 },
    }),
  });
