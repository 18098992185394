import { useCallback } from 'react';

import { store } from 'store';
import {
  clearNotification,
  setNotification,
} from 'redux/notification/notification.slice';
import { INotificationState } from 'redux/notification/notification.slice.type';

interface IUseNotificationReturn {
  set: (props: INotificationState) => void;
  clear: () => void;
}

const useNotification = (): IUseNotificationReturn => {
  const set = useCallback(({ type, message }: INotificationState) => {
    store.dispatch(
      setNotification({
        type,
        message,
      })
    );
  }, []);

  const clear = useCallback(() => {
    store.dispatch(clearNotification());
  }, []);

  return {
    set,
    clear,
  };
};

export default useNotification;
