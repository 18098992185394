import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { NoInfer } from 'react-redux';

import type { ISelector } from 'interfaces/Selector.interface';
import type { ISorting } from 'hooks/useSort.hook';
import type { ISimpleFilter } from 'hooks/useFilters.hook';

export const createSelectorSlice = (
  name: string,
  currentPageDefault: number,
  itemsPerPageDefault: number,
  defaultSorting?: ISorting,
  extraReducers?: (builder: ActionReducerMapBuilder<NoInfer<ISelector>>) => void
) => {
  const initialState: ISelector = {
    totalCount: 1,
    pagination: {
      currentPage: currentPageDefault,
      itemsPerPage: itemsPerPageDefault,
    },
    defaultSorting: defaultSorting,
    sorting: defaultSorting || {
      columnKey: null,
      direction: 'asc',
    },
    filters: [],
    nestedFilters: {},
    nestedSorting: {},
    initialFiltersSet: false,
  };

  return createSlice({
    name,
    initialState,
    reducers: {
      setPagination: (
        state: ISelector,
        action: PayloadAction<Omit<ISelector, 'sorting'>>
      ) => {
        state.pagination = action.payload.pagination;
      },
      setNextPage: (state: ISelector, action: PayloadAction<number>) => {
        state.pagination.currentPage = action.payload;
      },
      setItemsPerPage: (state: ISelector, action: PayloadAction<number>) => {
        if (state.pagination.itemsPerPage !== action.payload) {
          state.pagination.currentPage = currentPageDefault;
        }
        state.pagination.itemsPerPage = action.payload;
      },
      setTotalCount: (state: ISelector, action: PayloadAction<number>) => {
        state.totalCount = action.payload;
      },
      setSorting: (state: ISelector, action: PayloadAction<ISorting>) => {
        state.sorting = action.payload;
      },
      setFilters: (
        state: ISelector,
        action: PayloadAction<ISimpleFilter[]>
      ) => {
        state.filters = action.payload;
        state.initialFiltersSet = true;
      },
    },
    extraReducers,
  });
};
