import { FC } from 'react';

import { useLanguage } from 'hooks/useLanguage.hook';
import { useCurrentUser } from 'hooks/useCurrentUser';

const LanguageInitializeProvider: FC = () => {
  const user = useCurrentUser();
  useLanguage({ skip: !user.isLoggedIn });

  return null;
};

export default LanguageInitializeProvider;
