import { styled, css } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

export interface IPaginationStyledProps {
  $withPagination?: boolean;
}

export const ResultsContainerStyled = styled.div<IPaginationStyledProps>`
  background-color: ${Theme.colors.greyShades.grey19};
  overflow: auto;
  height: 100%;
  width: 100%;
  padding: ${pxToRem([5, 8])};
  border-radius: ${pxToRem(5)};
  ${({ $withPagination }) =>
    !$withPagination &&
    css`
      overflow-y: auto;
    `}
`;

export const ResultsWrapper = styled.div`
  ${flexFn('center', 'center', 'column')};
  margin-bottom: ${pxToRem(20)};
`;
