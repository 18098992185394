export const calculateContactItemsMaxItems = (
  itemHeight = 40,
  itemWidth = 40,
  wrapperHeight = 50,
  wrapperWidth = 100
): number => {
  const rowsCount = Math.floor(wrapperHeight / itemHeight);
  const itemsPerRowCount = Math.floor(wrapperWidth / itemWidth);

  return rowsCount * itemsPerRowCount;
};
