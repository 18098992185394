import {
  BaseQueryFn,
  QueryDefinition,
  MutationDefinition,
  EndpointBuilder,
} from '@reduxjs/toolkit/query';

import type { IGalleryImage } from 'interfaces/Gallery.inteface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';
import type {
  IDeleteGalleryImageArgs,
  IGalleryImageEntityArgs,
  IGetGalleryImagesCollectionArgs,
  IGetGalleryImagesCollectionResponse,
  IPostGalleryImageArgs,
} from './galleryImages.api.types';

export const getGalleryImages = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetGalleryImagesCollectionArgs,
  BaseQueryFn,
  string,
  IGetGalleryImagesCollectionResponse
> =>
  build.query<
    IGetGalleryImagesCollectionResponse,
    IGetGalleryImagesCollectionArgs
  >({
    providesTags: ['GalleryImage'],
    query: (queryArg: IGetGalleryImagesCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);
      return `/api/gallery_images${paginationQuery}${filterQuery}`;
    },
    transformResponse: (response: ICollectionApiResponse<IGalleryImage>) =>
      transformCollectionResponse<IGalleryImage>(response),
  });

export const getPublicGalleryImages = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetGalleryImagesCollectionArgs,
  BaseQueryFn,
  string,
  IGetGalleryImagesCollectionResponse
> =>
  build.query<
    IGetGalleryImagesCollectionResponse,
    IGetGalleryImagesCollectionArgs
  >({
    providesTags: ['GalleryImage'],
    query: (queryArg: IGetGalleryImagesCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);
      return `/api/public_gallery_images${paginationQuery}${filterQuery}`;
    },
    transformResponse: (response: ICollectionApiResponse<IGalleryImage>) =>
      transformCollectionResponse<IGalleryImage>(response),
  });

export const getGalleryImageEntity = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGalleryImageEntityArgs,
  BaseQueryFn,
  string,
  IGalleryImage
> =>
  build.query<IGalleryImage, IGalleryImageEntityArgs>({
    providesTags: ['GalleryImage'],
    query: (queryArg: IGalleryImageEntityArgs) => ({
      url: `api/gallery_images/${queryArg.id}`,
    }),
  });

export const postGalleryImage = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostGalleryImageArgs,
  BaseQueryFn,
  string,
  IGalleryImage
> =>
  build.mutation({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['GalleryImage'],
    query: (queryArg: IPostGalleryImageArgs) => ({
      url: `/api/gallery_images`,
      method: 'POST',
      body: {
        gallery: queryArg.gallery,
        shift: queryArg.shift,
      },
    }),
  });

export const deleteGalleryImage = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IDeleteGalleryImageArgs,
  BaseQueryFn,
  string,
  IGalleryImage
> =>
  build.mutation({
    invalidatesTags: ['GalleryImage'],
    query: (queryArg: IDeleteGalleryImageArgs) => ({
      url: `/api/gallery_images/${queryArg.id}`,
      method: 'DELETE',
    }),
  });
