import { styled } from 'styled-components';
import { theme, Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

export type TStyle = 'modal' | 'bright';

interface IFilterDescriptionProps {
  $styleType: TStyle;
}

export const FilterRowStyled = styled.div`
  ${flexFn('flex-start', 'center', 'row')}
  gap: ${pxToRem(10)};
  padding: ${pxToRem([4, 0])};
`;

export const FilterDescriptionStyled = styled.span<IFilterDescriptionProps>`
  white-space: nowrap;
  font-size: ${pxToRem(14)};
  font-weight: bold;
  color: ${({ $styleType }) =>
    $styleType === 'modal'
      ? theme.colors.primary.white
      : theme.colors.primary.grey};
`;
