import { FC } from 'react';
import { SwitchDescriptive } from 'team-hero-ui';

import { IFilterRendererProps } from 'components/FilterPanel/FilterPanel.type';
import { FilterLeftElementStyled } from 'components/FilterPanel/filterRenderers/FilterElement.styled';

export const SwitchRenderer: FC<IFilterRendererProps> = ({
  label,
  onChange,
  value = false,
  styleType,
}) => {
  return (
    <FilterLeftElementStyled>
      <SwitchDescriptive
        styleType={styleType === 'modal' ? 'modal' : 'bright'}
        label={label}
        checked={value}
        onChange={onChange}
      />
    </FilterLeftElementStyled>
  );
};
