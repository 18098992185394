import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

const { pxToRem } = Utils;

export const ApplicationSedcardStyled = styled.div`
  background-color: ${Theme.colors.primary.white};
  width: ${pxToRem(400)};
  max-width: ${pxToRem(400)};
  height: 100%;
`;
