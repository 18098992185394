import { TFunction } from 'i18next';

import { IOption } from 'components/Modals/AddEditModal/modalConfig/modalConfig.interface';
import { IOptionGroup, IOptionGroupWithItems } from '../SelectField.types';

export const createOptionSelectGroups = (
  optionGroups: IOptionGroup[],
  selectItems: IOption[],
  t: TFunction
): IOptionGroupWithItems[] => {
  const optionGroupsWithItems: IOptionGroupWithItems[] = optionGroups.map(
    (group) => {
      const groupItems = selectItems.filter((item) =>
        group.values.includes(item.value)
      );
      return {
        label: group.label,
        values: groupItems,
      };
    }
  );
  // remove empty groups
  const filteredGroupsWithItems = optionGroupsWithItems.filter(
    (group) => group.values.length > 0
  );
  const restItems = selectItems.filter(
    (item) =>
      !filteredGroupsWithItems.some((group) =>
        group.values.some((groupItem) => groupItem.value === item.value)
      )
  );
  if (restItems.length) {
    filteredGroupsWithItems.push({
      label: t('selectGroups.rest'),
      values: restItems,
    });
  }
  return filteredGroupsWithItems;
};
