import { styled, css } from 'styled-components';
import { Utils, Theme } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

interface ICollapsibleGroupContentProps {
  $isOpen?: boolean;
  $height?: number;
}

interface IRotateWrapperProps {
  $isRotated?: boolean;
}

interface ITopBarLeftLegendWrapperProps {
  $width: number;
}

interface ITopBarRightLegendWrapperProps {
  $width: number;
}

interface ITopBarCenterWrapperProps {
  $width: number;
}

const calculateCssByStatus = (isOpen?: boolean, height?: number) => {
  if (isOpen) {
    return css`
      opacity: 1;
      height: ${height ? pxToRem(height) : 'auto'};
      padding-bottom: ${pxToRem(20)};
    `;
  }
  return css`
    opacity: 0;
    height: 0;
    padding-bottom: 0;
  `;
};

export const CollapsibleGroupContentWrapperStyled = styled.div<ICollapsibleGroupContentProps>`
  overflow: hidden;
  box-sizing: content-box;
  ${({ $isOpen, $height }) => calculateCssByStatus($isOpen, $height)};
`;

export const CollapsibleGroupStyled = styled.div`
  margin-top: ${pxToRem(5)};
  margin-bottom: ${pxToRem(10)};
  color: ${Theme.colors.primary.grey};
  background: ${Theme.colors.greyShades.grey5};
  width: 100%;
`;

export const CollapsibleGroupHeaderStyled = styled.div`
  height: ${pxToRem(30)};
  padding: 0;
  ${flexFn('flex-start', 'center', 'row')};
`;

export const RotateWrapperStyled = styled.span<IRotateWrapperProps>`
  transition: transform 0.5s ease;
  display: inline-flex;
  transform-origin: center;
  ${({ $isRotated }) =>
    $isRotated
      ? css`
          transform: rotate(90deg);
        `
      : css`
          transform: rotate(0deg);
        `};
`;

export const TopBarLeftLegendWrapperStyled = styled.div<ITopBarLeftLegendWrapperProps>`
  width: ${({ $width }) => ($width ? pxToRem($width) : pxToRem(0))};
  background: transparent;
  padding: ${pxToRem([0, 5, 0, 15])};
  ${flexFn('space-between', 'center', 'row')}
`;

export const TopBarLabelStyled = styled.span`
  color: ${Theme.colors.primary.grey};
  text-transform: uppercase;
  font-size: ${pxToRem(14)};
  font-weight: 800;
`;

export const TopBarRightLegendWrapperStyled = styled.div<ITopBarRightLegendWrapperProps>`
  width: ${({ $width }) => ($width ? pxToRem($width) : pxToRem(0))};
  background: transparent;
`;

export const TopBarCenterWrapperStyled = styled.div<ITopBarCenterWrapperProps>`
  ${flexFn('flex-start', 'stretch', 'row')}
  width: ${({ $width }) => ($width ? pxToRem($width) : pxToRem(0))};
`;
