import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { pxToRem } = Utils;

interface IBeginSeparatorCellRendererProps {
  disabled?: boolean;
}

export const BeginSeparatorCellRendererStyled = styled.div<IBeginSeparatorCellRendererProps>`
  width: 100%;
  height: 100%;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.colors.greyShades.grey6 : theme.colors.primary.white};
    border-top-left-radius: ${pxToRem(5)};
    border-bottom-left-radius: ${pxToRem(5)};
  }
`;
