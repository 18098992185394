export interface IOutputNumberProps {
  value?: number;
  maxDigits?: number;
  minDigits?: number;
  locale?: Intl.LocalesArgument;
}

export const getOutputNumber = ({
  value,
  maxDigits = 1,
  minDigits,
  locale = 'de',
}: IOutputNumberProps): string => {
  if (value === 0 || value) {
    return value.toLocaleString(locale, {
      maximumFractionDigits: maxDigits,
      minimumFractionDigits: minDigits,
    });
  }

  return '';
};

export interface IGetPercentageStringProps {
  numberOne: number;
  numberTwo: number;
  maxDigits?: number;
  locale?: Intl.LocalesArgument;
}

export const getPercentageString = ({
  numberOne,
  numberTwo,
  maxDigits = 0,
  locale,
}: IGetPercentageStringProps): string => {
  if (numberTwo <= 0 || numberOne <= 0) {
    return '0%';
  }
  return `${getOutputNumber({ value: (numberOne / numberTwo) * 100, maxDigits, locale })}%`;
};
