import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { IUseFiltersReturn } from 'hooks/useFilters.hook';
import { IUseToggleReturn } from 'hooks/useToggle.hook';
import FilterChips from 'components/FilterChips';
import { FilterModal } from '../FilterModal';
import { getInitialFilterData } from './util';
import { IFilterInitialValue } from './useInitialFilterValues.hook';
import { IFilterDefinition } from 'interfaces/Table/DataTableFilters.interface';

interface IUseFilterModalArgs {
  filterData?: IUseFiltersReturn;
  filterDefinitionList?: IFilterDefinition[];
  visibility: IUseToggleReturn;
  onClose?: () => void;
}
interface IUseFilterModalReturn {
  FilterModalComponent: JSX.Element | null;
  FilterChipsComponent: JSX.Element | null;
}

const useFilterModal = ({
  filterData,
  filterDefinitionList,
  visibility,
  onClose,
}: IUseFilterModalArgs): IUseFilterModalReturn => {
  const { handleSubmit, control, reset } = useForm();

  const handleOnClose = useCallback(() => {
    visibility?.off();
    onClose?.();
  }, [onClose, visibility]);

  const onClearFilter = useCallback(
    (key: string, value: string | number | boolean | null) => {
      const currentFilters = filterData?.filters?.filter(
        (filter) => !(filter.key === key && filter.value === value)
      );

      filterData?.onSetFilters(currentFilters || [], () => {
        const filtersData: IFilterInitialValue[] = getInitialFilterData({
          filterDefinitionList: filterDefinitionList || [],
          alreadySetFilters: currentFilters || [],
          initialFiltersSet: true,
        });

        const formData = filtersData.reduce(
          (ac, a) => ({ ...ac, [a.key]: a.value }),
          {}
        );

        reset(formData);
      });
    },
    [filterData, filterDefinitionList, reset]
  );

  const FilterModalComponent = useMemo(() => {
    if (!filterData || !filterDefinitionList?.length) {
      return null;
    }

    return (
      <FilterModal
        filterDefinitionList={filterDefinitionList}
        filterData={filterData}
        hidden={!visibility.toggleValue}
        control={control}
        onClose={handleOnClose}
        reset={reset}
        handleSubmit={handleSubmit}
      />
    );
  }, [
    control,
    filterData,
    filterDefinitionList,
    handleOnClose,
    handleSubmit,
    reset,
    visibility.toggleValue,
  ]);

  const FilterChipsComponent = useMemo(() => {
    if (!filterData) {
      return null;
    }

    return (
      <FilterChips
        filters={filterData.filters}
        filterDefinitions={filterDefinitionList || []}
        onClearFilter={onClearFilter}
      />
    );
  }, [filterData, filterDefinitionList, onClearFilter]);

  return {
    FilterModalComponent,
    FilterChipsComponent,
  };
};

export default useFilterModal;
