import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TRootState } from 'store';
import { ICalendarState, TAvailabilityEvent } from './calendar.slice.type';

export const initialState: ICalendarState = {
  selectedAbsenceId: undefined,
  selectedAvailability: undefined,
};

const setAbsenceId: CaseReducer<
  ICalendarState,
  PayloadAction<Omit<ICalendarState, 'selectedAvailability'>>
> = (state, { payload: { selectedAbsenceId } }) => {
  state.selectedAbsenceId = selectedAbsenceId;
};

const setAvailability: CaseReducer<
  ICalendarState,
  PayloadAction<Omit<ICalendarState, 'selectedAbsenceId'>>
> = (state, { payload: { selectedAvailability } }) => {
  state.selectedAvailability = selectedAvailability;
};

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setCalendarAbsenceId: setAbsenceId,
    setAvailabilityState: setAvailability,
    clearCalendarState: () => initialState,
  },
});

export const {
  setCalendarAbsenceId,
  setAvailabilityState,
  clearCalendarState,
} = calendarSlice.actions;

export const calendarAbsenceSelector = (
  state: TRootState
): number | undefined => state.calendar.selectedAbsenceId;

export const calendarAvailabilitySelector = (
  state: TRootState
): TAvailabilityEvent | undefined => state.calendar.selectedAvailability;

export default calendarSlice.reducer;
