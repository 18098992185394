import {
  checkEmbedIsShift,
  checkEmbedIsString,
} from 'helpers/embed/embedTypeCheck.helper';
import {
  IProjectShift,
  IProjectShiftEmbed,
  IProjectShiftWorkplanner,
} from 'interfaces/ProjectShift.interface';

export const getShiftIdFromIriOrEmbed = (
  shift?: IProjectShiftWorkplanner | IProjectShift | IProjectShiftEmbed | string
): number | undefined => {
  if (!shift) {
    return undefined;
  }
  if (checkEmbedIsShift(shift)) {
    return shift.id;
  }

  if (checkEmbedIsString(shift)) {
    const id = parseInt(shift.replace('/api/project_shifts/', ''), 10);
    if (isNaN(id)) {
      return undefined;
    }
    return id;
  }
};
