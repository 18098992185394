import {
  BaseQueryFn,
  EndpointBuilder,
  QueryDefinition,
} from '@reduxjs/toolkit/query';

import type { IWidget } from 'interfaces/Widget/Widget.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import type {
  IWidgetCollectionArgs,
  IWidgetCollectionResponse,
  IWidgetItemArgs,
  TWidgetItemResponse,
} from './widgets.types';
import { serializeFilters } from 'services/helper/getPaginationParams.helper';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';

export const getWidgets = <T>(
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IWidgetCollectionArgs,
  BaseQueryFn,
  string,
  IWidgetCollectionResponse<T>
> =>
  build.query<IWidgetCollectionResponse<T>, IWidgetCollectionArgs>({
    providesTags: (_result, _error, args) =>
      args.filters?.some((filter) => filter.key === 'group')
        ? [
            {
              type: 'Widget',
              id:
                `${
                  args.filters.find((filter) => filter.key === 'group')?.value
                }` || '',
            },
          ]
        : ['Widget'],
    query: (queryArg: IWidgetCollectionArgs) => {
      const filterQuery = serializeFilters(queryArg.filters, '?');

      return {
        url: `api/widgets${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IWidget<T>>
    ): IWidgetCollectionResponse<T> =>
      transformCollectionResponse<IWidget<T>>(response),
  });

export const getWidgetItem = <T>(
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IWidgetItemArgs,
  BaseQueryFn,
  string,
  TWidgetItemResponse<T>
> =>
  build.query<TWidgetItemResponse<T>, IWidgetItemArgs>({
    providesTags: (_result, _error, args) =>
      args.key ? [{ type: 'Widget', id: args.key }] : ['Widget'],
    query: (queryArg: IWidgetItemArgs) => {
      const filterQuery = serializeFilters(queryArg.filters, '?');
      const urlKey = queryArg.key ? `/${queryArg.key}` : '';

      return {
        url: `api/widgets${urlKey}${filterQuery}`,
      };
    },
  });
