import {
  EndpointBuilder,
  QueryDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type { IRewardProfile } from 'interfaces/RewardProfile.interface';
import type { IGetRewardProfileItemArgs } from './rewardProfile.api.types';

export const getRewardProfile = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetRewardProfileItemArgs,
  BaseQueryFn,
  string,
  IRewardProfile
> =>
  build.query<IRewardProfile, IGetRewardProfileItemArgs>({
    providesTags: ['RewardProfile'],
    query: (queryArgs) => {
      return {
        url: `/api/reward_profiles/${queryArgs.id}`,
      };
    },
  });
