import type { TRootState } from 'store';
import type { ISelector } from 'interfaces/Selector.interface';
import { createSelectorSlice } from 'redux/collections/helper/createSelector.slice';
import {
  currentPageDefault,
  itemsPerPageDefault,
} from 'redux/collections/consts/selectorDefaults.consts';

export const projectShiftSlice = createSelectorSlice(
  'projectShifts',
  currentPageDefault,
  itemsPerPageDefault
);

const { actions, reducer } = projectShiftSlice;

export const {
  setPagination,
  setNextPage,
  setSorting,
  setItemsPerPage,
  setFilters,
} = actions;

export const paginatedProjectShiftsSelector = (
  state: TRootState
): ISelector => ({
  ...state.projectShift,
  filters: [],
});

export default reducer;
