import { Tag, Theme, Utils } from 'team-hero-ui';
import { styled } from 'styled-components';

import { StatusCellRenderer } from 'components/cellRenderers/StatusCellRenderer/StatusCellRenderer';
import type { IProjectMission } from 'interfaces/ProjectMission.interface';
import { IColumnDefinitionType } from 'interfaces/Table/DataTableColumn.interface';
import { searchColumnsWidths } from './utils';
import { DateCellRenderer } from 'components/cellRenderers/DateCellRenderer/DateCellRenderer';
import { getStatusColor } from './getStatusColor.helper';
import { LeaderCellRenderer } from 'components/cellRenderers/LeaderCellRenderer/LeaderCellRenderer';
import { GrayedCellRenderer } from 'components/cellRenderers/wta';
import { TruncatedCellStyled } from 'components/Table/components/TableBody/TableBody.styled';

const customWidths = searchColumnsWidths;
const visibility = true;
const label = '';

const { pxToRem } = Utils;

const ProjectTitleTag = styled.div`
  overflow: hidden;
  margin-right: ${pxToRem(4)};
  & > div {
    width: 100%;
  }
`;

export const missionsSearchColumnsFactory = (
  isSmScreenSize: boolean
): IColumnDefinitionType<IProjectMission>[] => {
  return [
    {
      key: 'status',
      label,
      visibility: isSmScreenSize,
      minWidth: customWidths.STATUSWIDE,
      width: customWidths.STATUSWIDE,
      cellType: 'custom',
      cellRenderer: ({ status }) => StatusCellRenderer(getStatusColor(status)),
    },
    {
      key: 'projectTitle',
      label,
      visibility,
      cellType: 'custom',
      minWidth: isSmScreenSize ? customWidths.M : customWidths.XS,
      cellRenderer: ({ project }) => (
        <ProjectTitleTag>
          <Tag>
            <TruncatedCellStyled>{project.title}</TruncatedCellStyled>
          </Tag>
        </ProjectTitleTag>
      ),
    },
    {
      key: 'title',
      label,
      visibility,
      cellType: 'string',
      minWidth: isSmScreenSize ? customWidths.M : customWidths.XS,
    },
    {
      key: 'start',
      label,
      visibility,
      cellType: 'custom',
      minWidth: isSmScreenSize ? customWidths.DATE : undefined,
      width: isSmScreenSize ? undefined : '44px',
      cellRenderer: (mission) =>
        isSmScreenSize ? (
          DateCellRenderer({
            date: mission.start,
          })
        ) : (
          <GrayedCellRenderer
            bgColor={Theme.colors.primary.white}
            color={Theme.colors.greyShades.grey3}
          >
            {DateCellRenderer({
              date: mission.start,
              format: 'dateShort',
            })}
          </GrayedCellRenderer>
        ),
    },
    {
      key: 'end',
      label,
      visibility,
      cellType: 'custom',
      minWidth: customWidths.DATE,
      width: isSmScreenSize ? undefined : '0px',
      cellRenderer: (mission) => (
        <div style={{ display: isSmScreenSize ? 'block' : 'none' }}>
          {DateCellRenderer({ date: mission.end })}
        </div>
      ),
    },
    {
      key: 'addressCity',
      label,
      visibility: isSmScreenSize,
      cellType: 'string',
      minWidth: customWidths.S,
    },
    {
      key: 'leader',
      label,
      visibility: isSmScreenSize,
      width: customWidths.AVATAR,
      minWidth: customWidths.AVATAR,
      cellType: 'custom',
      cellRenderer: ({ leader }) =>
        LeaderCellRenderer({
          embeddedContact: leader,
        }),
    },
  ];
};
