import {
  EndpointBuilder,
  MutationDefinition,
  QueryDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type { IInventoryImageResource } from 'interfaces/InventoryImage.interface';
import type {
  IInventoryImageEntityArgs,
  IDeleteInventoryImageArgs,
  IPostInventoryImageArgs,
  IPostInventoryImageEntryArgs,
  IPutInventoryImageEntryArgs,
} from './inventoryImages.api.types';

const BASE_URL = 'api/inventory_images';
const TAG = 'InventoryImage';

export const getInventoryImageEntity = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IInventoryImageEntityArgs,
  BaseQueryFn,
  string,
  IInventoryImageResource
> =>
  build.query<IInventoryImageResource, IInventoryImageEntityArgs>({
    providesTags: [TAG],
    query: (queryArg: IInventoryImageEntityArgs) => ({
      url: `${BASE_URL}/${queryArg.id}`,
    }),
  });

export const postInventoryImageEntityItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostInventoryImageEntryArgs,
  BaseQueryFn,
  string,
  IInventoryImageResource
> =>
  build.mutation<IInventoryImageResource, IPostInventoryImageEntryArgs>({
    invalidatesTags: [TAG],
    query: (queryArg) => ({
      url: BASE_URL,
      method: 'POST',
      body: queryArg,
    }),
  });

export const putInventoryImageEntityItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPutInventoryImageEntryArgs,
  BaseQueryFn,
  string,
  IInventoryImageResource
> =>
  build.mutation<IInventoryImageResource, IPutInventoryImageEntryArgs>({
    invalidatesTags: [TAG],
    query: (queryArg) => ({
      url: `${BASE_URL}/${queryArg.id}`,
      method: 'PUT',
      body: queryArg,
    }),
  });

export const deleteInventoryImageItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IDeleteInventoryImageArgs,
  BaseQueryFn,
  string,
  unknown
> =>
  build.mutation<unknown, IDeleteInventoryImageArgs>({
    invalidatesTags: [TAG],
    query: (queryArg) => ({
      url: `${BASE_URL}/${queryArg.id}`,
      method: 'DELETE',
    }),
  });

export const postInventoryImageItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostInventoryImageArgs,
  BaseQueryFn,
  string,
  IInventoryImageResource
> =>
  build.mutation<IInventoryImageResource, IPostInventoryImageArgs>({
    query: (queryArg) => ({
      url: `${BASE_URL}/${queryArg.id}/file`,
      method: 'POST',
      body: queryArg.body,
      extraOptions: { maxRetries: 0 },
    }),
  });
