import {
  BaseQueryFn,
  QueryDefinition,
  EndpointBuilder,
} from '@reduxjs/toolkit/query';

import type { IMissionFileResource } from 'interfaces/File.interface';
import type { IFileEntityArgs } from './projectMissionFiles.api.types';

export const getMissionFileEntity = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IFileEntityArgs,
  BaseQueryFn,
  string,
  IMissionFileResource
> =>
  build.query<IMissionFileResource, IFileEntityArgs>({
    query: (queryArg: IFileEntityArgs) => ({
      url: `/api/mission_files/${queryArg.id}`,
    }),
  });
