import { useEffect, useState } from 'react';

import { ISelectValue } from 'interfaces/Data/SelectValue.interface';
import type { ISimpleFilter } from 'hooks/useFilters.hook';
import { getInitialFilterData } from './util';
import { IFilterDefinition } from 'interfaces/Table/DataTableFilters.interface';

interface IDateRangePickerValue {
  from: Date | string | null;
  to: Date | string | null;
}

interface IFromToNumberPickerData {
  from: number | null;
  to: number | null;
}

export interface IFilterInitialValue
  extends Pick<IFilterDefinition, 'key' | 'filterType'> {
  value:
    | string
    | string[]
    | boolean
    | ISelectValue
    | IFromToNumberPickerData
    | IDateRangePickerValue
    | Date
    | number;
}

interface IUseInitialFilterValuesReturn {
  initialValues: IFilterInitialValue[];
  initialValuesObject: Record<string, unknown>;
  initialValuesResetObject: Record<string, unknown>;
}

export const useInitialFilterValues = (
  filterDefinitionList: IFilterDefinition[],
  alreadySetFilters: ISimpleFilter[],
  initialFiltersSet = false
): IUseInitialFilterValuesReturn => {
  const [initialValues, setInitialValues] = useState<IFilterInitialValue[]>([]);
  const [initialValuesObject, setInitialValuesObject] = useState<
    Record<string, unknown>
  >({});
  const [initialValuesResetObject, setInitialValuesResetObject] = useState<
    Record<string, unknown>
  >({});

  useEffect(() => {
    if (!initialValues.length) {
      const initialFilterData = getInitialFilterData({
        filterDefinitionList,
        alreadySetFilters,
        initialFiltersSet,
      });
      setInitialValues(initialFilterData);
      setInitialValuesObject(
        initialFilterData.reduce((ac, a) => ({ ...ac, [a.key]: a.value }), {})
      );
      setInitialValuesResetObject(
        initialFilterData.reduce((ac, a) => ({ ...ac, [a.key]: '' }), {})
      );
    }
  }, [
    alreadySetFilters,
    filterDefinitionList,
    initialFiltersSet,
    initialValues.length,
  ]);

  return {
    initialValues,
    initialValuesObject,
    initialValuesResetObject,
  };
};
