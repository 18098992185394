import { useCallback, useEffect, useState } from 'react';
import { Mutex } from 'async-mutex';

import type {
  BaseQueryFn,
  TypedUseLazyQuery,
  TypedUseQuery,
} from '@reduxjs/toolkit/query/react';
import type { IId } from 'interfaces/IId.interface';
import type { IMeta } from 'interfaces/Meta.interface';
import type { ISorting } from 'components/TableView/hooks/useSort.hook';
import type { ISimpleFilter } from 'hooks/useFilters.hook';
import type { IGetInfiniteLoadingArgs } from 'components/InfiniteLoader/InfiniteLoader.types';
import { useErrorHandler } from 'hooks/useDisplayApiError.hook';
import { createPaginationApiArgs } from 'services/helper/getPaginationParams.helper';
import { teamHeroApi } from 'services/teamHeroApi.service';
import { useAppDispatch } from 'store/hooks';
import { getNumberArray } from 'helpers/array/getNumberArray.helper';

export type TTagsToRevalidate =
  | 'Message'
  | 'MessageConversation'
  | 'UserNotification'
  | 'ProjectMission';

interface IUseLazyInfiniteLoadingProps<T> {
  itemsPerPage?: number;
  filters: ISimpleFilter[];
  sorting?: ISorting;
  skip?: boolean;
  tagsToRevalidate?: TTagsToRevalidate[]; // Add the required type argument for TagDescription
  query: TypedUseQuery<TResultType<T>, IGetInfiniteLoadingArgs, BaseQueryFn>;
  lazyQuery: TypedUseLazyQuery<
    TResultType<T>,
    IGetInfiniteLoadingArgs,
    BaseQueryFn
  >;
  showLoadButtonEveryXPages?: number;
}

interface IUseLazyInfiniteLoadingResult<T> {
  data: T[];
  loadMore: () => void;
  hasMore: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isFetchingMore: boolean;
  isError: boolean;
  refetch: () => void;
  pageNumber: number;
  totalCount: number;
  showLoadButtonAfterPage: number;
  setNextLoadButtonNumber: () => void;
}

type TResultType<T> = {
  items: T[];
  totalCount: number;
};

type TItemType = IMeta & IId;

const mutex = new Mutex();

export const useLazyInfiniteLoading = <T extends TItemType>({
  itemsPerPage = 20,
  filters,
  sorting,
  skip,
  query,
  lazyQuery,
  tagsToRevalidate,
  showLoadButtonEveryXPages = 3,
}: IUseLazyInfiniteLoadingProps<T>): IUseLazyInfiniteLoadingResult<T> => {
  const dispatch = useAppDispatch();

  const [trigger] = lazyQuery();

  const [showLoadButtonAfterPage, setShowLoadButtonAfterPage] =
    useState<number>(showLoadButtonEveryXPages);

  const [pageNumber, setPageNumber] = useState(1); // first load only page 1

  const [maxPage, setMaxPage] = useState(0); // we don't know how many pages could exist yet

  const [accData, setAccData] = useState<T[]>([]);

  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const { handleError } = useErrorHandler();

  const {
    data: firstPageData,
    isLoading,
    isFetching,
    isError,
    refetch: refetchFirstPage,
  } = query(
    {
      ...createPaginationApiArgs({ itemsPerPage, currentPage: 1 }, sorting),
      filters,
    },
    {
      skip: skip,
    }
  );

  const refetch = useCallback(() => {
    dispatch(teamHeroApi.util.invalidateTags(tagsToRevalidate || []));
    refetchFirstPage();
  }, [dispatch, refetchFirstPage, tagsToRevalidate]);

  /** when params like filters change, then we reset the loading pages number to 1 */
  useEffect(() => {
    setPageNumber(1);
    // also reset the showLoadButtonAfterPage value
    setShowLoadButtonAfterPage(showLoadButtonEveryXPages);
  }, [filters, sorting, itemsPerPage, showLoadButtonEveryXPages]);

  const fetchAndSetData = useCallback(async () => {
    await mutex.waitForUnlock();
    const totalCount = firstPageData?.totalCount || 0;
    if (firstPageData) {
      setMaxPage(Math.ceil(totalCount / itemsPerPage));
    }

    if (pageNumber === 1) {
      setAccData(firstPageData?.items || []);
    }

    if (pageNumber > 1) {
      setIsFetchingMore(true);
      // run through all pages and get the data from lazyTrigger function
      const promises = getNumberArray(2, pageNumber).map((currentPage) =>
        trigger(
          {
            ...createPaginationApiArgs({ itemsPerPage, currentPage }, sorting),
            filters,
          },
          true // use data from cache if exists
        ).unwrap()
      );

      const release = await mutex.acquire();
      Promise.all(promises)
        .then((data) => {
          const items: T[] = [firstPageData, ...data].reduce<T[]>(
            (acc, curr) => [...acc, ...(curr?.items ?? [])],
            []
          );
          setAccData(items);
        })
        .catch((error) => {
          handleError(error);
          setPageNumber(1);
        })
        .finally(() => {
          setIsFetchingMore(false);
          release();
        });
    }
  }, [
    filters,
    firstPageData,
    handleError,
    itemsPerPage,
    pageNumber,
    sorting,
    trigger,
  ]);

  // this useEffect will run and load more data on page number increase
  useEffect(() => {
    fetchAndSetData().catch((e) => handleError(e));
  }, [
    firstPageData,
    pageNumber,
    trigger,
    fetchAndSetData,
    // every time, the first page isFetching, also run fetches of other pages
    isFetching,
    filters,
    itemsPerPage,
    handleError,
    sorting,
  ]);

  const loadMore = useCallback(() => {
    setPageNumber((prevState) => prevState + 1);
  }, []);

  const setNextLoadButtonNumber = useCallback(() => {
    setShowLoadButtonAfterPage((prev) => prev + showLoadButtonEveryXPages);
  }, [showLoadButtonEveryXPages]);

  return {
    data: accData,
    loadMore,
    hasMore: pageNumber < maxPage,
    isLoading,
    isFetching,
    isFetchingMore,
    isError,
    refetch,
    pageNumber,
    totalCount: firstPageData?.totalCount || 0,
    showLoadButtonAfterPage,
    setNextLoadButtonNumber,
  };
};
