import { FC } from 'react';

import { useSearchPopup } from 'hooks/useSearchPopup.hook';
import { useCurrentUser } from 'hooks/useCurrentUser';

const SearchPopupProvider: FC = () => {
  const user = useCurrentUser();

  const { searchPopup } = useSearchPopup();

  return user.isLoggedIn ? searchPopup : null;
};

export default SearchPopupProvider;
