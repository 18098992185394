import {
  EndpointBuilder,
  QueryDefinition,
  MutationDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type { IProjectMission } from 'interfaces/ProjectMission.interface';
import type {
  IProjectShift,
  IProjectShiftWorkplannerApiResponse,
} from 'interfaces/ProjectShift.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import type {
  IProjectShiftConfirmBriefingArgs,
  IProjectShiftCollectionArgs,
  IProjectShiftCollectionResponse,
  IProjectShiftArgs,
  IProjectShiftPutStatusArgs,
  IProjectShiftPutArgs,
  IProjectShiftRemindPutArgs,
  IProjectShiftWorkplannerCollectionResponse,
} from './projectShifts.api.types';
import { transformShiftWorkplannerCollectionResponse } from './transformShiftCollectionResponse.helper';

const defaultTotalCount = 0;
const defaultItems: IProjectMission[] = [];

export const getProjectShifts = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IProjectShiftCollectionArgs,
  BaseQueryFn,
  string,
  IProjectShiftCollectionResponse
> =>
  build.query<IProjectShiftCollectionResponse, IProjectShiftCollectionArgs>({
    providesTags: ['ProjectShift'],
    query: (queryArg: IProjectShiftCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        contact: queryArg.contact,
        during: queryArg.during,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);

      return {
        url: `api/project_shifts${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IProjectShift>
    ): IProjectShiftCollectionResponse => {
      const totalCount =
        (response['hydra:totalItems'] as number) || defaultTotalCount;
      const items =
        (response['hydra:member'] as IProjectShift[]) || defaultItems;

      return { items, totalCount };
    },
  });

export const getProjectShiftsWorkplanner = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IProjectShiftCollectionArgs,
  BaseQueryFn,
  string,
  IProjectShiftWorkplannerCollectionResponse
> =>
  build.query<
    IProjectShiftWorkplannerCollectionResponse,
    IProjectShiftCollectionArgs
  >({
    providesTags: ['ProjectShift'],
    query: (queryArg: IProjectShiftCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);

      return {
        url: `api/project_shifts/workplanner${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IProjectShiftWorkplannerApiResponse>
    ): IProjectShiftWorkplannerCollectionResponse =>
      transformShiftWorkplannerCollectionResponse(response),
  });

export const getProjectShiftItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<IProjectShiftArgs, BaseQueryFn, string, IProjectShift> =>
  build.query<IProjectShift, IProjectShiftArgs>({
    providesTags: ['ProjectShift'],
    query: (queryArg: IProjectShiftArgs) => ({
      url: `/api/project_shifts/${queryArg.id}`,
    }),
  });

export const putProjectShiftItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IProjectShiftPutArgs,
  BaseQueryFn,
  string,
  IProjectShift
> =>
  build.mutation<IProjectShift, IProjectShiftPutArgs>({
    invalidatesTags: ['ProjectShift'],
    query: (queryArg) => ({
      url: `/api/project_shifts/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });

export const putProjectShiftsConfirmBriefing = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IProjectShiftConfirmBriefingArgs,
  BaseQueryFn,
  string,
  IProjectShift
> =>
  build.mutation<IProjectShift, IProjectShiftConfirmBriefingArgs>({
    invalidatesTags: ['ProjectShift'],
    query: (queryArg) => ({
      url: `/api/project_shifts/${queryArg.id}`,
      method: 'PUT',
      body: {
        isBriefed: true,
      },
    }),
  });

export const putProjectShiftRemind = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IProjectShiftRemindPutArgs,
  BaseQueryFn,
  string,
  IProjectShift
> =>
  build.mutation<IProjectShift, IProjectShiftRemindPutArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags
        ? []
        : ['ProjectShift', 'ProjectMission', 'Project'],
    query: (queryArg) => ({
      url: `api/project_shifts/${queryArg.id}/remind`,
      method: 'PUT',
      body: {},
    }),
  });

export const putProjectShiftItemStatus = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IProjectShiftPutStatusArgs,
  BaseQueryFn,
  string,
  IProjectShift
> =>
  build.mutation<IProjectShift, IProjectShiftPutStatusArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags
        ? []
        : ['ProjectShift', 'ProjectMission', 'Project'],
    query: (queryArg) => ({
      url: `/api/project_shifts/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });
