export enum ELocalStorageKeys {
  heroUserToken = 'hero_user.token',
  heroUserRefreshToken = 'hero_user.refresh_token',
  localeOverride = 'locale.override',
  notificationsGrouped = 'hero_user.notifications_grouped',
  systemNotificationsHidden = 'hero_user.system_notifications_hidden',
}

export default class LocalStorageService {
  public static set = (key: ELocalStorageKeys, value: string): void => {
    localStorage.setItem(key, value);
  };

  public static get = (key: ELocalStorageKeys): string | null => {
    return localStorage.getItem(key);
  };

  public static remove = (key: ELocalStorageKeys): void => {
    localStorage.removeItem(key);
  };

  public static clearAll = (): void => {
    localStorage.clear();
  };
}
