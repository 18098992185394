import {
  EndpointBuilder,
  QueryDefinition,
  MutationDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type { IApplicationDocument } from 'interfaces/ApplicationDocument.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';
import type {
  IApplicationDocumentCollectionArgs,
  IApplicationDocumentCollectionResponse,
  IDeleteApplicationDocumentItemArgs,
  IGetApplicationDocumentArgs,
  IPostApplicationDocumentItemArgs,
  IPutApplicationDocumentItemArgs,
} from './applicationDocuments.api.types';

export const getApplicationDocuments = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IApplicationDocumentCollectionArgs,
  BaseQueryFn,
  string,
  IApplicationDocumentCollectionResponse
> =>
  build.query<
    IApplicationDocumentCollectionResponse,
    IApplicationDocumentCollectionArgs
  >({
    providesTags: ['ApplicationDocument'],
    query: (queryArg: IApplicationDocumentCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);

      return {
        url: `api/application_documents${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IApplicationDocument>
    ): IApplicationDocumentCollectionResponse =>
      transformCollectionResponse<IApplicationDocument>(response),
  });

export const getApplicationDocumentItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetApplicationDocumentArgs,
  BaseQueryFn,
  string,
  IApplicationDocument
> =>
  build.query<IApplicationDocument, IGetApplicationDocumentArgs>({
    providesTags: ['ApplicationDocument'],
    query: (queryArg: IGetApplicationDocumentArgs) => ({
      url: `api/application_documents/${queryArg.id}`,
    }),
  });

export const postApplicationDocumentItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostApplicationDocumentItemArgs,
  BaseQueryFn,
  string,
  IApplicationDocument
> =>
  build.mutation<IApplicationDocument, IPostApplicationDocumentItemArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['ApplicationDocument'],
    query: (queryArg) => ({
      url: `api/application_documents`,
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const postPublicApplicationDocumentItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostApplicationDocumentItemArgs,
  BaseQueryFn,
  string,
  IApplicationDocument
> =>
  build.mutation<IApplicationDocument, IPostApplicationDocumentItemArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['ApplicationDocument'],
    query: (queryArg) => ({
      url: `api/application_documents/public`,
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const putApplicationDocumentItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPutApplicationDocumentItemArgs,
  BaseQueryFn,
  string,
  IApplicationDocument
> =>
  build.mutation<IApplicationDocument, IPutApplicationDocumentItemArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['ApplicationDocument'],
    query: (queryArg) => ({
      url: `api/application_documents/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });

export const deleteApplicationDocumentItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IDeleteApplicationDocumentItemArgs,
  BaseQueryFn,
  string,
  unknown
> =>
  build.mutation<unknown, IDeleteApplicationDocumentItemArgs>({
    invalidatesTags: ['ApplicationDocument'],
    query: (queryArg) => ({
      url: `api/application_documents/${queryArg.id}`,
      method: 'DELETE',
    }),
  });
