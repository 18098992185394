import {
  EndpointBuilder,
  MutationDefinition,
  QueryDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type { IApplicationImageResource } from 'interfaces/ApplicationImage.interface';
import type {
  IApplicationImageEntityArgs,
  IDeleteApplicationImageArgs,
  IPostApplicationImageArgs,
  IPostApplicationImageEntryArgs,
  IPutApplicationImageEntryArgs,
} from './applicationImages.api.types';

export const getApplicationImageEntity = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IApplicationImageEntityArgs,
  BaseQueryFn,
  string,
  IApplicationImageResource
> =>
  build.query<IApplicationImageResource, IApplicationImageEntityArgs>({
    providesTags: ['ApplicationImage'],
    query: (queryArg: IApplicationImageEntityArgs) => ({
      url: `api/application_images/${queryArg.id}`,
    }),
  });

export const postApplicationImageEntityItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostApplicationImageEntryArgs,
  BaseQueryFn,
  string,
  IApplicationImageResource
> =>
  build.mutation<IApplicationImageResource, IPostApplicationImageEntryArgs>({
    invalidatesTags: ['ApplicationImage'],
    query: (queryArg) => ({
      url: `api/application_images`,
      method: 'POST',
      body: queryArg,
    }),
  });

export const postApplicationImagePublicEntityItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostApplicationImageEntryArgs,
  BaseQueryFn,
  string,
  IApplicationImageResource
> =>
  build.mutation<IApplicationImageResource, IPostApplicationImageEntryArgs>({
    invalidatesTags: ['ApplicationImage'],
    query: (queryArg) => ({
      url: `api/application_images/public`,
      method: 'POST',
      body: queryArg,
    }),
  });

export const putApplicationImageEntityItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPutApplicationImageEntryArgs,
  BaseQueryFn,
  string,
  IApplicationImageResource
> =>
  build.mutation<IApplicationImageResource, IPutApplicationImageEntryArgs>({
    invalidatesTags: ['ApplicationImage'],
    query: (queryArg) => ({
      url: `api/application_images/${queryArg.id}`,
      method: 'PUT',
      body: queryArg,
    }),
  });

export const deleteApplicationImageItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IDeleteApplicationImageArgs,
  BaseQueryFn,
  string,
  unknown
> =>
  build.mutation<unknown, IDeleteApplicationImageArgs>({
    invalidatesTags: ['ApplicationImage'],
    query: (queryArg) => ({
      url: `api/application_images/${queryArg.id}`,
      method: 'DELETE',
    }),
  });

export const postApplicationImageItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostApplicationImageArgs,
  BaseQueryFn,
  string,
  IApplicationImageResource
> =>
  build.mutation<IApplicationImageResource, IPostApplicationImageArgs>({
    query: (queryArg) => ({
      url: `api/application_images/${queryArg.id}/file`,
      method: 'POST',
      body: queryArg.body,
      extraOptions: { maxRetries: 0 },
    }),
  });

export const postApplicationImagePublicItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostApplicationImageArgs,
  BaseQueryFn,
  string,
  IApplicationImageResource
> =>
  build.mutation<IApplicationImageResource, IPostApplicationImageArgs>({
    query: (queryArg) => ({
      url: `api/application_images/public/${queryArg.id}/file`,
      method: 'POST',
      body: queryArg.body,
      extraOptions: { maxRetries: 0 },
    }),
  });
