import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  device,
  Dropdown as DropdownUI,
  DropdownItem,
  Icons,
  Theme,
  Utils,
} from 'team-hero-ui';

import { PaginationDropdownStyled } from './Dropdown.styled';

export interface IDropdownItem<T> {
  label: string;
  value: T;
}

export interface IDropdownProps<T> {
  items: IDropdownItem<T>[];
  onChange: (value: T) => void;
  selected: string | number;
}

const { useMediaQuery } = Utils;

export const Dropdown = <T,>({
  items,
  onChange,
  selected,
}: IDropdownProps<T>): ReactElement => {
  const isScreenSize = useMediaQuery(device.sm);

  const [closed, setClosed] = useState(false);
  const { t } = useTranslation(['general']);

  const handleClick = (value: T) => {
    onChange(value);
    setClosed(true);
  };

  const handleClose = () => {
    setClosed(false);
  };

  return (
    <PaginationDropdownStyled>
      <DropdownUI
        color={Theme.colors.greyShades.grey6}
        borderColor={Theme.colors.greyShades.grey5}
        label={
          isScreenSize ? t('showEntries', { entries: selected }) : selected
        }
        closed={closed}
        handleCloseCb={handleClose}
        endIcon={
          <Icons.CaretDownIcon
            svgColor={Theme.colors.greyShades.grey2}
            svgSize={10}
          />
        }
        dataTestId={'pagination-dropdown'}
      >
        {items.map((i) => {
          const onItemClick = () => {
            handleClick(i.value);
          };
          return (
            <DropdownItem
              key={i.label}
              onClick={onItemClick}
              className={selected === i.value ? 'selected' : ''}
            >
              <>{i.value}</>
            </DropdownItem>
          );
        })}
      </DropdownUI>
    </PaginationDropdownStyled>
  );
};
