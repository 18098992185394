import { createPaginationApiArgs } from 'services/helper/getPaginationParams.helper';
import { useGetProjectMissionsQuery } from 'services/teamHeroApi.service';
import type { IProjectMission } from 'interfaces/ProjectMission.interface';
import type { ISimpleFilter } from 'hooks/useFilters.hook';
import { TTabsView } from '../components/Tabs/Tabs';

interface IUseSearchReturn {
  missions: IProjectMission[];
  isFetching: boolean;
  totalCount: number;
}

interface IUseSearchProps {
  searchValue: string;
  view: TTabsView;
  pagination: {
    page: number;
    itemsPerPage: number;
  };
}

export const useSearch = ({
  pagination: { itemsPerPage, page },
  searchValue,
  view,
}: IUseSearchProps): IUseSearchReturn => {
  const skipCondition = searchValue.length < 3;

  const filters: ISimpleFilter[] = [
    {
      key: 'name',
      operator: 'AND',
      value: searchValue,
    },
    {
      key: 'heroStatus',
      operator: 'AND',
      value: view,
    },
  ];

  const { currentData: missions, isFetching: areMissionsFetching } =
    useGetProjectMissionsQuery(
      {
        filters,
        ...createPaginationApiArgs(
          { itemsPerPage, currentPage: page },
          { columnKey: 'start', direction: 'desc' }
        ),
      },
      {
        skip: skipCondition,
      }
    );

  return {
    totalCount: missions?.totalCount || 0,
    missions: missions?.items || [],
    isFetching: areMissionsFetching,
  };
};
