import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FilterDescriptionStyled,
  FilterRowStyled,
  TStyle,
} from 'components/FilterRow/FilterRow.styled';

interface IFilterRowProps {
  children: ReactNode;
  styleType?: TStyle;
}

const FilterRow: FC<IFilterRowProps> = ({ children, styleType = 'bright' }) => {
  const { t } = useTranslation('general');

  return (
    <FilterRowStyled data-component='filter-row'>
      <FilterDescriptionStyled $styleType={styleType}>
        {t('filters.enabledFiltersLabel')}
      </FilterDescriptionStyled>
      {children}
    </FilterRowStyled>
  );
};

export default FilterRow;
