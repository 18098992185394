import {
  EndpointBuilder,
  MutationDefinition,
  QueryDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type { IInventory } from 'interfaces/Inventory.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';
import type {
  IGetInventoryCollectionArgs,
  IGetInventoryCollectionResponse,
  IGetInventoryItemArgs,
  IInventoryDeleteArgs,
  IInventoryPostArgs,
  IInventoryPutArgs,
} from './inventories.api.types';

const BASE_URL = '/api/inventories';
const TAG = 'Inventory';

export const getInventories = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetInventoryCollectionArgs,
  BaseQueryFn,
  string,
  IGetInventoryCollectionResponse
> =>
  build.query<IGetInventoryCollectionResponse, IGetInventoryCollectionArgs>({
    providesTags: [TAG],
    query: (queryArg: IGetInventoryCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);

      return {
        url: `${BASE_URL}${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IInventory>
    ): IGetInventoryCollectionResponse =>
      transformCollectionResponse<IInventory>(response),
  });

export const getInventoryItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<IGetInventoryItemArgs, BaseQueryFn, string, IInventory> =>
  build.query<IInventory, IGetInventoryItemArgs>({
    providesTags: [TAG],
    query: (queryArg: IGetInventoryItemArgs) => ({
      url: `${BASE_URL}/${queryArg.id}`,
    }),
  });

export const postInventoryItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IInventoryPostArgs, BaseQueryFn, string, IInventory> =>
  build.mutation<IInventory, IInventoryPostArgs>({
    invalidatesTags: [TAG],
    query: (queryArg) => ({
      url: BASE_URL,
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const putInventoryItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IInventoryPutArgs, BaseQueryFn, string, IInventory> =>
  build.mutation<IInventory, IInventoryPutArgs>({
    invalidatesTags: [TAG],
    query: (queryArg) => ({
      url: `${BASE_URL}/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });

export const deleteInventoryItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IInventoryDeleteArgs, BaseQueryFn, string, unknown> =>
  build.mutation<unknown, IInventoryDeleteArgs>({
    invalidatesTags: [TAG],
    query: (queryArg) => ({
      url: `${BASE_URL}/${queryArg.id}`,
      method: 'DELETE',
    }),
  });
