import {
  BaseQueryFn,
  MutationDefinition,
  QueryDefinition,
  EndpointBuilder,
} from '@reduxjs/toolkit/query';

import type { ICollectionApiResponse } from 'services/api/api.types';
import type {
  ICampaignsCollectionResponse,
  ICampaignPostClickArgs,
  ICampaignsCollectionArgs,
  IGetCampaignItemArgs,
} from './campaigns.api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import type { ICampaign } from 'interfaces/Campaign.interface';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';

export const getCampaigns = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  ICampaignsCollectionArgs,
  BaseQueryFn,
  string,
  ICampaignsCollectionResponse
> =>
  build.query<ICampaignsCollectionResponse, ICampaignsCollectionArgs>({
    providesTags: ['Campaign'],
    query: (queryArg: ICampaignsCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);

      return {
        url: `api/campaigns${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<ICampaign>
    ): ICampaignsCollectionResponse =>
      transformCollectionResponse<ICampaign>(response),
  });

export const getCampaignItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<IGetCampaignItemArgs, BaseQueryFn, string, ICampaign> =>
  build.query<ICampaign, IGetCampaignItemArgs>({
    providesTags: ['Campaign'],
    query: (queryArg: IGetCampaignItemArgs) => ({
      url: `api/campaigns/${queryArg.id}`,
    }),
  });

export const postCampaignClick = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<ICampaignPostClickArgs, BaseQueryFn, string, ICampaign> =>
  build.mutation<ICampaign, ICampaignPostClickArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['Campaign'],
    query: (queryArg) => ({
      url: `api/campaigns/${queryArg.id}/click`,
      method: 'POST',
      body: queryArg.body,
    }),
  });
