import {
  BaseQueryFn,
  MutationDefinition,
  EndpointBuilder,
} from '@reduxjs/toolkit/query';

import type { IGalleryImage } from 'interfaces/Gallery.inteface';
import type { IPostGalleryImageFileArgs } from './galleryImageFile.api.types';

export const postGalleryImageFile = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostGalleryImageFileArgs,
  BaseQueryFn,
  string,
  IGalleryImage
> =>
  build.mutation<IGalleryImage, IPostGalleryImageFileArgs>({
    query: (queryArg) => ({
      url: `/api/gallery_images/${queryArg.id}/file`,
      method: 'POST',
      body: queryArg.body,
    }),
  });
