import {
  BaseQueryFn,
  EndpointBuilder,
  QueryDefinition,
} from '@reduxjs/toolkit/query';

import type { IMessageConversationParticipant } from 'interfaces/MessageConversationParticipant.interface';
import type {
  IGetMessageConversationParticipantsResponse,
  IGetMessageConversationParticipantsArgs,
} from './messageConversationParticipants.api.types';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';

export const getMessageConversationParticipants = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetMessageConversationParticipantsArgs,
  BaseQueryFn,
  string,
  IGetMessageConversationParticipantsResponse
> =>
  build.query<
    IGetMessageConversationParticipantsResponse,
    IGetMessageConversationParticipantsArgs
  >({
    providesTags: ['MessageConversationParticipant'],
    query: (queryArg: IGetMessageConversationParticipantsArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg?.filters);
      return {
        url: `api/message_conversation_participants${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IMessageConversationParticipant>
    ): IGetMessageConversationParticipantsResponse =>
      transformCollectionResponse<IMessageConversationParticipant>(response),
  });

export const getMessageConversationParticipantItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  number,
  BaseQueryFn,
  string,
  IMessageConversationParticipant
> =>
  build.query<IMessageConversationParticipant, number>({
    providesTags: ['MessageConversationParticipant'],
    query: (id) => {
      return {
        url: `api/message_conversation_participants/${id}`,
      };
    },
  });
