import { styled } from 'styled-components';
import { device, Theme, Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const NavigationActionCardStyled = styled.div`
  border: ${pxToRem(2)} solid ${Theme.colors.greyShades.grey10};
  border-radius: ${pxToRem(6)};
  ${flexFn('space-between', 'center', 'column')};
  aspect-ratio: 1;
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  @media ${device.xl} {
    aspect-ratio: unset;
    height: ${pxToRem(180)};
  }
`;

export const ActionCardIconStyled = styled.div`
  ${flexFn()};
  flex: 1;
`;

export const ActionCardTitleStyled = styled.p`
  font-size: clamp(${pxToRem(10)}, 2.5vw, ${pxToRem(20)});
  padding-bottom: ${pxToRem(4)};
  margin-top: ${pxToRem(-4)};
  overflow: hidden;

  @media ${device.sm} {
    padding-bottom: ${pxToRem(12)};
    margin-top: ${pxToRem(-12)};
  }
`;
