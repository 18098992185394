import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, IconButton, Icons } from 'team-hero-ui';
import moment from 'moment';

import AvatarSection from 'components/Details/AvatarSection.tsx/AvatarSection';
import {
  ApplicationSedcardSectionStyled,
  BottomSectionStyled,
  CardTitleStyled,
  ContactInfoStyled,
  FirstNameStyled,
  HeaderStyled,
  RatingButtonsStyled,
  RatingCardContentStyled,
  TopSectionStyled,
} from './ApplicationSedcardSection.styled';
import type { IApplicationSedcardSectionProps } from './ApplicationSedcardSection.types';
import Rating from 'components/Rating';
import { SedcardFieldsContent } from './SedcardFieldsContent/SedcardFieldsContent';
import RatingButtons from 'components/ClientLoginMissionDetails/Tabs/MissionApplicationsTab/components/RatingButtons';

const getAge = (birthDate: moment.Moment): number =>
  moment().diff(birthDate, 'years', false);

const ApplicationSedcardSection: FC<IApplicationSedcardSectionProps> = ({
  missionId,
  contact,
  connectedApplicationIds,
  clientStatus,
  onClose,
}) => {
  const { t } = useTranslation('general');

  return (
    <ApplicationSedcardSectionStyled>
      <TopSectionStyled>
        <HeaderStyled>
          <p>{t('clientLogin.applications.sedcard')}</p>
          <IconButton onClick={onClose}>
            <Icons.CloseIcon svgSize={30} />
          </IconButton>
        </HeaderStyled>
        <ContactInfoStyled>
          <FirstNameStyled>{contact.firstName}</FirstNameStyled>
          {getAge(moment(contact.dateOfBirth))} {t('details.yearsShort')}{' '}
          {t('modal.labels.from')} {contact.addressCity}
        </ContactInfoStyled>
        <AvatarSection
          images={contact.images || ''}
          avatar={contact.avatar}
          nationality={contact.addressCountry}
          firstName={contact.firstName}
          lastName={contact.lastName}
          onEditButtonClick={() => {}}
        />
        <RatingButtonsStyled>
          <RatingButtons
            missionId={missionId}
            applicationIds={connectedApplicationIds}
            selectedStatus={clientStatus}
          />
        </RatingButtonsStyled>
      </TopSectionStyled>
      <BottomSectionStyled>
        <Card
          title={
            <CardTitleStyled>
              {t('clientLogin.applications.sedcard')}
            </CardTitleStyled>
          }
        >
          <>
            <SedcardFieldsContent contact={contact} />
          </>
        </Card>
        <Card>
          <RatingCardContentStyled>
            <CardTitleStyled>
              {t('clientLogin.applications.rating')}
            </CardTitleStyled>
            <Rating score={contact.averageRating || 0} size={34} />
          </RatingCardContentStyled>
        </Card>
      </BottomSectionStyled>
    </ApplicationSedcardSectionStyled>
  );
};

export default ApplicationSedcardSection;
