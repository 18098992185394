import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import moment, { Moment, Duration } from 'moment';

import { TFormatDateFunction, useDateFormat } from 'hooks/useDateFormat.hook';
import { timebarFormat } from 'components/Timeline/consts/timebar.consts';
import {
  getPixelIncrement,
  isInWorkingTime,
} from 'components/Timeline/helper/timelineItemsCalculate.helper';
import {
  TResolutionType,
  TTimeOffsetType,
} from 'components/Timeline/interfaces/timelineResolutions.interface';
import {
  TimelineHeadBarItemStyled,
  TimelineHeadBarInnerStyled,
  TimelineHeadBarOuterStyled,
  TimelineHeadBarItemInnerStyled,
  TimelineHeadBarLegendStyled,
  TimelineLeftSettingsWrapperStyled,
  TimelineHeadBarItemInnerAltStyled,
  TimelineHeadbarItemInnerTextStyled,
  TimelineHeadbarItemInnerTopBarTextStyled,
} from './TimelineHeadBar.styled';
import {
  ITimelineHeadBarProps,
  ITimeBarLabelItem,
} from './TimelineHeadBar.type';

const calculateTopBarItems = (
  barResolution: TResolutionType,
  start: Moment,
  end: Moment,
  centerTopBarWidth: number,
  formatDate: TFormatDateFunction
): ITimeBarLabelItem[] => {
  const currentDate = start.clone();
  const timeIncrements: ITimeBarLabelItem[] = [];
  // left offset must be added
  let pixelsLeft = centerTopBarWidth;
  const labelSizeLimit = 60;

  const addTimeIncrement = (
    initialOffset: Duration,
    offsetType: TTimeOffsetType,
    stepFunc: (x: Moment, y: Duration) => void
  ) => {
    let offset = null;
    while (currentDate.isBefore(end) && pixelsLeft > 0) {
      // if this is the first 'block' it may be cut off at the start
      if (pixelsLeft === centerTopBarWidth) {
        offset = initialOffset;
      } else {
        offset = moment.duration(0);
      }
      const pixelIncrements = Math.min(
        getPixelIncrement(
          currentDate,
          barResolution,
          start,
          end,
          offset.as(offsetType),
          centerTopBarWidth
        ),
        pixelsLeft
      );
      const labelSize = pixelIncrements < labelSizeLimit ? 'short' : 'long';
      const additionalTopLabel =
        labelSize === 'short' && timebarFormat[barResolution].top
          ? formatDate(currentDate, timebarFormat[barResolution].top)
          : null;
      const label = formatDate(
        currentDate,
        timebarFormat[barResolution][labelSize]
      );

      timeIncrements.push({
        label,
        additionalTopLabel,
        size: pixelIncrements,
        key: pixelsLeft,
        highlight: isInWorkingTime(barResolution, currentDate),
      });
      stepFunc(currentDate, offset);
      pixelsLeft -= pixelIncrements;
    }
  };

  if (barResolution === 'year') {
    const offset = moment.duration(
      currentDate.diff(currentDate.clone().startOf('year'))
    );
    addTimeIncrement(offset, 'months', (currentDt, offst) =>
      currentDt.subtract(offst).add(1, 'year')
    );
  } else if (barResolution === 'month') {
    const offset = moment.duration(
      currentDate.diff(currentDate.clone().startOf('month'))
    );
    addTimeIncrement(offset, 'days', (currentDt, offst) =>
      currentDt.subtract(offst).add(1, 'month')
    );
  } else if (barResolution === 'day') {
    const offset = moment.duration(
      currentDate.diff(currentDate.clone().startOf('day'))
    );
    addTimeIncrement(offset, 'hours', (currentDt, offst) =>
      currentDt.subtract(offst).add(1, 'days')
    );
  } else if (barResolution === 'hour') {
    const offset = moment.duration(
      currentDate.diff(currentDate.clone().startOf('hour'))
    );
    addTimeIncrement(offset, 'minutes', (currentDt, offst) =>
      currentDt.subtract(offst).add(1, 'hours')
    );
  }

  return timeIncrements;
};

const TimelineHeadBar: FC<ITimelineHeadBarProps> = ({
  resolution,
  start,
  end,
  timelineWidth,
  leftOffset = 0,
  legendWidth = 0,
  headBarLeftRender,
}) => {
  const { formatDate } = useDateFormat();
  const { t } = useTranslation('general');

  const centerTopBarWidth = timelineWidth - (legendWidth + leftOffset);

  const topBarItems = calculateTopBarItems(
    resolution,
    start,
    end,
    centerTopBarWidth,
    formatDate
  );

  return (
    <TimelineHeadBarOuterStyled data-component={'timeline-headbar'}>
      <TimelineHeadBarInnerStyled>
        <TimelineLeftSettingsWrapperStyled
          $width={leftOffset}
          data-test-id='timeline-headbar-left-settings'
        >
          {headBarLeftRender}
        </TimelineLeftSettingsWrapperStyled>
        {topBarItems.map((item) => (
          <TimelineHeadBarItemStyled key={item.key} $width={item.size}>
            <TimelineHeadBarItemInnerStyled $isHighlighted={item.highlight}>
              {item.additionalTopLabel && (
                <TimelineHeadbarItemInnerTopBarTextStyled>
                  {item.additionalTopLabel}
                </TimelineHeadbarItemInnerTopBarTextStyled>
              )}
              <TimelineHeadbarItemInnerTextStyled>
                {item.label}
              </TimelineHeadbarItemInnerTextStyled>
            </TimelineHeadBarItemInnerStyled>
          </TimelineHeadBarItemStyled>
        ))}
        {legendWidth > 0 && (
          <TimelineHeadBarLegendStyled $width={legendWidth}>
            <TimelineHeadBarItemInnerAltStyled $isHighlighted>
              {t('workplanner.timeline.headbar.right.label')}
            </TimelineHeadBarItemInnerAltStyled>
          </TimelineHeadBarLegendStyled>
        )}
      </TimelineHeadBarInnerStyled>
    </TimelineHeadBarOuterStyled>
  );
};

export default TimelineHeadBar;
