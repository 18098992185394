import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

import { CommonCellContentStyled } from '../Common.styled';

const { pxToRem, flexFn } = Utils;

export const DateRendererContainerStyled = styled.div`
  gap: ${pxToRem(5)};
  ${flexFn('flex-start')}
`;

export const DateRendererDayOfWeekStyled = styled(CommonCellContentStyled)`
  color: ${Theme.colors.greyShades.grey3};
`;
