import { FC, useEffect } from 'react';
import { ConversationHeader, Icons, MessageDivider, Theme } from 'team-hero-ui';
import { useTranslation } from 'react-i18next';

import type { IClientLoginConversationProps } from './ClientLoginConversation.types';
import {
  useGetMessageConversationQuery,
  usePutMessageConversationReadUnreadMutation,
} from 'services/teamHeroApi.service';
import useCurrentUserContact from 'hooks/useCurrentUserContact.hook';
import { ConversationStyled } from './ClientLoginConversation.styled';
import Avatar from 'components/Avatar';
import MessageInput from 'pages/Messages/Conversation/MessageInput';
import Message from 'pages/Messages/Conversation/Message';
import MessagesSkeleton from 'pages/Messages/Conversation/MessagesSkeleton';
import InfiniteLoaderMessages from 'components/InfiniteLoader/InfiniteLoaderMessages';

const svgSize = 40;

const ClientLoginConversation: FC<IClientLoginConversationProps> = ({
  conversationId,
  isNewConversation = false,
  onClose,
  onCloseNewConversation,
}) => {
  const { currentUserContact } = useCurrentUserContact();
  const { t } = useTranslation('general');

  const { data: conversation, isError: isErrorConversation } =
    useGetMessageConversationQuery(conversationId || 0, {
      skip: isNewConversation || !conversationId,
    });

  const onCloseHeaderClick = () => {
    onClose?.();
  };

  const [putMessageConversationReadUnread] =
    usePutMessageConversationReadUnreadMutation();

  // this should only be called once after the conversation is loaded
  useEffect(() => {
    if (conversation && conversation.totalUnreadMessages > 0) {
      putMessageConversationReadUnread({
        id: conversation.id,
        body: { status: 'read' },
      });
    }
  }, [conversation, putMessageConversationReadUnread]);

  if (isErrorConversation) {
    throw new Error('Error fetching');
  }

  return (
    <ConversationStyled>
      {conversation && (
        <>
          <ConversationHeader
            leftSideElements={
              conversation.isGroupChat ? (
                <Icons.PeopleIcon
                  svgColor={Theme.colors.greyShades.grey2}
                  svgSize={svgSize}
                />
              ) : (
                <>
                  <Avatar
                    sourceType='file'
                    size={svgSize}
                    images={conversation.lastMessage?.fromContact?.avatar || ''}
                    firstName={conversation.lastMessage?.fromContact?.firstName}
                    lastName={conversation.lastMessage?.fromContact?.lastName}
                    skipLoading
                    thumbnailSize='small'
                  />
                  <p>
                    {conversation.lastMessage?.fromContact?.firstName}{' '}
                    {conversation.lastMessage?.fromContact?.lastName}
                  </p>
                </>
              )
            }
            onClose={onCloseHeaderClick}
          />
          <InfiniteLoaderMessages
            conversationId={conversationId}
            itemsPerPage={3}
            itemRenderer={(items, isLoading) =>
              isLoading ? (
                <MessagesSkeleton messagesNumber={3} />
              ) : (
                <>
                  {items.map((message, index, elements) => {
                    const previousMessage = elements[index - 1];
                    return (
                      <>
                        {message.isUnreadByMe &&
                          !previousMessage?.isUnreadByMe && (
                            <MessageDivider label={t('messages.unread')} />
                          )}
                        <Message
                          key={message.id}
                          message={message}
                          isUnreadByMe={message.isUnreadByMe}
                        />
                      </>
                    );
                  })}
                </>
              )
            }
          />
        </>
      )}
      {(conversation || isNewConversation) && (
        <MessageInput
          conversationView={
            isNewConversation ? 'new-conversation' : 'existing-conversation'
          }
          conversationIri={conversation?.['@id']}
          fromContactIri={currentUserContact?.['@id']}
          onCreateConversation={onCloseNewConversation}
        />
      )}
    </ConversationStyled>
  );
};

export default ClientLoginConversation;
