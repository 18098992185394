import { saveAs } from 'file-saver';
import { TFunction } from 'i18next';
import * as XLSX from 'xlsx';
import { useState } from 'react';

import { IColumnDefinitionType } from 'interfaces/Table/DataTableColumn.interface';

interface IDownloadDataReturn<T> {
  downloadXLS: (customColumns?: IColumnDefinitionType<T>[]) => void;
  downloadPDF: () => void;
  downloadCSV: () => void;
  isDownloading: boolean;
}

const getCell = <T>(column: IColumnDefinitionType<T>, row: T) => {
  if (column.cellType === 'custom' && column.cellExportHandler) {
    return column.cellExportHandler(row);
  }

  return row[column.key as keyof T];
};

export function setDefaultColumnWidths(worksheet: XLSX.WorkSheet) {
  let columnsConfig: XLSX.ColInfo[] = [];
  const defaultWidth = 12;

  const ref = worksheet['!ref'];

  if (ref) {
    const columnCount = XLSX.utils.decode_range(ref).e.c + 1;

    columnsConfig = Array(columnCount).fill({ width: defaultWidth });

    worksheet['!cols'] = columnsConfig;
  }
}

const getRow = async <T>(
  row: T,
  columns: IColumnDefinitionType<T>[],
  t: TFunction<'general'>
): Promise<T> => {
  const rowToDownload: T = {} as T;

  for (const column of columns) {
    if (column.displayInExport === false) continue;
    if (column.visibility || column.displayInExport) {
      const cell = await getCell(column, row);

      if (cell !== undefined && cell !== null) {
        const isCustomField = Boolean(column?.isCustomField);
        const key = isCustomField
          ? column.label
          : t(`table.header.labels.${column.key}`);

        rowToDownload[key as keyof T] = cell as T[keyof T];
      }
    }
  }

  return rowToDownload;
};

export const useDownload = <T>(
  data: T[],
  columnsDefinition: IColumnDefinitionType<T>[],
  t: TFunction<'general'>
): IDownloadDataReturn<T> => {
  const [isDownloading, setIsDownloading] = useState(false);

  const prepareData = async (columns: IColumnDefinitionType<T>[]) => {
    const dataToDownload: T[] = [];
    for (const row of data) {
      dataToDownload.push(await getRow(row, columns, t));
    }

    const ws = XLSX.utils.json_to_sheet(dataToDownload);

    setDefaultColumnWidths(ws);

    return { Sheets: { data: ws }, SheetNames: ['data'] };
  };

  const downloadXLS = async (customColumns?: IColumnDefinitionType<T>[]) => {
    setIsDownloading(true);
    const fileName = 'table_export';
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const wb = await prepareData(customColumns || columnsDefinition);

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataToSave = new Blob([excelBuffer], { type: fileType });
    saveAs(dataToSave, `${fileName}${fileExtension}`);
    setIsDownloading(false);
  };

  const downloadCSV = async () => {
    const fileName = 'log_export';
    const fileType = 'text/csv;charset=utf-8;';
    const fileExtension = '.csv';

    const wb = await prepareData(columnsDefinition);

    const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
    const dataToSave = new Blob([excelBuffer], { type: fileType });
    saveAs(dataToSave, `${fileName}${fileExtension}`);
  };

  const downloadPDF = () => {
    // eslint-disable-next-line no-console
    console.log('download PDF');
  };

  return {
    downloadXLS,
    downloadPDF,
    downloadCSV,
    isDownloading,
  };
};
