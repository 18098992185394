import { ISectionColumn } from '../modalConfig.interface';

export const emptyField: ISectionColumn = {
  items: [
    [
      {
        renderType: 'empty',
        propertyName: '',
      },
    ],
  ],
};
