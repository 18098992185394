import {
  EndpointBuilder,
  QueryDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type { IProjectMission } from 'interfaces/ProjectMission.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import type {
  IProjectMissionCollectionArgs,
  IProjectMissionCollectionResponse,
  IProjectMissionArgs,
} from 'services/projectMissions/projectMissions.api.types';

const defaultTotalCount = 0;
const defaultItems: IProjectMission[] = [];

export const getProjectMissions = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IProjectMissionCollectionArgs,
  BaseQueryFn,
  string,
  IProjectMissionCollectionResponse
> =>
  build.query<IProjectMissionCollectionResponse, IProjectMissionCollectionArgs>(
    {
      providesTags: ['ProjectMission'],
      query: (queryArg: IProjectMissionCollectionArgs) => {
        const paginationParamObject = {
          page: queryArg.page,
          itemsPerPage: queryArg.itemsPerPage,
          pagination: queryArg.pagination,
          ...queryArg.order,
        };
        const paginationQuery = serializeFlatObject(paginationParamObject, '?');
        const filterQuery = serializeFilters(queryArg.filters);
        return {
          url: `/api/project_missions${paginationQuery}${filterQuery}`,
        };
      },
      transformResponse: (
        response: ICollectionApiResponse<IProjectMission>
      ): IProjectMissionCollectionResponse => {
        const totalCount =
          (response['hydra:totalItems'] as number) || defaultTotalCount;
        const items =
          (response['hydra:member'] as IProjectMission[]) || defaultItems;
        return { items, totalCount };
      },
    }
  );

export const getProjectMissionItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IProjectMissionArgs,
  BaseQueryFn,
  string,
  IProjectMission
> => {
  return build.query<IProjectMission, IProjectMissionArgs>({
    providesTags: ['ProjectMission'],
    query: (queryArg: IProjectMissionArgs) => ({
      url: `/api/project_missions/${queryArg.id}`,
    }),
  });
};
