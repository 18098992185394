import { FC, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { ApplicationCardStyled } from './ApplicationCard.styled';
import type { IApplicationCardProps } from './ApplicationCard.types';
import ApplicationCardActive from './ApplicationCardActive';
import ApplicationCardDefault from './ApplicationCardDefault';

const ApplicationCard: FC<IApplicationCardProps> = ({
  missionId,
  allApplications,
  application,
  index,
  onAvatarClick,
}) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <Draggable draggableId={application.id.toString()} index={index}>
      {(provided) => (
        <ApplicationCardStyled
          onMouseEnter={() => setIsActive(true)}
          onMouseLeave={() => setIsActive(false)}
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          {isActive ? (
            <ApplicationCardActive
              missionId={missionId}
              allApplications={allApplications}
              application={application}
              onAvatarClick={onAvatarClick}
            />
          ) : (
            <ApplicationCardDefault application={application} />
          )}
        </ApplicationCardStyled>
      )}
    </Draggable>
  );
};

export default ApplicationCard;
