import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

import {
  TFilterPanelStyleValue,
  TFiltersDisplayType,
} from 'components/FilterPanel/FilterPanel.type';

const { pxToRem, flexFn } = Utils;

interface IFilterPanelProps {
  $styleType?: TFilterPanelStyleValue;
  $displayType?: TFiltersDisplayType;
}

export const FilterPanelStyled = styled.div<IFilterPanelProps>`
  ${flexFn('flex-start', 'center', 'column')};
  height: ${({ $displayType }) => ($displayType === 'modal' ? '100%' : 'auto')};
  ${({ $styleType }) =>
    $styleType === 'modal'
      ? `background-color: ${Theme.colors.greyShades.grey15};`
      : null}
`;

interface IFiltersWrapperStyledProps {
  $maxHeight: string;
  $displayType?: TFiltersDisplayType;
}

export const FiltersWrapperStyled = styled.div<IFiltersWrapperStyledProps>`
  margin-bottom: ${pxToRem(10)};
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: ${({ $maxHeight }) => $maxHeight};
  padding: ${({ $displayType }) =>
    $displayType === 'modal' ? pxToRem(16) : 0};
`;

export const FiltersActionsWrapperStyled = styled.div`
  ${flexFn()}
  padding: ${pxToRem([20, 0, 30])};
  width: 100%;
  gap: ${pxToRem(10)};
`;
