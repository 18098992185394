import {
  EndpointBuilder,
  MutationDefinition,
  QueryDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type { IMessageFileResource } from 'interfaces/File.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';
import type {
  IPostMessageFileEntityArgs,
  IDeleteMessageFileEntityArgs,
  IPostMessageFileArgs,
  IPutMessageFileEntityArgs,
  IGetMessageFilesResponse,
  IGetMessageFilesArgs,
  IGetMessageFileArgs,
} from './messageFiles.api.types';

export const getMessageFilesEntity = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetMessageFilesArgs,
  BaseQueryFn,
  string,
  IGetMessageFilesResponse
> =>
  build.query<IGetMessageFilesResponse, IGetMessageFilesArgs>({
    providesTags: ['MessageFile'],
    query: (queryArg) => {
      const paginationParamObject = {
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);

      return {
        url: `/api/message_files${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IMessageFileResource>
    ): IGetMessageFilesResponse =>
      transformCollectionResponse<IMessageFileResource>(response),
  });

export const getMessageFileEntity = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetMessageFileArgs,
  BaseQueryFn,
  string,
  IMessageFileResource
> =>
  build.query<IMessageFileResource, IGetMessageFileArgs>({
    providesTags: ['MessageFile'],
    query: (queryArg: IGetMessageFileArgs) => ({
      url: `/api/message_files/${queryArg.id}`,
    }),
  });

export const postMessageFileEntityItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostMessageFileEntityArgs,
  BaseQueryFn,
  string,
  IMessageFileResource
> =>
  build.mutation<IMessageFileResource, IPostMessageFileEntityArgs>({
    invalidatesTags: ['MessageFile'],
    query: (queryArg) => ({
      url: `api/message_files`,
      method: 'POST',
      body: queryArg,
    }),
  });

export const putMessageFileEntityItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPutMessageFileEntityArgs,
  BaseQueryFn,
  string,
  IMessageFileResource
> =>
  build.mutation<IMessageFileResource, IPutMessageFileEntityArgs>({
    invalidatesTags: ['MessageFile'],
    query: (queryArg) => ({
      url: `api/message_files/${queryArg.id}`,
      method: 'PUT',
      body: queryArg,
    }),
  });

export const deleteMessageFileEntityItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IDeleteMessageFileEntityArgs,
  BaseQueryFn,
  string,
  unknown
> =>
  build.mutation<unknown, IDeleteMessageFileEntityArgs>({
    invalidatesTags: ['MessageFile'],
    query: (queryArg) => ({
      url: `/api/message_files/${queryArg.id}`,
      method: 'DELETE',
    }),
  });

export const getMessageFileItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetMessageFileArgs,
  BaseQueryFn,
  string,
  IMessageFileResource
> =>
  build.query<IMessageFileResource, IGetMessageFileArgs>({
    providesTags: ['MessageFile'],
    query: (queryArg: IGetMessageFileArgs) => ({
      url: `/api/message_files/${queryArg.id}/file`,
    }),
  });

export const postMessageFileItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostMessageFileArgs,
  BaseQueryFn,
  string,
  IMessageFileResource
> =>
  build.mutation<IMessageFileResource, IPostMessageFileArgs>({
    invalidatesTags: ['MessageFile'],
    query: (queryArg) => ({
      url: `api/message_files/${queryArg.id}/file`,
      method: 'POST',
      body: queryArg.body,
    }),
  });
