import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const ApplicationCardDefaultStyled = styled.div`
  ${flexFn('flex-start', 'center')};
  gap: ${pxToRem(8)};
  background-color: ${Theme.colors.primary.white};
  padding: ${pxToRem([12, 8])};
  border-radius: ${pxToRem(4)};
  height: ${pxToRem(64)};
`;

export const ApplicationDetailsStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')};
  gap: ${pxToRem(4)};
`;

export const ApplicationNameStyled = styled.div`
  font-weight: 600;
  font-size: ${pxToRem(14)};
`;

export const ApplicationPositionStyled = styled.div`
  font-size: ${pxToRem(13)};
`;
