import { styled, css } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

export const TABLE_SVG_SIZE = 15;

export interface ICommonCellContentProps {
  $color?: string;
  $bold?: boolean;
  $bgColor?: string;
  $paddingLeft?: number;
  $fontSize?: number;
}

export const transparencyHex = '0d';

export const CommonCellContentStyled = styled.div<ICommonCellContentProps>`
  font-size: ${({ $fontSize }) =>
    $fontSize ? pxToRem($fontSize) : pxToRem(14)};
  color: ${({ $color }) => $color};
  font-weight: ${({ $bold }) => ($bold ? 600 : 400)};
  padding-left: ${({ $paddingLeft }) => $paddingLeft && pxToRem($paddingLeft)};
`;

export const HighlightCellStyled = styled(CommonCellContentStyled)`
  background-color: ${Theme.colors.greyShades.grey3 + transparencyHex};
`;

export const CellContainerStyled = styled.div`
  ${flexFn()}
`;

export const FlexCellRendererStyled = styled(CommonCellContentStyled)`
  ${flexFn('flex-start')};
  gap: ${pxToRem(5)};
  width: 100%;
  height: 100%;
`;

export const ContainerRowStyled = styled.div`
  ${flexFn('flex-start')};
  width: 100%;
  height: 100%;
`;

interface IIconWrapperStyled {
  $hoverStrokeColor?: string;
  $hoverFillColor?: string;
  $marginTop?: boolean;
}

const IconMarginTop = css`
  svg {
    margin-top: ${pxToRem(4)};
  }
`;

export const IconWrapperStyled = styled.div<IIconWrapperStyled>`
  ${flexFn('flex-start')};
  cursor: pointer;

  &:hover .cls-2 {
    stroke: ${({ $hoverStrokeColor }) => $hoverStrokeColor || null};
    fill: ${({ $hoverFillColor }) => $hoverFillColor || null};
  }

  ${({ $marginTop }) => $marginTop && IconMarginTop}
`;

export const StatusIconWrapperStyled = styled.div`
  padding: ${pxToRem([5, 7])};
  border-radius: 50%;
  background-color: ${Theme.colors.others.green.green8};
`;
