import { FC } from 'react';

import {
  HeaderSectionAdditionalRowStyled,
  HeaderSectionStyled,
  HeaderWrapperStyled,
} from './ToolbarHeader.styled';
import { IToolbarHeaderProps } from './ToolbarHeader.type';

const ToolbarHeader: FC<IToolbarHeaderProps> = ({
  leftSection,
  rightSection,
  centerSection,
  secondRow,
}) => {
  return (
    <HeaderWrapperStyled data-component='toolbar-header'>
      {leftSection && <HeaderSectionStyled>{leftSection}</HeaderSectionStyled>}
      {centerSection && (
        <HeaderSectionStyled className='table-header-section'>
          {centerSection}
        </HeaderSectionStyled>
      )}
      {rightSection && (
        <HeaderSectionStyled>{rightSection}</HeaderSectionStyled>
      )}
      {secondRow && (
        <HeaderSectionAdditionalRowStyled>
          {secondRow}
        </HeaderSectionAdditionalRowStyled>
      )}
    </HeaderWrapperStyled>
  );
};

export default ToolbarHeader;
