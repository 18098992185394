import { FC } from 'react';
import { Avatar, TAvatarSize } from 'team-hero-ui';

import { WrapperStyled } from './ParticipantMinCellRenderer.styled';

interface IParticipantMinCellRendererSkeletonProps {
  avatarWidth?: TAvatarSize | number;
}

const ParticipantMinCellRendererSkeleton: FC<
  IParticipantMinCellRendererSkeletonProps
> = ({ avatarWidth = 40 }) => {
  return (
    <WrapperStyled>
      <Avatar size={avatarWidth} showLoadingSkeleton />
    </WrapperStyled>
  );
};

export default ParticipantMinCellRendererSkeleton;
