import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

interface ILegendWrapperStyledProps {
  $width: number;
  $leftOffset?: number;
}

export const LegendWrapperStyled = styled.div<ILegendWrapperStyledProps>`
  width: ${({ $width }) => ($width ? pxToRem($width) : pxToRem(0))};
  background: transparent;
  ${flexFn('center', 'stretch')}
  padding: ${({ $leftOffset }) =>
    $leftOffset ? pxToRem([3, 1.5, 3, $leftOffset]) : pxToRem([3, 1.5, 3, 20])};
`;

export const LegendInnerStyled = styled.div`
  background: ${Theme.colors.primary.white};
  padding: ${pxToRem(10)};
  flex: 1;
  max-width: 100%;
  color: ${Theme.colors.primary.grey};
  border-radius: ${pxToRem([2, 0, 0, 2])};
  ${flexFn('space-between', 'start', 'column')}
`;

export const LegendInnerTopStyled = styled.div`
  width: 100%;
  ${flexFn('space-between', 'center', 'row')}
`;

export const LabelWrapperStyled = styled.div`
  width: fit-content;
  min-width: 0;
  position: relative;
  padding-right: ${pxToRem(8)};
`;

export const LabelStyled = styled.div`
  white-space: nowrap;
  font-size: ${pxToRem(13)};
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  color: ${Theme.colors.primary.grey};
`;

export const LegendInnerBottomWrapperStyled = styled.div`
  width: 100%;
  flex: 1;
  padding-top: ${pxToRem(8)};
  ${flexFn('flex-start', 'end', 'row')}
`;
