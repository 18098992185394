import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

export const ApplicationPoolStyled = styled.div`
  ${flexFn('flex-start', 'stretch')};
  flex: 1;
  background-color: ${Theme.colors.greyShades.grey5};
  padding: ${pxToRem(24)};
  margin-left: ${pxToRem(12)};
`;

export const ApplicationsStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')};
  gap: ${pxToRem(4)};
  margin-bottom: ${pxToRem(24)};
  flex: 1 1 0;
  overflow: auto;
  width: 100%;
`;

export const ApplicationAreasStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')};
  gap: ${pxToRem(24)};
  height: 100%;
  flex: 1 1 0;
`;

export const PoolToCheckStyled = styled.p`
  color: ${Theme.colors.primary.red};
  font-size: ${pxToRem(14)};
  font-weight: 700;
  margin-bottom: ${pxToRem(24)};
  text-transform: uppercase;
`;
