import {
  BaseQueryFn,
  EndpointBuilder,
  QueryDefinition,
} from '@reduxjs/toolkit/query';

import type { IDocumentTemplate } from 'interfaces/DocumentTemplate.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';
import type {
  IGetDocumentTemplateCollectionArgs,
  IGetDocumentTemplateCollectionResponse,
  IGetDocumentTemplateItemArgs,
} from './documentTemplates.api.type';

export const getDocumentTemplates = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetDocumentTemplateCollectionArgs,
  BaseQueryFn,
  string,
  IGetDocumentTemplateCollectionResponse
> =>
  build.query<
    IGetDocumentTemplateCollectionResponse,
    IGetDocumentTemplateCollectionArgs
  >({
    providesTags: ['DocumentTemplate'],
    query: (queryArg: IGetDocumentTemplateCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);
      return {
        url: `api/document_templates${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IDocumentTemplate>
    ): IGetDocumentTemplateCollectionResponse =>
      transformCollectionResponse<IDocumentTemplate>(response),
  });

export const getDocumentTemplateItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetDocumentTemplateItemArgs,
  BaseQueryFn,
  string,
  IDocumentTemplate
> =>
  build.query<IDocumentTemplate, IGetDocumentTemplateItemArgs>({
    providesTags: ['DocumentTemplate'],
    query: (queryArg: IGetDocumentTemplateItemArgs) => ({
      url: `api/document_templates/${queryArg.id}`,
    }),
  });
