import { ReactElement, useEffect, useState } from 'react';

import ItemsPerPageDropdownItems from 'components/PaginationFooter/helper/defaultItemsPerPageDropdownItems';
import PaginationFooter from 'components/PaginationFooter';
import calculatePagesCount from 'components/PaginationFooter/helper/calculatePagesCount.helper';

interface IUseSimplePaginationReturn {
  page: number;
  itemsPerPage: number;
  getPaginationFooter: (
    totalCount: number,
    withPagesDropdown?: boolean,
    size?: 'small' | 'medium' | 'large'
  ) => ReactElement;
  resetPagination: () => void;
}

const initialItemsPerPage = ItemsPerPageDropdownItems[0].value;
const initialPage = 1;

export const useSimplePagination = (
  searchValue: string
): IUseSimplePaginationReturn => {
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage);
  const [page, setPage] = useState(initialPage);

  const resetPagination = () => {
    setPage(initialPage);
  };

  const getPaginationFooter = (
    totalCount: number,
    withPagesDropdown = true,
    size: 'small' | 'medium' | 'large' = 'medium'
  ) => (
    <PaginationFooter
      dropdownProps={
        withPagesDropdown
          ? {
              items: ItemsPerPageDropdownItems,
              onChange: (value: number) => setItemsPerPage(value),
              selected: itemsPerPage,
            }
          : undefined
      }
      paginationProps={{
        count: calculatePagesCount(itemsPerPage, totalCount),
        page,
        size,
        onChange: (_e, nextPage) => setPage(nextPage),
      }}
    />
  );

  useEffect(() => {
    resetPagination();
  }, [searchValue]);

  return {
    page,
    itemsPerPage,
    getPaginationFooter,
    resetPagination,
  };
};
