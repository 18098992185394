import { useTranslation } from 'react-i18next';
import { IPreferredFieldsOption, PreferredFields } from 'team-hero-ui';
import { FC } from 'react';

import { ISedcardFieldsContentProps } from './SedcardFieldsContent.types';
import { useGetCustomFields } from 'hooks/useGetCustomFields.hook';
import type { IContact } from 'interfaces/Contact.interface';

export const SedcardFieldsContent: FC<ISedcardFieldsContentProps> = ({
  contact,
}) => {
  const { t } = useTranslation();
  const { customFields, getFieldName, getDisplayValue } = useGetCustomFields();

  const getPreferredFields = (): IPreferredFieldsOption[] => {
    const options: IPreferredFieldsOption[] = [];

    customFields.forEach((customField) => {
      const preferredFieldValue = contact[
        customField.key as keyof IContact
      ] as unknown as string | number | boolean;

      if (['ie', 'e'].includes(customField.includeInSedcard)) {
        options.push({
          text:
            getFieldName(customField.key) ||
            t(`modal.labels.${customField.key}`),
          value: getDisplayValue(customField.key, preferredFieldValue),
        });
      }
    });

    return options;
  };

  return (
    <PreferredFields
      preferredFieldsOptions={getPreferredFields()}
      padding={0}
    />
  );
};
