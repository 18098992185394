import moment, { Moment } from 'moment';

export const isStartOfDay = (day: Moment | string) =>
  moment(day).hour() === 0 && moment(day).minute() === 0;

export const isEndOfDay = (day: Moment | string) =>
  moment(day).hour() === 23 && moment(day).minute() === 59;

const setTime = (
  day: Moment,
  hour: number | string,
  minute: number | string
) => {
  return day.clone().set({
    h: Number(hour),
    m: Number(minute),
  });
};

export const startOfDay = (day: Moment) => setTime(day, 0, 0);

export const endOfDay = (day: Moment) => setTime(day, 23, 59);

export const checkSpansFullDay = (start: Moment, end: Moment): boolean => {
  // check if the shift spans a whole day like 00:00 - 23:59
  // Check if the start and end moments are on the same day
  if (!start.isSame(end, 'day')) {
    return false;
  }

  // Check if the start moment is at 00:00 and the end moment is at 23:59
  return isStartOfDay(start) && isEndOfDay(end);
};
