import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SwitchDescriptiveNullable } from 'team-hero-ui';

import { IFilterRendererProps } from 'components/FilterPanel/FilterPanel.type';
import { FilterLeftElementStyled } from 'components/FilterPanel/filterRenderers/FilterElement.styled';

export const SwitchNullableRenderer: FC<IFilterRendererProps> = ({
  label,
  onChange,
  value = null,
  styleType,
}) => {
  const { t } = useTranslation();
  return (
    <FilterLeftElementStyled>
      <SwitchDescriptiveNullable
        styleType={styleType === 'modal' ? 'modal' : 'bright'}
        label={label}
        value={value}
        size='medium'
        onChange={onChange}
        nullValueText={t('switch.nullValueText')}
      />
    </FilterLeftElementStyled>
  );
};
