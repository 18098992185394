import { useCallback, useEffect } from 'react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { TRootState } from 'store';
import { ITableSelector } from 'interfaces/Table/selector.interface';
import { TSortDirection } from 'interfaces/Pagination.interface';

export interface ISorting {
  columnKey: string | null;
  direction: TSortDirection;
}

export interface IUseSortReturn extends ISorting {
  onSetSorting: (columnKey: string, direction: TSortDirection) => void;
}

type TDataTableSortingReduxSlice = ActionCreatorWithPayload<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  `${string}/${string}`
>;

const emptySorting: ISorting = {
  columnKey: null,
  direction: 'asc',
};

export const useSort = (
  setSortingDispatch: TDataTableSortingReduxSlice,
  paginationSelector: (state: TRootState) => ITableSelector,
  initialSortField?: string,
  initialSortingDirection?: TSortDirection
): IUseSortReturn => {
  const dispatch = useAppDispatch();
  const { defaultSorting, sorting } = useAppSelector(paginationSelector);

  const onSetSorting = useCallback(
    (columnKey: string, sortingDirection: TSortDirection) => {
      const resetSorting: boolean =
        sortingDirection === sorting.direction &&
        columnKey === sorting.columnKey;

      let payload: ISorting = { columnKey, direction: sortingDirection };

      if (resetSorting) {
        if (defaultSorting) {
          payload = defaultSorting;
        } else {
          payload = emptySorting;
        }
      }

      dispatch(setSortingDispatch(payload));
    },
    [
      defaultSorting,
      dispatch,
      setSortingDispatch,
      sorting.columnKey,
      sorting.direction,
    ]
  );

  useEffect(() => {
    if (initialSortField && initialSortingDirection && !sorting.columnKey) {
      const payload = {
        columnKey: initialSortField,
        direction: initialSortingDirection,
      };
      dispatch(setSortingDispatch(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    columnKey: sorting.columnKey,
    direction: sorting.direction,
    onSetSorting,
  };
};
