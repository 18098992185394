import { styled, css } from 'styled-components';
import { device, Theme, Utils } from 'team-hero-ui';

import { NavigationActionCardStyled } from 'components/NavigationActionCard/NavigationActionCard.styled';

const { flexFn, pxToRem } = Utils;

interface ISearchActionCardsStyledProps {
  $isFiltered: boolean;
  $isSmScreenSize: boolean;
}

export const SearchActionCardsStyled = styled.div<ISearchActionCardsStyledProps>`
  width: 100%;
  grid-gap: ${pxToRem(4)};
  ${({ $isFiltered, $isSmScreenSize }) =>
    $isFiltered && !$isSmScreenSize
      ? css`
          ${flexFn('flex-start')};
          flex-wrap: nowrap;
          overflow: auto;
          gap: ${pxToRem(8)};
          min-height: ${pxToRem(80)};
          ${NavigationActionCardStyled} {
            min-width: ${pxToRem(70)};
            flex: 0;
          }
        `
      : css`
          display: grid;
          grid-template-columns: repeat(4, 1fr);
        `};

  @media ${device.sm} {
    overflow: unset;
    grid-gap: ${pxToRem(24)};
  }

  svg {
    font-size: clamp(${pxToRem(34)}, 8vw, ${pxToRem(60)}) !important;
    stroke-width: ${pxToRem(0.7)};
    @media ${device.sm} {
      stroke-width: ${pxToRem(0.5)};
    }
    g > path {
      stroke: currentColor;
    }
  }

  .MuiAvatar-root {
    font-size: clamp(${pxToRem(12)}, 2.5vw, ${pxToRem(30)}) !important;
    width: clamp(${pxToRem(34)}, 6vw, ${pxToRem(80)}) !important;
    height: clamp(${pxToRem(34)}, 6vw, ${pxToRem(80)}) !important;
  }
`;

export const HelpLinkStyled = styled.a`
  color: ${Theme.colors.primary.white};
`;

export const SearchActionCardsWrapperStyled = styled.div`
  ${flexFn()};
  min-height: ${pxToRem(80)};
  width: 100%;
  overflow: auto;

  @media ${device.sm} {
    min-height: unset;
    overflow: unset;
  }
`;
