import { useEffect, useState } from 'react';

export const useSearchDebounce = (searchQuery: string, delay = 350): string => {
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    const delayFn = setTimeout(() => setSearch(searchQuery), delay);
    return () => clearTimeout(delayFn);
  }, [searchQuery, delay]);

  return search;
};
