import {
  EndpointBuilder,
  QueryDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type { ICompany } from 'interfaces/Company.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';
import type {
  ICompanyCollectionArgs,
  ICompanyCollectionResponse,
  ICompanyItemArgs,
} from './companies.types';

export const getCompanies = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  ICompanyCollectionArgs,
  BaseQueryFn,
  string,
  ICompanyCollectionResponse
> =>
  build.query<ICompanyCollectionResponse, ICompanyCollectionArgs>({
    providesTags: ['Company'],
    query: (queryArg: ICompanyCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);
      return {
        url: `api/companies${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<ICompany>
    ): ICompanyCollectionResponse =>
      transformCollectionResponse<ICompany>(response),
  });

export const getCompanyItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<ICompanyItemArgs, BaseQueryFn, string, ICompany> =>
  build.query<ICompany, ICompanyItemArgs>({
    providesTags: ['Company'],
    query: (queryArg: ICompanyItemArgs) => ({
      url: `api/companies/${queryArg.id}`,
    }),
  });
