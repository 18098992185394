import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { SearchPopup } from 'components/SearchPopup/SearchPopup';
import {
  openSearch,
  searchSelector,
  closeSearch,
  resetHasBeenOpened,
} from 'redux/search/search.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface IUseSearchPopupReturn {
  searchPopup: JSX.Element | null;
  open: (initialValue?: string) => void;
  close: () => void;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
}

export const useSearchPopup = (): IUseSearchPopupReturn => {
  const [searchValue, setSearchValue] = useState('');

  const { isOpen: isModalOpen, hasBeenOpened } = useAppSelector(searchSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setSearchValue('');
  }, [isModalOpen]);

  const close = useCallback(() => {
    dispatch(closeSearch());
    setTimeout(() => dispatch(resetHasBeenOpened()), 350);
  }, [dispatch]);

  const open = useCallback(() => {
    dispatch(
      openSearch({
        isOpen: true,
        hasBeenOpened: true,
        initialSearch: searchValue,
      })
    );
  }, [dispatch, searchValue]);

  return {
    searchPopup: (
      <SearchPopup
        onClose={close}
        displayCloseAnimation={!isModalOpen && hasBeenOpened}
        displayOpenAnimation={isModalOpen}
      />
    ),
    open,
    close,
    searchValue,
    setSearchValue,
  };
};
