import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

interface ILegendWrapperStyledProps {
  $width: number;
}

export const LegendWrapperStyled = styled.div<ILegendWrapperStyledProps>`
  width: ${({ $width }) => ($width ? pxToRem($width) : pxToRem(0))};
  background: transparent;
  ${flexFn('center', 'stretch')}
  padding: ${pxToRem([3, 20, 3, 1.5])};
`;

export const InnerStyled = styled.div`
  background: ${Theme.colors.primary.white};
  padding: ${pxToRem([10])};
  flex: 1;
  color: ${Theme.colors.primary.grey};
  border-radius: ${pxToRem([0, 2, 2, 0])};
  ${flexFn('space-between', 'end', 'column')}
  gap: ${pxToRem(5)};
`;

export const InnerColumnStyled = styled.div`
  line-height: 1;
`;

export const TextStyled = styled.span`
  font-size: ${pxToRem(14)};
  color: ${Theme.colors.primary.grey};
  font-weight: 700;
`;
