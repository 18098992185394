import { orderBy } from 'lodash';

import type { IFileEntry } from 'interfaces/File.interface';
import type { TThumbnailSize } from 'helpers/file/file.helpers.types';
import { store } from 'store';
import { teamHeroApi } from 'services/teamHeroApi.service';
import createFileUrl from 'helpers/file/getFile.helper';
import { isFulfilled } from 'helpers/promise/promise.helpers';
import { getIriData } from 'helpers/iri/getIriData.helper';
export type TSourceValue = 'resource' | 'file';

export const getImageDataFromIri = async (
  imageIri: string
): Promise<IFileEntry | undefined> => {
  const { entity, id } = getIriData(imageIri);
  let getImageEndpoint;

  switch (entity) {
    case 'contact_images':
      getImageEndpoint = store.dispatch(
        teamHeroApi.endpoints.getContactImageEntity.initiate({
          id,
        })
      );
      break;
    case 'application_images':
      getImageEndpoint = store.dispatch(
        teamHeroApi.endpoints.getApplicationImageEntity.initiate({
          id,
        })
      );
      break;
    case 'inventory_images':
      getImageEndpoint = store.dispatch(
        teamHeroApi.endpoints.getInventoryImageEntity.initiate({
          id,
        })
      );
      break;
    default:
      return undefined;
  }

  const { data } = await getImageEndpoint;
  getImageEndpoint.unsubscribe();

  return data;
};

const getResourceImageUrls = async (
  imageEntityResponse: IFileEntry[],
  thumbnailSize?: TThumbnailSize
): Promise<string[]> => {
  const imagesPromises = Promise.allSettled(
    imageEntityResponse.map(async (item) => {
      if (item.file) {
        return createFileUrl({
          apiRequestUrl: item.file.slice(1),
          thumbnailSize,
        }).then((fileUrl) => fileUrl);
      }
      return Promise.resolve('');
    })
  );

  return (await imagesPromises)
    .filter(isFulfilled)
    .map((successPromiseResult) => successPromiseResult.value);
};

const isFileEntry = (item: IFileEntry | undefined): item is IFileEntry => {
  return !!item;
};

const getUrlsByResourceCollection = (
  images: string[],
  thumbnailSize?: TThumbnailSize
): Promise<string[]> => {
  return Promise.all(
    images.map(async (imageIri) => {
      return getImageDataFromIri(imageIri);
    })
  ).then((resourceImageResult) => {
    const results = resourceImageResult.filter(isFileEntry);

    if (results && results.length > 0) {
      const orderedImageResource = orderBy(results, ['displayOrder'], ['asc']);

      return getResourceImageUrls(orderedImageResource, thumbnailSize);
    } else {
      return [];
    }
  });
};

const getUrlsByResource = (
  image: string,
  thumbnailSize?: TThumbnailSize
): Promise<string> => {
  return getUrlsByResourceCollection([image], thumbnailSize).then((result) => {
    if (result.length > 0) {
      return result[0];
    }

    return '';
  });
};

const getUrlByFile = (
  images: string,
  thumbnailSize?: TThumbnailSize
): Promise<string> => {
  return createFileUrl({
    apiRequestUrl: images.replace('/', ''),
    thumbnailSize,
  }).then((result) => {
    return Promise.resolve(result);
  });
};

const getUrlsByFileCollection = (
  images: string[],
  thumbnailSize?: TThumbnailSize
): Promise<string[]> => {
  return Promise.all(
    images.map((image) => {
      return createFileUrl({
        apiRequestUrl: image.replace('/', ''),
        thumbnailSize,
      });
    })
  ).then((result) => {
    return Promise.resolve(result);
  });
};

export const getImageUrls = (
  images?: string | string[],
  imageType?: TSourceValue,
  thumbnailSize?: TThumbnailSize
): Promise<string | string[]> => {
  const isArray = Array.isArray(images) && images.length;
  const isString = typeof images === 'string';

  if (!images || (Array.isArray(images) && images.length === 0)) {
    return Promise.resolve('');
  }
  if (imageType === 'resource' && isArray) {
    return getUrlsByResourceCollection(images, thumbnailSize);
  }
  if (imageType === 'resource' && isString) {
    return getUrlsByResource(images, thumbnailSize);
  }
  if (imageType === 'file' && isArray) {
    return getUrlsByFileCollection(images, thumbnailSize);
  }
  if (imageType === 'file' && isString) {
    return getUrlByFile(images, thumbnailSize);
  }

  return Promise.resolve('');
};
