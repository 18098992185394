import { IDropdownItem } from '../components/Dropdown';

const defaultItemsPerPageDropdownItems: IDropdownItem<number>[] = [
  {
    label: '20',
    value: 20,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
  {
    label: '200',
    value: 200,
  },
];

export default defaultItemsPerPageDropdownItems;
