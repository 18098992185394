import { styled } from 'styled-components';
import { theme, Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

export const TimelineBackdropWrapperStyled = styled.div`
  height: 100%;
  ${flexFn()}
`;

interface ICurrentDateLineStyledProps {
  $left: number;
}

export const CurrentDateLineStyled = styled.span<ICurrentDateLineStyledProps>`
  position: absolute;
  top: 0;
  left: ${({ $left }) => pxToRem($left)};
  width: ${pxToRem(1)};
  margin: ${pxToRem([2, 0])};
  background: ${theme.colors.primary.red};
  background: repeating-linear-gradient(
    0deg,
    transparent,
    transparent ${pxToRem(3)},
    ${theme.colors.primary.red} ${pxToRem(3)},
    ${theme.colors.primary.red} ${pxToRem(10)}
  );
  height: 100%;
`;
