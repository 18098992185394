import type { TRootState } from 'store';
import type { ISelector } from 'interfaces/Selector.interface';
import { createSelectorSlice } from 'redux/collections/helper/createSelector.slice';
import {
  currentPageDefault,
  itemsPerPageDefault,
} from 'redux/collections/consts/selectorDefaults.consts';

export const payslipsSlice = createSelectorSlice(
  'paginatedPayslips',
  currentPageDefault,
  itemsPerPageDefault,
  { columnKey: 'createdAt', direction: 'desc' }
);

const { actions, reducer } = payslipsSlice;

export const {
  setPagination,
  setNextPage,
  setSorting,
  setItemsPerPage,
  setFilters,
} = actions;

export const paginatedPayslipsSelector = (state: TRootState): ISelector =>
  state.collectionPayslips;

export default reducer;
