import { TFunction } from 'i18next';
import Joi, { Schema } from 'joi';
import moment, { Moment } from 'moment';

export const isValidWithdrawalDate = (
  withdrawalDate?: Moment | string,
  entryDate?: Moment | string
): boolean => {
  return !entryDate || moment(withdrawalDate).isAfter(entryDate);
};

const getWithdrawalDateValidation =
  (isFieldRequired: boolean) =>
  (value: Moment, helpers: Joi.CustomHelpers<unknown>) => {
    if (!value) {
      return isFieldRequired ? helpers.error('any.required') : value;
    }

    const {
      state: {
        ancestors: [{ entryDate }],
      },
    } = helpers;

    return isValidWithdrawalDate(value, entryDate)
      ? value
      : helpers.error('any.invalid');
  };

export const withdrawalDateValidationStrategy = (
  isFieldRequired: boolean,
  t: TFunction
): Schema =>
  Joi.custom(getWithdrawalDateValidation(isFieldRequired)).messages({
    'any.required': t('errors:validation.fieldRequired'),
    'any.invalid': t('errors:validation.withdrawalDate'),
  });
