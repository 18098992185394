import {
  BaseQueryFn,
  MutationDefinition,
  EndpointBuilder,
  QueryDefinition,
} from '@reduxjs/toolkit/query';

import type { IRating } from 'interfaces/Rating.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import type {
  IPostRatingsArgs,
  IPutRatingArgs,
  IRatingsCollectionArgs,
  IRatingsCollectionResponse,
} from './Rating.api.types';
import { serializeFilters } from 'services/helper/getPaginationParams.helper';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';

export const getRatings = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IRatingsCollectionArgs,
  BaseQueryFn,
  string,
  IRatingsCollectionResponse
> =>
  build.query<IRatingsCollectionResponse, IRatingsCollectionArgs>({
    providesTags: ['Rating'],
    query: (queryArg: IRatingsCollectionArgs) => {
      const filterQuery = serializeFilters(queryArg.filters);

      return {
        url: `/api/ratings?${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IRating>
    ): IRatingsCollectionResponse =>
      transformCollectionResponse<IRating>(response),
  });

export const postRatings = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IPostRatingsArgs, BaseQueryFn, string, IRating> =>
  build.mutation({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['Rating'],
    query: (queryArg: IPostRatingsArgs) => ({
      url: '/api/ratings',
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const putRating = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IPutRatingArgs, BaseQueryFn, string, IRating> =>
  build.mutation<IRating, IPutRatingArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['Rating'],
    query: (queryArg) => ({
      url: `/api/ratings/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });
