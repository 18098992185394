import { styled, css } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

import {
  TFilterPanelStyleValue,
  TFiltersDisplayType,
} from './FilterPanel.type';

const { pxToRem, flexFn } = Utils;

interface IFilterPanelProps {
  $styleType?: TFilterPanelStyleValue;
  $displayType?: TFiltersDisplayType;
}

interface IFilterPanelStyledProps extends IFilterPanelProps {
  $hidden?: boolean;
}

const getFilterWrapperStylesByType = (
  styleType?: TFilterPanelStyleValue,
  hidden?: boolean
) => {
  switch (styleType) {
    case 'modal':
      return css`
        width: 100%;
      `;

    case 'default':
    default:
      return css`
        width: ${hidden ? 0 : pxToRem(380)};
        max-width: 100%;
        height: 100%;
        opacity: ${hidden ? 0 : 1};
        overflow: ${hidden ? 'hidden' : 'auto'};
        transition:
          width 0.3s ease,
          opacity 0.2s ease-in-out;
        flex-shrink: 0;
        align-self: start;
      `;
  }
};

export const FilterPanelVisibilityWrapper = styled.div<IFilterPanelStyledProps>`
  padding: ${({ $hidden }) =>
    $hidden ? pxToRem([20, 0]) : pxToRem([20, 20, 20, 20])};
  transition:
    padding-left 0.15s ease-out,
    padding-right 0.15s ease-out;
  ${({ $styleType, $hidden }) =>
    getFilterWrapperStylesByType($styleType, $hidden)};
`;

export const FilterPanelLabelStyled = styled.div`
  ${flexFn('flex-start')};
  gap: ${pxToRem(20)};
  margin-bottom: ${pxToRem(30)};
  width: 100%;
`;

export const FilterLabelStyled = styled.h2`
  text-transform: uppercase;
  color: ${Theme.colors.greyShades.grey3};
`;
