import { styled, css } from 'styled-components';
import { theme, Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

interface ITimelineBackdropItemStyled {
  $width: number;
}

interface ITimelineBackdropItemInnerStyledProps {
  $isStriped?: boolean;
}

export const TimelineBackdropItemStyled = styled.span<ITimelineBackdropItemStyled>`
  text-align: center;
  ${flexFn()}
  white-space: nowrap;
  padding: ${pxToRem([3, 2])};
  height: 100%;
  overflow: hidden;
  width: ${({ $width }) => ($width ? pxToRem($width) : pxToRem(0))};
`;

const getBgValue = (isStriped?: boolean) => {
  if (isStriped) {
    return css`
      background: repeating-linear-gradient(
        135deg,
        ${theme.colors.primary.white},
        ${theme.colors.primary.white} ${pxToRem(3)},
        ${theme.colors.greyShades.grey6} ${pxToRem(3)},
        ${theme.colors.greyShades.grey6} ${pxToRem(6)}
      );
    `;
  }
  return css`
    background: ${theme.colors.primary.white};
  `;
};

export const HoverClickOverlay = styled.span`
  height: 100%;
  width: 100%;
  ${flexFn('center', 'center')};
  display: none;
`;

export const TimelineBackdropItemInnerStyled = styled.span<ITimelineBackdropItemInnerStyledProps>`
  flex: 1;
  ${flexFn('center', 'center')}
  height: 100%;
  ${({ $isStriped }) => getBgValue($isStriped)};
  align-self: center;
  border-radius: 0;
  font-size: ${pxToRem(11)};
  overflow: hidden;

  &:hover ${HoverClickOverlay} {
    display: flex;
  }
`;
