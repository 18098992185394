import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

interface IConversationPanelStyledProps {
  $height?: string;
}

export const ConversationPanelStyled = styled.div<IConversationPanelStyledProps>`
  ${flexFn('flex-start', 'stretch', 'column')};
  height: ${({ $height }) => $height || '100%'};
`;

export const ConversationListStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')};
  height: 100%;
  box-shadow: ${pxToRem(-3)} 0 ${pxToRem(10)} ${pxToRem(3)}
    ${Theme.colors.greyShades.grey4}80;
`;

export const KeyAccountStyled = styled.div`
  ${flexFn('flex-start', 'stretch')};
  gap: ${pxToRem(24)};

  p:first-child {
    color: ${Theme.colors.primary.grey};
    font-weight: bold;
  }

  p:last-child {
    color: ${Theme.colors.greyShades.grey2};
  }
`;

export const PhoneNumberStyled = styled.div`
  ${flexFn('flex-start', 'stretch')};
  gap: ${pxToRem(12)};
  color: ${Theme.colors.primary.grey};
`;

export const NewConversationActionCardStyled = styled.div`
  padding: ${pxToRem([12, 24])};
`;
