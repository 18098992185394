import {
  BaseQueryFn,
  EndpointBuilder,
  QueryDefinition,
  MutationDefinition,
} from '@reduxjs/toolkit/query';

import type { IUserNotification } from 'interfaces/UserNotification.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import type {
  IGetUserNotificationItemArgs,
  IGetUserNotificationsResponse,
  IUserNotificationCollectionArgs,
  IUserNotificationPutArgs,
} from 'services/userNotifications/userNotifications.types';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';

export const getUserNotifications = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IUserNotificationCollectionArgs,
  BaseQueryFn,
  string,
  IGetUserNotificationsResponse
> =>
  build.query<IGetUserNotificationsResponse, IUserNotificationCollectionArgs>({
    providesTags: ['UserNotification'],
    query: (queryArg: IUserNotificationCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);

      return {
        url: `api/user_notifications${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IUserNotification>
    ): IGetUserNotificationsResponse =>
      transformCollectionResponse<IUserNotification>(response),
  });

export const getUserNotificationItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetUserNotificationItemArgs,
  BaseQueryFn,
  string,
  IUserNotification
> =>
  build.query<IUserNotification, IGetUserNotificationItemArgs>({
    providesTags: ['UserNotification'],
    query: (queryArg: IGetUserNotificationItemArgs) => ({
      url: `api/user_notifications/${queryArg.id}`,
    }),
  });

export const putUserNotificationItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IUserNotificationPutArgs,
  BaseQueryFn,
  string,
  IUserNotification
> =>
  build.mutation<IUserNotification, IUserNotificationPutArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['UserNotification'],
    query: (queryArg) => ({
      url: `api/user_notifications/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });

export const postUserNotificationsMarkAllAsRead = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<void, BaseQueryFn, string, IUserNotification> =>
  build.mutation<IUserNotification, void>({
    invalidatesTags: ['UserNotification'],
    query: () => ({
      url: 'api/user_notifications/set_all_read',
      method: 'POST',
    }),
  });

export const postUserNotificationsUnsetAllNew = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<null, BaseQueryFn, string, IUserNotification> =>
  build.mutation<IUserNotification, null>({
    invalidatesTags: ['UserNotification'],
    query: () => ({
      url: 'api/user_notifications/unset_all_new',
      method: 'POST',
    }),
  });
