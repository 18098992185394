import { TFunction } from 'i18next';
import Joi, { AnySchema, ErrorReport } from 'joi';

const countSSN = (value: number): number => {
  return Math.floor(value / 10) + (value % 10);
};

const calculateSSN = (
  b1: number,
  b2: number,
  gd1: number,
  gd2: number,
  gd3: number,
  gd4: number,
  gd5: number,
  gd6: number,
  letter: string,
  s1: number,
  s2: number
): number => {
  let z = 0,
    y = 0;

  switch (letter.toLowerCase()) {
    case 'a':
      z = 0;
      y = 1;
      break;
    case 'b':
      z = 0;
      y = 2;
      break;
    case 'c':
      z = 0;
      y = 3;
      break;
    case 'd':
      z = 0;
      y = 4;
      break;
    case 'e':
      z = 0;
      y = 5;
      break;
    case 'f':
      z = 0;
      y = 6;
      break;
    case 'g':
      z = 0;
      y = 7;
      break;
    case 'h':
      z = 0;
      y = 8;
      break;
    case 'i':
      z = 0;
      y = 9;
      break;
    case 'j':
      z = 1;
      y = 0;
      break;
    case 'k':
      z = 1;
      y = 1;
      break;
    case 'l':
      z = 1;
      y = 2;
      break;
    case 'm':
      z = 1;
      y = 3;
      break;
    case 'n':
      z = 1;
      y = 4;
      break;
    case 'o':
      z = 1;
      y = 5;
      break;
    case 'p':
      z = 1;
      y = 6;
      break;
    case 'q':
      z = 1;
      y = 7;
      break;
    case 'r':
      z = 1;
      y = 8;
      break;
    case 's':
      z = 1;
      y = 9;
      break;
    case 't':
      z = 2;
      y = 0;
      break;
    case 'u':
      z = 2;
      y = 1;
      break;
    case 'v':
      z = 2;
      y = 2;
      break;
    case 'w':
      z = 2;
      y = 3;
      break;
    case 'x':
      z = 2;
      y = 4;
      break;
    case 'y':
      z = 2;
      y = 5;
      break;
    case 'z':
      z = 2;
      y = 6;
      break;
  }

  return (
    countSSN(b1 * 2) +
    countSSN(b2) +
    countSSN(gd1 * 2) +
    countSSN(gd2 * 5) +
    countSSN(gd3 * 7) +
    countSSN(gd4) +
    countSSN(gd5 * 2) +
    countSSN(gd6) +
    z * 2 +
    y +
    countSSN(s1 * 2) +
    countSSN(s2)
  );
};

const getSSNValidation =
  (fieldRequired = false) =>
  (
    value: string,
    helpers: Joi.CustomHelpers<unknown>
  ): string | ErrorReport => {
    const invalidValueError = helpers.error('any.valueInvalid');
    const fieldRequiredError = helpers.error('any.required');

    if (!value) {
      return fieldRequired ? fieldRequiredError : value;
    }

    if (value.length != 12) return invalidValueError;
    const valueArray = value.split('');

    const result =
      calculateSSN(
        +valueArray[0],
        +valueArray[1],
        +valueArray[2],
        +valueArray[3],
        +valueArray[4],
        +valueArray[5],
        +valueArray[6],
        +valueArray[7],
        valueArray[8],
        +valueArray[9],
        +valueArray[10]
      ) % 10;

    if (result !== parseInt(valueArray[11])) return invalidValueError;

    return value;
  };

export const socialSecurityNumberValidation = (
  isRequired: boolean,
  t: TFunction
): AnySchema =>
  Joi.custom(getSSNValidation(isRequired)).messages({
    'any.valueInvalid': t('modal.labels.invalidSocialSecurityNumber'),
    'any.required': t('modal.validation.fieldRequired'),
  });
