import { Theme } from 'team-hero-ui';

import { TMissionStatus } from 'interfaces/ProjectMission.interface';

export const getStatusColor = (status: TMissionStatus): string => {
  switch (status) {
    case 'live':
      return Theme.colors.others.green.green1;
    case 'draft':
      return Theme.colors.others.blue.blue1;
    case 'archived':
      return Theme.colors.greyShades.grey3;
    default:
      return 'none';
  }
};
