import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Loader } from 'team-hero-ui';
import { useInView } from 'react-intersection-observer';

import type { IInfiniteLoaderMessagesProps } from './InfiniteLoader.types';
import type { ISorting } from 'hooks/useSort.hook';
import type { ISimpleFilter } from 'hooks/useFilters.hook';
import type { IMessage } from 'interfaces/Message.interface';
import {
  useGetMessagesQuery,
  useLazyGetMessagesQuery,
} from 'services/teamHeroApi.service';
import { useLazyInfiniteLoading } from 'hooks/infiniteLoading/useLazyInfiniteLoading.hook';
import {
  ButtonWrapper,
  LoadMoreTopStyled,
  MessageInnerWrapperStyled,
  OuterWrapperStyled,
  TriggerDivStyled,
  ScrollToBottomAnchor,
} from 'components/InfiniteLoader/InfiniteLoader.styled';
import { useDebouncedEffect } from 'hooks/debounce/useDebounceEffect.hook';

const InfiniteLoaderMessages: FC<IInfiniteLoaderMessagesProps<IMessage>> = ({
  conversationId,
  itemsPerPage,
  itemRenderer,
  showLoadButtonEveryXPages = 5,
}) => {
  const { t } = useTranslation();

  const [previousScrollHeight, setPreviousScrollHeight] = useState<number>(0);

  const listRef = useRef<HTMLDivElement | null>(null);

  const sorting = useMemo(
    (): ISorting => ({
      columnKey: 'createdAt',
      direction: 'desc',
    }),
    []
  );

  const activeFilters = useMemo(
    (): ISimpleFilter[] => [
      {
        key: 'conversation',
        operator: 'AND',
        value: conversationId || 0,
      },
    ],
    [conversationId]
  );

  const {
    data,
    hasMore,
    loadMore,
    isLoading,
    isFetching,
    isFetchingMore,
    pageNumber,
    showLoadButtonAfterPage,
    setNextLoadButtonNumber,
  } = useLazyInfiniteLoading<IMessage>({
    filters: activeFilters,
    itemsPerPage: itemsPerPage,
    sorting,
    query: useGetMessagesQuery,
    lazyQuery: useLazyGetMessagesQuery,
    tagsToRevalidate: ['Message'],
    showLoadButtonEveryXPages,
    skip: !conversationId,
  });

  const [startElementRef, inViewStartElement] = useInView({
    root: listRef.current,
    rootMargin: '100px 0px',
    threshold: 0,
  });

  const clickLoadMore = useCallback(() => {
    setNextLoadButtonNumber();
    // we need to set the previous scroll height to the current scroll height
    // but minus the height of the load more button
    setPreviousScrollHeight(
      listRef.current ? listRef.current.scrollHeight - 76 : 0
    );
    loadMore();
  }, [loadMore, setNextLoadButtonNumber]);

  useDebouncedEffect(
    () => {
      if (
        // element is in view
        inViewStartElement &&
        // not fetching already
        !isFetchingMore &&
        !isFetching &&
        // there is more data to fetch
        hasMore &&
        // load more button is not shown
        pageNumber < showLoadButtonAfterPage &&
        // conversationId is set
        !!conversationId
      ) {
        setPreviousScrollHeight(listRef.current?.scrollHeight || 0);
        loadMore();
      }
    },
    [
      hasMore,
      inViewStartElement,
      isFetching,
      isFetchingMore,
      loadMore,
      pageNumber,
      showLoadButtonAfterPage,
    ],
    100
  );

  // adding behavior to scroll to the bottom of the message list when new messages are added
  const anchorRef = useRef<HTMLDivElement>(null);

  // remain scroll position when new messages are added
  useEffect(() => {
    // the new scroll height after new messages are added
    const newScrollHeight = listRef.current?.scrollHeight || 0;
    requestAnimationFrame(() => {
      // when previous scroll height is smaller than new scroll height
      if (newScrollHeight > previousScrollHeight) {
        // scroll to the difference between new scroll height and previous scroll height
        listRef.current?.scrollTo({
          top: newScrollHeight - previousScrollHeight,
          behavior: 'instant',
        });
      } else {
        // else scroll to bottom
        listRef.current?.scrollTo({
          top: newScrollHeight,
          behavior: 'instant',
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    // Scroll to the bottom initially
    if (listRef.current) {
      listRef.current.scrollTo({
        top: listRef.current.scrollHeight,
        behavior: 'instant',
      });
    }
  }, [conversationId]);

  const reversedData = data.slice().reverse();

  return (
    <OuterWrapperStyled data-test-id='infiniteloader-wrapper' ref={listRef}>
      {!isFetching &&
        !isFetchingMore &&
        pageNumber >= showLoadButtonAfterPage &&
        hasMore && (
          <ButtonWrapper>
            <Button color={'grey'} size='large' onClick={clickLoadMore}>
              {t('common.loadMore')}
            </Button>
          </ButtonWrapper>
        )}
      {isFetchingMore && (
        <LoadMoreTopStyled>
          <Loader loaderSize='small' loaderType='static' />
        </LoadMoreTopStyled>
      )}
      <TriggerDivStyled ref={startElementRef} />
      {!!conversationId && (
        <MessageInnerWrapperStyled>
          {itemRenderer(reversedData, isLoading || isFetching)}
        </MessageInnerWrapperStyled>
      )}
      <ScrollToBottomAnchor ref={anchorRef} />
    </OuterWrapperStyled>
  );
};

export default InfiniteLoaderMessages;
