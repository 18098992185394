import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';

const NativeAppUrlListener: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      // check if event.url is set
      if (!event.url) {
        return;
      }
      try {
        // get the path from the url
        const slug = new URL(event.url);
        let navigatePath = '';
        // check if the path is a cockpit url
        if (slug.pathname.includes('/cockpit')) {
          // check if livemode is in the path
          if (slug.pathname.includes('/livemode')) {
            // remove cockpit from pathname
            const adjustedPath = slug.pathname.replace('/cockpit', '');
            navigatePath = adjustedPath + slug.search;
          } else {
            throw Error('Not a valid url');
          }
        } else {
          navigatePath = slug.pathname + slug.search;
        }
        // navigate to the path
        navigate(navigatePath);
      } catch (err) {
        throw Error('Not a valid url');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default NativeAppUrlListener;
