import { FC, useEffect } from 'react';
import { device, Utils } from 'team-hero-ui';

import { ResultsSection } from 'components/SearchPopup/components/ResultsSection/ResultsSection';
import { useSearch } from '../hooks/useSearch.hook';
import { missionsSearchColumnsFactory } from '../columnsFactories/missionsSearchColumns.config';
import type { IProjectMission } from 'interfaces/ProjectMission.interface';
import { ISearchTab } from './searchTabs.types';
import { ResultsContainer } from '../components/ResultsContainer/ResultsContainer';
import { useSimplePagination } from 'hooks/useSimplePagination.hook';

const { useMediaQuery } = Utils;

export const MissionsTab: FC<ISearchTab> = ({
  searchValue,
  onClose,
  selectedTab = 'new',
}) => {
  const { getPaginationFooter, itemsPerPage, page, resetPagination } =
    useSimplePagination(searchValue);

  const isSmScreenSize = useMediaQuery(device.sm);

  const { missions, totalCount, isFetching } = useSearch({
    pagination: {
      itemsPerPage,
      page,
    },
    searchValue,
    view: selectedTab,
  });

  useEffect(() => {
    if (!isFetching && missions.length === 0 && totalCount > 0) {
      resetPagination();
    }
  }, [missions.length, isFetching, resetPagination, totalCount]);

  return (
    <ResultsContainer
      isLoading={isFetching}
      searchValue={searchValue}
      totalCount={totalCount}
      onClose={onClose}
      withPagination
    >
      {!!missions.length && (
        <ResultsSection<IProjectMission>
          columns={missionsSearchColumnsFactory(isSmScreenSize)}
          data={missions}
          pagination={getPaginationFooter(
            totalCount,
            isSmScreenSize /*withPagesDropdown */,
            isSmScreenSize ? 'medium' : 'small' /*size */
          )}
          viewType={selectedTab}
          onClose={onClose}
        />
      )}
    </ResultsContainer>
  );
};
