import {
  BaseQueryFn,
  EndpointBuilder,
  QueryDefinition,
  MutationDefinition,
} from '@reduxjs/toolkit/query';

import type { IUserNotificationToken } from 'interfaces/UserNotificationToken.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import { serializeFlatObject } from 'services/helper/getPaginationParams.helper';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';
import {
  IGetUserNotificationTokensResponse,
  IUserNotificationTokenCollectionArgs,
  IUserNotificationTokenPostArgs,
} from 'services/userNotificationTokens/userNotificationTokens.types';

export const getUserNotificationTokens = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IUserNotificationTokenCollectionArgs,
  BaseQueryFn,
  string,
  IGetUserNotificationTokensResponse
> =>
  build.query<
    IGetUserNotificationTokensResponse,
    IUserNotificationTokenCollectionArgs
  >({
    providesTags: ['UserNotificationToken'],
    query: (queryArg: IUserNotificationTokenCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');

      return {
        url: `api/user_notification_tokens${paginationQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IUserNotificationToken>
    ): IGetUserNotificationTokensResponse =>
      transformCollectionResponse<IUserNotificationToken>(response),
  });

export const getUserNotificationTokenItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<number, BaseQueryFn, string, IUserNotificationToken> =>
  build.query<IUserNotificationToken, number>({
    providesTags: ['UserNotificationToken'],
    query: (id) => {
      return {
        url: `/api/user_notification_tokens/${id}`,
      };
    },
  });

export const postUserNotificationToken = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IUserNotificationTokenPostArgs,
  BaseQueryFn,
  string,
  IUserNotificationToken
> =>
  build.mutation<IUserNotificationToken, IUserNotificationTokenPostArgs>({
    invalidatesTags: ['UserNotificationToken'],
    query: (queryArg) => ({
      url: '/api/user_notification_tokens',
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const deleteUserNotificationToken = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<number, BaseQueryFn, string, void> =>
  build.mutation<void, number>({
    invalidatesTags: ['UserNotificationToken'],
    query: (id) => ({
      url: `/api/user_notification_tokens/${id}`,
      method: 'DELETE',
    }),
  });
