import { FC } from 'react';

import type { IModalValidationWrapper } from './ModalValidationWrapper.types';
import {
  InputWrapperStyled,
  ValidationLabelStyled,
  ValidationWrapperStyled,
} from './ModalValidationWrapper.styles';

const ModalValidationWrapper: FC<IModalValidationWrapper> = ({
  text,
  flex = 0,
  children,
  hideBorder = false,
}) => {
  return (
    <ValidationWrapperStyled
      $validationStyles={!!text && !hideBorder}
      $flex={flex}
    >
      <InputWrapperStyled>{children}</InputWrapperStyled>
      <ValidationLabelStyled>{text}</ValidationLabelStyled>
    </ValidationWrapperStyled>
  );
};

export default ModalValidationWrapper;
