import { styled } from 'styled-components';
import { Button, Theme } from 'team-hero-ui';

import { ButtonWithoutStyle } from './ColumnSelectionDropdown.styled';

export const DownloadButtonStyled = styled(Button)`
  ${ButtonWithoutStyle}
`;

export const DropdownStyled = styled.div`
  button:disabled {
    opacity: 0.5;
    background-color: ${Theme.colors.primary.white}80;
  }
`;
