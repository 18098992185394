import { FC, ReactNode } from 'react';
import { Loader } from 'team-hero-ui';

import { NoResultsFound } from '../NoResultsFound/NoResultsFound';
import SearchActionCards from '../SearchActionCards/SearchActionCards';
import { ResultsContainerStyled } from './ResultsContainer.styled';

interface IResultsContainerProps {
  children: ReactNode;
  searchValue: string;
  totalCount: number;
  isLoading: boolean;
  withPagination?: boolean;
  onClose: () => void;
}

export const ResultsContainer: FC<IResultsContainerProps> = ({
  isLoading,
  searchValue,
  totalCount,
  children,
  withPagination = false,
  onClose,
}) => {
  return (
    <>
      <SearchActionCards searchValue={searchValue} onActionSelect={onClose} />
      {isLoading && <Loader loaderType='absolute' />}
      {!!searchValue && (
        <ResultsContainerStyled $withPagination={withPagination}>
          {!isLoading && !totalCount ? <NoResultsFound /> : children}
        </ResultsContainerStyled>
      )}
    </>
  );
};
