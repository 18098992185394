import { FC, ReactNode } from 'react';
import { AnyAction, Store } from '@reduxjs/toolkit';
import { createUiKitTheme } from 'team-hero-ui';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';

import GlobalStyle from 'assets/styles/GlobalStyles';

interface IProvidersProps {
  children: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  store: Store<any, AnyAction>;
}

const theme = createUiKitTheme();

const ProvidersWithoutRouter: FC<IProvidersProps> = ({ children, store }) => (
  <Provider store={store}>
    <GlobalStyle />
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </Provider>
);

export default ProvidersWithoutRouter;
