import {
  EndpointBuilder,
  QueryDefinition,
  MutationDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type {
  IContactAbsenceEntityArgs,
  IGetAbsenceCategoriesArgs,
  IGetAbsenceCategoriesResponse,
  IGetContactAbsencesResponse,
  IPostContactAbsenceArgs,
  IPutContactAbsenceArgs,
} from './contactAbsences.api.types';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import type { IContactAbsence } from 'interfaces/ContactAbsence.interface';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';
import type { IAbsenceCategory } from 'interfaces/AbsenceCategory.interface';
import type { IId } from 'interfaces/IId.interface';

export const getContactAbsences = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IContactAbsenceEntityArgs,
  BaseQueryFn,
  string,
  IGetContactAbsencesResponse
> =>
  build.query<IGetContactAbsencesResponse, IContactAbsenceEntityArgs>({
    providesTags: [
      { type: 'ContactAbsence' },
      { type: 'ContactAbsence', id: 'LIST' },
    ],
    query: (queryArg) => {
      const paginationParamObject = {
        contact: queryArg.contactId,
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        during: queryArg.during,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);

      return {
        url: `/api/contact_out_of_offices${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IContactAbsence>
    ): IGetContactAbsencesResponse =>
      transformCollectionResponse<IContactAbsence>(response),
  });

export const getContactAbsence = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<number | undefined, BaseQueryFn, string, IContactAbsence> =>
  build.query<IContactAbsence, number | undefined>({
    providesTags: ['ContactAbsence'],
    query: (id) => {
      return {
        url: `/api/contact_out_of_offices/${id}`,
      };
    },
  });

export const postContactAbsence = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostContactAbsenceArgs,
  BaseQueryFn,
  string,
  IContactAbsence
> =>
  build.mutation<IContactAbsence, IPostContactAbsenceArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags
        ? []
        : ['ContactAbsence', 'ContactHolidayPlan', 'AbsenceFile'],
    query: (queryArg) => ({
      url: '/api/contact_out_of_offices',
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const putContactAbsence = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPutContactAbsenceArgs,
  BaseQueryFn,
  string,
  IContactAbsence
> =>
  build.mutation<IContactAbsence, IPutContactAbsenceArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags
        ? []
        : ['ContactAbsence', 'ContactHolidayPlan', 'AbsenceFile'],
    query: (queryArg) => ({
      url: `/api/contact_out_of_offices/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });

export const deleteContactAbsence = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IId, BaseQueryFn, string, void> =>
  build.mutation<void, IId>({
    invalidatesTags: [
      { type: 'ContactAbsence', id: 'LIST' },
      'ContactHolidayPlan',
    ],
    query: (queryArg) => ({
      url: `/api/contact_out_of_offices/${queryArg.id}`,
      method: 'DELETE',
    }),
  });

export const getAbsenceCategories = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetAbsenceCategoriesArgs,
  BaseQueryFn,
  string,
  IGetAbsenceCategoriesResponse
> =>
  build.query<IGetAbsenceCategoriesResponse, IGetAbsenceCategoriesArgs>({
    providesTags: ['AbsenceCategory'],
    query: (queryArg) => {
      const paginationParamObject = {
        pagination: queryArg.pagination,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');

      return {
        url: `/api/out_of_office_categories${paginationQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IAbsenceCategory>
    ): IGetAbsenceCategoriesResponse =>
      transformCollectionResponse<IAbsenceCategory>(response),
  });
