interface IGetIriDataReturn {
  entity: string;
  id: number;
}

export const getIriData = (iri: string): IGetIriDataReturn => {
  const [entity, id] = iri.split('/').filter(Boolean).slice(1);

  return { entity, id: parseInt(id, 10) };
};
