import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

interface IRatingScoreStyledProps {
  $bgColor: string;
  $fontColor: string;
}

export const RatingScoreStyled = styled.div<IRatingScoreStyledProps>`
  font-weight: 600;
  ${flexFn()}
  width: 100%;
  height: 100%;
  margin-right: ${pxToRem(20)};
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ $fontColor }) => $fontColor};
`;
