import { FC } from 'react';
import { IconButton, Icons, Theme } from 'team-hero-ui';

import { TShiftApplicationClientStatus } from 'interfaces/ShiftApplication.interface';
import useSetApplicationClientStatus from '../../hooks/useSetApplicationClientStatus.hook';
import { IRatingButtonsProps } from './RatingButtons.types';

const iconSize = 40;

const rateStatuses: TShiftApplicationClientStatus[] = [
  'favorite',
  'okay',
  'nofit',
];

const RatingButtons: FC<IRatingButtonsProps> = ({
  missionId,
  applicationIds,
  selectedStatus,
}) => {
  const { setApplicationClientStatus } = useSetApplicationClientStatus({
    missionId,
  });

  const getStyleType = (status: TShiftApplicationClientStatus) =>
    selectedStatus === status ? 'lightGrey' : undefined;

  const getIconColor = (
    status: TShiftApplicationClientStatus,
    defaultColor: string
  ) =>
    selectedStatus &&
    rateStatuses.includes(selectedStatus) &&
    selectedStatus !== status
      ? Theme.colors.greyShades.grey3
      : defaultColor;

  return (
    <>
      <IconButton
        styleType={getStyleType('favorite')}
        onClick={() =>
          setApplicationClientStatus({
            applicationIds: applicationIds,
            status: 'favorite',
          })
        }
      >
        <Icons.PollFavourite
          svgSize={iconSize}
          svgColor={getIconColor('favorite', Theme.colors.primary.green)}
        />
      </IconButton>
      <IconButton
        styleType={getStyleType('okay')}
        onClick={() =>
          setApplicationClientStatus({
            applicationIds: applicationIds,
            status: 'okay',
          })
        }
      >
        <Icons.PollOK
          svgSize={iconSize}
          svgColor={getIconColor('okay', Theme.colors.primary.green)}
        />
      </IconButton>
      <IconButton
        styleType={getStyleType('nofit')}
        onClick={() =>
          setApplicationClientStatus({
            applicationIds: applicationIds,
            status: 'nofit',
          })
        }
      >
        <Icons.PollNoFit
          svgSize={iconSize}
          svgColor={getIconColor('nofit', Theme.colors.primary.red)}
        />
      </IconButton>
    </>
  );
};

export default RatingButtons;
