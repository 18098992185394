import {
  EndpointBuilder,
  QueryDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type { IPublicSystemData } from 'interfaces/PublicSystemSettings.interface';
import type { ISystemData } from 'interfaces/SystemSettings.interface';

export const getSystemSettings = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<void, BaseQueryFn, string, ISystemData> =>
  build.query<ISystemData, void>({
    providesTags: ['SystemSetting'],
    query: () => ({
      url: `/api/settings/system`,
    }),
  });

export const getPublicSystemSettings = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<void, BaseQueryFn, string, IPublicSystemData> =>
  build.query<IPublicSystemData, void>({
    providesTags: ['PublicSystemSetting'],
    query: () => ({
      url: `/api/settings/public`,
    }),
  });
