import {
  EndpointBuilder,
  QueryDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import type { IApplicationSource } from 'interfaces/ApplicationSource.interface';
import type {
  IApplicationSourcesCollectionResponse,
  IApplicationSourcesCollectionArgs,
  IGetApplicationSourceItemArgs,
} from './applicationSources.api.types';

const defaultItems: IApplicationSource[] = [];

export const getApplicationSources = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IApplicationSourcesCollectionArgs,
  BaseQueryFn,
  string,
  IApplicationSourcesCollectionResponse
> =>
  build.query<
    IApplicationSourcesCollectionResponse,
    IApplicationSourcesCollectionArgs
  >({
    providesTags: ['ApplicationSource'],
    query: (queryArg: IApplicationSourcesCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);
      return {
        url: `api/application_sources${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IApplicationSource>
    ): IApplicationSourcesCollectionResponse => {
      const items =
        (response['hydra:member'] as IApplicationSource[]) || defaultItems;
      return { items };
    },
  });

export const getApplicationSourceItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetApplicationSourceItemArgs,
  BaseQueryFn,
  string,
  IApplicationSource
> =>
  build.query<IApplicationSource, IGetApplicationSourceItemArgs>({
    providesTags: ['ApplicationSource'],
    query: (queryArg: IGetApplicationSourceItemArgs) => ({
      url: `api/application_sources/${queryArg.id}`,
    }),
  });
