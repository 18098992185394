import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const ApplicationSedcardSectionStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')};
  height: 100%;
`;

export const TopSectionStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')};
  padding: ${pxToRem(24)};
  padding-top: ${pxToRem(12)};
  background-color: ${Theme.colors.primary.white};
`;

export const FirstNameStyled = styled.p`
  font-size: ${pxToRem(24)};
  font-weight: 600;
  color: ${Theme.colors.greyShades.grey1};
`;

export const RatingButtonsStyled = styled.div`
  ${flexFn('center', 'stretch', 'row')}
`;

export const BottomSectionStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')}
  background-color: ${Theme.colors.greyShades.grey5};
  padding: ${pxToRem(24)};
  gap: ${pxToRem(24)};
  flex: 1;
`;

export const HeaderStyled = styled.div`
  ${flexFn('space-between', 'center')};
  p {
    color: ${Theme.colors.greyShades.grey2};
    font-size: ${pxToRem(20)};
  }
`;

export const ContactInfoStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')}
  gap: ${pxToRem(8)};
  color: ${Theme.colors.greyShades.grey2};
  margin-bottom: ${pxToRem(24)};
  font-size: ${pxToRem(14)};
`;

export const CardTitleStyled = styled.div`
  font-size: ${pxToRem(20)};
  font-weight: 600;
  text-transform: uppercase;
`;

export const RatingCardContentStyled = styled.div`
  ${flexFn('space-between', 'center')};
  margin-top: ${pxToRem(-16)};
`;
