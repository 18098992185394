export const searchColumnsWidths = {
  XS: '50px',
  S: '150px',
  M: '220px',
  L: '300px',
  AVATAR: '60px',
  DATE: '150px',
  STATUS: '7px',
  STATUSWIDE: '14px',
};
