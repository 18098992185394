import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const ApplicationGroupsStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')};
  gap: ${pxToRem(4)};
  margin-top: ${pxToRem(-24)};
  margin-bottom: ${pxToRem(24)};
`;
