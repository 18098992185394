import { styled, css, keyframes } from 'styled-components';
import { device, Theme, Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

const fadeIn = keyframes`
  0% {
    top: -100dvh;
    opacity:0;
  }
  100% {
    top: 0;
    opacity:1;
  }
`;

const fadeOut = keyframes`
  0% {
    top: 0;
    opacity:1;
  }
  100% {
    top: -100dvh;
    opacity:0;
  }
`;

interface IWrapperStyledProps {
  $displayCloseAnimation: boolean;
  $displayOpenAnimation: boolean;
}

export const WrapperStyled = styled.div<IWrapperStyledProps>`
  ${flexFn('flex-start', 'center', 'column')};
  overflow: hidden;
  height: 100dvh;
  width: 100vw;
  background-color: ${Theme.colors.primary.grey}f2;
  z-index: 1200;
  position: fixed;
  top: -100dvh;
  left: 0;
  color: ${Theme.colors.primary.white};

  ${({ $displayOpenAnimation }) =>
    $displayOpenAnimation &&
    css`
      animation: 0.3s ${fadeIn} ease-in;
      animation-fill-mode: forwards;
    `}

  ${({ $displayCloseAnimation }) =>
    $displayCloseAnimation &&
    css`
      animation: 0.3s ${fadeOut} ease-out;
      animation-fill-mode: forwards;
    `}
`;

export const CloseStyled = styled.div`
  width: 100%;
  ${flexFn('flex-end', 'center')};
  margin: ${pxToRem([12, 24, 0, 0])};

  @media ${device.sm} {
    margin: ${pxToRem([24, 24, 0, 0])};
  }
`;

export const ContainerWrapper = styled.div`
  height: 100dvh;
  ${flexFn('flex-start', 'center', 'column-reverse')};
  margin: ${pxToRem(12)};
  gap: ${pxToRem(24)};
  width: 90%;
  overflow: auto;
  @media ${device.sm} {
    margin: ${pxToRem(24)};
    ${flexFn('flex-start', 'center', 'column')};
    width: 80%;
  }
  @media ${device.md} {
    width: 60%;
  }
`;

export const InputStyled = styled.div`
  gap: ${pxToRem(4)};
  width: 100%;
  ${flexFn('flex-start', 'center', 'column')};
`;
