import {
  EndpointBuilder,
  MutationDefinition,
  QueryDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type {
  ISignatureFileEntityArgs,
  IPostSignatureFileArgs,
  IPutSignatureFileEntryArgs,
} from './signatureFiles.api.types';
import type { ISignatureFileResource } from 'interfaces/SignatureFile.interface';

export const getSignatureFileEntity = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  ISignatureFileEntityArgs,
  BaseQueryFn,
  string,
  ISignatureFileResource
> =>
  build.query<ISignatureFileResource, ISignatureFileEntityArgs>({
    providesTags: ['SignatureFile'],
    query: (queryArg: ISignatureFileEntityArgs) => ({
      url: `/api/signature_files/${queryArg.id}`,
    }),
  });

export const postSignatureFileEntityItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<void, BaseQueryFn, string, ISignatureFileResource> =>
  build.mutation<ISignatureFileResource, void>({
    invalidatesTags: ['SignatureFile'],
    query: () => ({
      url: `api/signature_files`,
      method: 'POST',
      body: {},
    }),
  });

export const putSignatureFileEntityItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPutSignatureFileEntryArgs,
  BaseQueryFn,
  string,
  ISignatureFileResource
> =>
  build.mutation<ISignatureFileResource, IPutSignatureFileEntryArgs>({
    invalidatesTags: ['SignatureFile'],
    query: (queryArg) => ({
      url: `api/signature_files/${queryArg.id}`,
      method: 'PUT',
      body: queryArg,
    }),
  });

export const postSignatureFileItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostSignatureFileArgs,
  BaseQueryFn,
  string,
  ISignatureFileResource
> =>
  build.mutation<ISignatureFileResource, IPostSignatureFileArgs>({
    query: (queryArg) => ({
      url: `api/signature_files/${queryArg.id}/file`,
      method: 'POST',
      body: queryArg.body,
      extraOptions: { maxRetries: 0 },
    }),
  });
