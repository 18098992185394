import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

export const ModalContentStyled = styled.div`
  height: 100%;
  overflow: auto;
  ${flexFn('flex-start', 'stretch', 'column')};
  gap: ${pxToRem(4)};
  width: 25vw;
  min-width: ${pxToRem(400)};
`;
