export const moveItem = <T>(array: T[], from: number, to: number): T[] => {
  if (to === from) return array;

  const target = array[from];
  const increment = to < from ? -1 : 1;

  for (let k = from; k !== to; k += increment) {
    array[k] = array[k + increment];
  }
  array[to] = target;
  return array;
};

/**
 * This helper function checks, if the given value is first occurring, if not will return false
 */
export const onlyUnique = (
  value: string,
  index: number,
  self: string[]
): boolean => self.indexOf(value) === index;
