import {
  EndpointBuilder,
  QueryDefinition,
  MutationDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import type {
  IApplicationJobAdCollectionArgs,
  IApplicationJobAdCollectionResponse,
  IApplicationJobAdPostArgs,
  IDeleteApplicationJobAdItemArgs,
} from './applicationJobAdSources.types';
import type { IApplicationJobAdSource } from 'interfaces/ApplicationJobAdSource.interface';

const defaultTotalCount = 0;
const defaultItems: IApplicationJobAdSource[] = [];

export const getApplicationJobAdSources = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IApplicationJobAdCollectionArgs,
  BaseQueryFn,
  string,
  IApplicationJobAdCollectionResponse
> =>
  build.query<
    IApplicationJobAdCollectionResponse,
    IApplicationJobAdCollectionArgs
  >({
    providesTags: ['ApplicationJobAdSources'],
    query: (queryArg: IApplicationJobAdCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);
      return {
        url: `api/application_job_ad_sources${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IApplicationJobAdSource>
    ): IApplicationJobAdCollectionResponse => {
      const totalCount =
        (response['hydra:totalItems'] as number) || defaultTotalCount;
      const items =
        (response['hydra:member'] as IApplicationJobAdSource[]) || defaultItems;
      return { items, totalCount };
    },
  });

export const postApplicationJobAdSourceItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IApplicationJobAdPostArgs,
  BaseQueryFn,
  string,
  IApplicationJobAdSource
> =>
  build.mutation<IApplicationJobAdSource, IApplicationJobAdPostArgs>({
    invalidatesTags: ['ApplicationJobAdSources'],
    query: (queryArg) => ({
      url: 'api/application_job_ad_sources',
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const deleteApplicationJobAdSourceItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IDeleteApplicationJobAdItemArgs,
  BaseQueryFn,
  string,
  unknown
> =>
  build.mutation<unknown, IDeleteApplicationJobAdItemArgs>({
    invalidatesTags: ['ApplicationJobAdSources'],
    query: (queryArg) => ({
      url: `api/application_job_ad_sources/${queryArg.id}`,
      method: 'DELETE',
    }),
  });
