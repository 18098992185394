import { FC, useRef } from 'react';
import { Button } from 'team-hero-ui';
import { useTranslation } from 'react-i18next';

import { FilterPanelVisibilityWrapper } from 'components/FilterPanel/FilterPanel.styled';
import type { IApplicationPoolFilterPanelProps } from './ApplicationPoolFilterPanel.types';
import { ApplicationGroupsStyled } from './ApplicationPoolFilterPanel.styled';
import ApplicationGroup from './ApplicationGroup';

const ApplicationPoolFilterPanel: FC<IApplicationPoolFilterPanelProps> = ({
  hidden,
  applicationGroups,
  onApplicationGroupClick,
  onShowAllClick,
}) => {
  const { t } = useTranslation('general');
  const enterContainerRef = useRef<HTMLDivElement>(null);

  return (
    <FilterPanelVisibilityWrapper
      $hidden={hidden}
      $styleType={'default'}
      ref={enterContainerRef}
      data-test-id='filter-panel-wrapper'
    >
      <ApplicationGroupsStyled>
        {applicationGroups.map((applicationGroup) => (
          <ApplicationGroup
            key={applicationGroup.id}
            applicationGroup={applicationGroup}
            onApplicationGroupClick={onApplicationGroupClick}
          />
        ))}
      </ApplicationGroupsStyled>
      <Button onClick={onShowAllClick}>
        {t('clientLogin.applications.showAll')}
      </Button>
    </FilterPanelVisibilityWrapper>
  );
};

export default ApplicationPoolFilterPanel;
