import {
  BaseQueryFn,
  MutationDefinition,
  QueryDefinition,
  EndpointBuilder,
} from '@reduxjs/toolkit/query';

import type {
  IProjectExpense,
  IProjectExpenseFileEntry,
} from 'interfaces/ProjectExpense.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import type {
  IProjectExpenseItemArgs,
  IGetProjectExpensesCollectionArgs,
  IGetProjectExpensesCollectionResponse,
  IProjectExpenseArgs,
  IProjectExpenseFileArgs,
  IPutProjectExpenseArgs,
} from './projectExpenses.api.types';

const defaultTotalCount = 0;
const defaultItems: IProjectExpense[] = [];

export const getProjectExpenses = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetProjectExpensesCollectionArgs,
  BaseQueryFn,
  string,
  IGetProjectExpensesCollectionResponse
> =>
  build.query<
    IGetProjectExpensesCollectionResponse,
    IGetProjectExpensesCollectionArgs
  >({
    providesTags: ['ProjectExpense'],
    query: (queryArg: IGetProjectExpensesCollectionArgs) => {
      const paginationParamObject = {
        createdBy: queryArg.createdBy,
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);

      return {
        url: `api/project_expenses${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IProjectExpense>
    ): IGetProjectExpensesCollectionResponse => {
      const totalCount =
        (response['hydra:totalItems'] as number) || defaultTotalCount;
      const items =
        (response['hydra:member'] as IProjectExpense[]) || defaultItems;
      return { items, totalCount };
    },
  });

export const getProjectExpenseItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IProjectExpenseItemArgs,
  BaseQueryFn,
  string,
  IProjectExpense
> =>
  build.query<IProjectExpense, IProjectExpenseItemArgs>({
    providesTags: ['ProjectExpense'],
    query: (queryArg: IProjectExpenseItemArgs) => ({
      url: `/api/project_expenses/${queryArg.id}`,
    }),
  });

export const postProjectExpense = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IProjectExpenseArgs,
  BaseQueryFn,
  string,
  IProjectExpense
> =>
  build.mutation<IProjectExpense, IProjectExpenseArgs>({
    invalidatesTags: ['ProjectExpense'],
    query: (queryArg) => ({
      url: `api/project_expenses`,
      method: 'POST',
      body: queryArg,
    }),
  });

export const putProjectExpense = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPutProjectExpenseArgs,
  BaseQueryFn,
  string,
  IProjectExpense
> =>
  build.mutation<IProjectExpense, IPutProjectExpenseArgs>({
    invalidatesTags: ['ProjectExpense'],
    query: (queryArg) => ({
      url: `api/project_expenses/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.data,
    }),
  });

export const postProjectExpenseFile = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IProjectExpenseFileArgs,
  BaseQueryFn,
  string,
  IProjectExpenseFileEntry
> =>
  build.mutation<IProjectExpenseFileEntry, IProjectExpenseFileArgs>({
    query: (queryArg) => ({
      url: `api/project_expenses/${queryArg.id}/file`,
      method: 'POST',
      body: queryArg.body,
      extraOptions: { maxRetries: 0 },
    }),
  });

export const deleteProjectExpense = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IProjectExpenseItemArgs,
  BaseQueryFn,
  string,
  IProjectExpense
> =>
  build.mutation<IProjectExpense, IProjectExpenseItemArgs>({
    invalidatesTags: ['ProjectExpense'],
    query: (queryArg) => ({
      url: `api/project_expenses/${queryArg.id}`,
      method: 'DELETE',
    }),
  });
