import { StatusStyled } from './StatusCellRenderer.styled';

export const StatusCellRenderer = (
  statusColor: string,
  onClick?: () => void,
  disabled?: boolean
): JSX.Element => {
  return (
    <StatusStyled
      $disabled={disabled}
      onClick={onClick}
      $statusColor={statusColor}
    />
  );
};
