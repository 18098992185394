import { Button, Icons, Loader, Theme } from 'team-hero-ui';
import { FC, useCallback, useEffect, useState } from 'react';

import type {
  IApplicationGroup,
  IMissionApplicationsTabProps,
} from './MissionApplicationsTab.types';
import { useToggle } from 'hooks/useToggle.hook';
import ApplicationPoolFilterPanel from './components/ApplicationPoolFilterPanel';
import { MissionApplicationsStyled } from './MissionApplicationsTab.styled';
import {
  useGetShiftApplicationsQuery,
  useLazyGetProjectShiftItemQuery,
} from 'services/teamHeroApi.service';
import { getGroupedApplications } from './helpers/applications.helper';
import ApplicationPool from './components/ApplicationPool';

const MissionApplicationsTab: FC<IMissionApplicationsTabProps> = ({
  missionId,
}) => {
  const { toggle, toggleValue } = useToggle(false);

  const [selectedApplicationIds, setSelectedApplicationIds] = useState<
    number[]
  >([]);

  const { data: applications, isLoading: isLoadingApplications } =
    useGetShiftApplicationsQuery({
      pagination: false,
      filters: [
        {
          key: 'shift.mission',
          operator: 'AND',
          value: missionId,
        },
      ],
    });

  const [applicationGroups, setApplicationGroups] = useState<
    IApplicationGroup[]
  >([]);

  const selectAllApplications = useCallback(() => {
    setSelectedApplicationIds(
      applications?.items?.map((application) => application.id) || []
    );
  }, [applications?.items]);

  // set initial selected applications to all applications
  useEffect(() => {
    if (!selectedApplicationIds.length) {
      selectAllApplications();
    }
  }, [applications, selectAllApplications, selectedApplicationIds.length]);

  const [triggerProjectShiftItem] = useLazyGetProjectShiftItemQuery();

  const getShift = useCallback(
    async (id: number) => {
      const shift = await triggerProjectShiftItem({ id }, true).unwrap();
      return shift;
    },
    [triggerProjectShiftItem]
  );

  useEffect(() => {
    if (applications?.items) {
      getGroupedApplications(applications.items, getShift).then((data) =>
        setApplicationGroups(data)
      );
    }
  }, [applications, getShift]);

  const onSelectApplicationGroup = (applicationGroup: IApplicationGroup) => {
    setSelectedApplicationIds(
      applicationGroup.applications.map((application) => application.id)
    );
  };

  const selectedApplicationsData = applications?.items?.filter((application) =>
    selectedApplicationIds.includes(application.id)
  );

  if (isLoadingApplications) {
    return <Loader loaderType='static' />;
  }

  return (
    <MissionApplicationsStyled>
      <Button
        color='toolbar'
        onClick={toggle}
        data-test-id='open-filters-button'
      >
        <Icons.FilterIcon
          svgColor={Theme.colors.greyShades.grey2}
          svgSize={13}
        />
      </Button>
      <ApplicationPoolFilterPanel
        hidden={!toggleValue}
        applicationGroups={applicationGroups}
        onApplicationGroupClick={onSelectApplicationGroup}
        onShowAllClick={selectAllApplications}
      />
      <ApplicationPool
        missionId={missionId}
        allApplications={applications?.items || []}
        selectedApplications={selectedApplicationsData || []}
      />
    </MissionApplicationsStyled>
  );
};

export default MissionApplicationsTab;
