import {
  BaseQueryFn,
  EndpointBuilder,
  QueryDefinition,
} from '@reduxjs/toolkit/query';

import type { ICustomFields } from 'interfaces/CustomFields.interface';

export const getCustomFields = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<void, BaseQueryFn, string, ICustomFields> =>
  build.query<ICustomFields, void>({
    providesTags: ['SettingsCustomField'],
    query: () => `/api/settings/custom_fields`,
  });
