import { FC } from 'react';
import { MessageBubbleSkeleton } from 'team-hero-ui';

interface IMessagesSkeletonProps {
  messagesNumber?: number;
}

const MessagesSkeleton: FC<IMessagesSkeletonProps> = ({
  messagesNumber = 3,
}) => {
  return (
    <div>
      {[...Array(messagesNumber).keys()].map((value, index) => (
        <MessageBubbleSkeleton key={value} isSentMessage={index % 2 !== 0} />
      ))}
    </div>
  );
};

export default MessagesSkeleton;
