import { dateService } from 'services/date/date.service';

export const getDuringStringHelper = (
  startDate: Date,
  endDate: Date
): string => {
  return (
    dateService(startDate).format('YYYY-MM-DDTHH:mm:ss') +
    ';' +
    dateService(endDate).format('YYYY-MM-DDTHH:mm:ss')
  );
};
