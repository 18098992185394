import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import type { IApiError } from './api.types';
import { axiosClient } from 'clients/httpClient';

export class ApiService {
  // private static errorHandler = (error: any) => {};

  public static Get = async <Response>(
    endpoint: string,
    params?: AxiosRequestConfig
  ): Promise<Response | IApiError> => {
    const success = (res: AxiosResponse): Response => {
      return res.data as Response;
    };

    const failure = (error: AxiosError): IApiError => {
      return error.response?.data as IApiError;
      // fire notification here if needed
    };

    return axiosClient
      .get(`/${endpoint}`, params)
      .then((response) => Promise.resolve(success(response)))
      .catch((error) => Promise.reject(failure(error)));
  };
}
