import {
  EndpointBuilder,
  QueryDefinition,
  MutationDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type { IContactImageResource } from 'interfaces/ContactImage.interface';
import type {
  IContactImageEntityArgs,
  IDeleteContactImageArgs,
  IPostContactImageArgs,
  IPostContactImageEntryArgs,
  IPutContactImageEntryArgs,
} from './contactImages.api.types';

export const getContactImageEntity = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IContactImageEntityArgs,
  BaseQueryFn,
  string,
  IContactImageResource
> =>
  build.query<IContactImageResource, IContactImageEntityArgs>({
    providesTags: ['ContactImage'],
    query: (queryArg: IContactImageEntityArgs) => ({
      url: `/api/contact_images/${queryArg.id}`,
    }),
  });

export const postContactImageEntityItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostContactImageEntryArgs,
  BaseQueryFn,
  string,
  IContactImageResource
> =>
  build.mutation<IContactImageResource, IPostContactImageEntryArgs>({
    invalidatesTags: ['ContactImage'],
    query: (queryArg) => ({
      url: `api/contact_images`,
      method: 'POST',
      body: queryArg,
    }),
  });

export const putContactImageEntityItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPutContactImageEntryArgs,
  BaseQueryFn,
  string,
  IContactImageResource
> =>
  build.mutation<IContactImageResource, IPutContactImageEntryArgs>({
    invalidatesTags: ['ContactImage'],
    query: (queryArg) => ({
      url: `api/contact_images/${queryArg.id}`,
      method: 'PUT',
      body: queryArg,
    }),
  });

export const deleteContactImageItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IDeleteContactImageArgs, BaseQueryFn, string, unknown> =>
  build.mutation<unknown, IDeleteContactImageArgs>({
    invalidatesTags: ['ContactImage'],
    query: (queryArg) => ({
      url: `/api/contact_images/${queryArg.id}`,
      method: 'DELETE',
    }),
  });

export const postContactImageItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostContactImageArgs,
  BaseQueryFn,
  string,
  IContactImageResource
> =>
  build.mutation<IContactImageResource, IPostContactImageArgs>({
    query: (queryArg) => ({
      url: `api/contact_images/${queryArg.id}/file`,
      method: 'POST',
      body: queryArg.body,
      extraOptions: { maxRetries: 0 },
      formData: true,
    }),
  });
