import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from 'team-hero-ui';

import { getUniqueApplications } from '../../../helpers/applications.helper';
import {
  ApplicationCountStyled,
  ApplicationGroupStyled,
  AreaStyled,
  PositionStyled,
} from './ApplicationGroup.styled';
import type { IApplicationGroupProps } from './ApplicationGroup.types';

const ApplicationGroup: FC<IApplicationGroupProps> = ({
  applicationGroup,
  onApplicationGroupClick,
}) => {
  const { t } = useTranslation('general');

  return (
    <ApplicationGroupStyled
      onClick={() => onApplicationGroupClick(applicationGroup)}
    >
      <Grid container>
        <Grid item xs={8}>
          <PositionStyled>{applicationGroup.position}</PositionStyled>
          <AreaStyled>{applicationGroup.area}</AreaStyled>
        </Grid>
        {!!applicationGroup.openApplications.length && (
          <Grid item xs={4}>
            <ApplicationCountStyled>
              <p>{t('clientLogin.applications.toCheck')} </p>
              <p>
                {
                  getUniqueApplications(applicationGroup.openApplications)
                    .length
                }
              </p>
            </ApplicationCountStyled>
          </Grid>
        )}
      </Grid>
    </ApplicationGroupStyled>
  );
};

export default ApplicationGroup;
