import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

import { CommonCellContentStyled } from '../Common.styled';

const { flexFn, pxToRem } = Utils;

export const WrapperStyled = styled(CommonCellContentStyled)`
  ${flexFn('center', 'center', 'row')};
  font-size: ${pxToRem(13)};
`;

export const ShiftStartStyled = styled.span`
  ${flexFn()};
  font-size: inherit;
`;

export const ShiftEndStyled = styled.span`
  ${flexFn()};
  font-size: inherit;
`;

export const ShiftEndOverlayStyled = styled.span`
  ${flexFn()};
  font-size: inherit;
  color: ${Theme.colors.primary.grey};
  padding-right: ${pxToRem(5)};
  background: ${Theme.colors.primary.white};
`;

export const OverlappingDaysStyled = styled.span`
  ${flexFn()};
  padding: ${pxToRem([0, 0, 2, 0])};
  border-top: ${pxToRem(2)} solid ${Theme.colors.primary.white};
  font-size: inherit;
  background: ${Theme.colors.primary.grey};
`;

export const OverlappingDaysNumberStyled = styled.span`
  ${flexFn()};
  font-size: ${pxToRem(9)};
  padding: ${pxToRem([0, 2])};
  color: ${Theme.colors.primary.white};
`;
