import { jwtDecode } from 'jwt-decode';

import type { IUserTokenPayload } from './user.types';
import LocalStorageService, {
  ELocalStorageKeys,
} from 'services/localStorage/localStorage.service';

export const removeUserSession = (): void => {
  LocalStorageService.remove(ELocalStorageKeys.heroUserToken);
  LocalStorageService.remove(ELocalStorageKeys.heroUserRefreshToken);
};

export const storeToken = (token?: string): void => {
  if (token) {
    LocalStorageService.set(ELocalStorageKeys.heroUserToken, token);
  }
};

export const storeRefreshToken = (token?: string): void => {
  if (token) {
    LocalStorageService.set(ELocalStorageKeys.heroUserRefreshToken, token);
  }
};

export const getToken = (): string | null => {
  return LocalStorageService.get(ELocalStorageKeys.heroUserToken);
};

export const getRefreshToken = (): string | null => {
  return LocalStorageService.get(ELocalStorageKeys.heroUserRefreshToken);
};

export const removeToken = (): void => {
  LocalStorageService.remove(ELocalStorageKeys.heroUserToken);
};

export const removeRefreshToken = (): void => {
  LocalStorageService.remove(ELocalStorageKeys.heroUserRefreshToken);
};

export const getPayloadFromToken = (
  token: string | null
): IUserTokenPayload => {
  if (!token) {
    // TODO: change it later
    throw new Error('Token is not available');
  }

  const { email, roles } = jwtDecode(token) as IUserTokenPayload;
  return { email, roles };
};

export const getTokenPayload = (): IUserTokenPayload | null => {
  const token = LocalStorageService.get(ELocalStorageKeys.heroUserToken);
  if (!token) return null;
  return getPayloadFromToken(token);
};
