import { Theme } from 'team-hero-ui';

import { RatingScoreStyled } from './RatingScoreCellRenderer.styled';

export const RatingScoreCellRenderer = (
  score: number,
  bgColor = Theme.colors.greyShades.grey6,
  fontColor = Theme.colors.primary.grey
): JSX.Element => {
  const roundedScore = (Math.round(score * 10) / 10).toFixed(1);

  return (
    <RatingScoreStyled $bgColor={bgColor} $fontColor={fontColor}>
      {roundedScore}
    </RatingScoreStyled>
  );
};
