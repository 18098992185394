import { useGetProjectMissionItemQuery } from 'services/teamHeroApi.service';

interface IUseClientLoginMissionSettingsReturn {
  enableClientAccess: boolean;
  enableClientCanRate: boolean;
  enableClientContacts: boolean;
  enableClientJobs: boolean;
  enableClientLivemode: boolean;
  enableClientPool: boolean;
  enableClientRatings: boolean;
  enableClientTimetrackings: boolean;
}

interface IUseClientLoginMissionSettingsProps {
  missionId?: number;
}

const useClientLoginMissionSettings = ({
  missionId,
}: IUseClientLoginMissionSettingsProps): IUseClientLoginMissionSettingsReturn => {
  const { data: mission } = useGetProjectMissionItemQuery(
    {
      id: missionId,
    },
    { skip: !missionId }
  );

  return {
    enableClientAccess: mission?.enableClientAccess ?? false,
    enableClientCanRate: mission?.enableClientCanRate ?? false,
    enableClientContacts: mission?.enableClientContacts ?? false,
    enableClientJobs: mission?.enableClientJobs ?? false,
    enableClientLivemode: mission?.enableClientLivemode ?? false,
    enableClientPool: mission?.enableClientPool ?? false,
    enableClientRatings: mission?.enableClientRatings ?? false,
    enableClientTimetrackings: mission?.enableClientTimetrackings ?? false,
  };
};

export default useClientLoginMissionSettings;
