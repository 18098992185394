import type { IPushNotificationData } from './NativePushNotificationsProvider.interface';

export const checkIsNotificationData = (
  data: unknown
): data is IPushNotificationData => {
  return (
    typeof data === 'object' &&
    data !== null &&
    'route' in data &&
    typeof data.route === 'string'
  );
};
