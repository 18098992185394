import {
  BaseQueryFn,
  QueryDefinition,
  MutationDefinition,
  EndpointBuilder,
} from '@reduxjs/toolkit/query';

import type { IContactPayslipFile } from 'interfaces/ContactPayslip.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import type {
  IContactPayslipFileArgs,
  IDeleteContactPayslipFileEntityArgs,
  IGetContactFilesCollectionArgs,
  IGetContactFilesCollectionResponse,
} from './contactPayslipFile.api.types';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';

export const getContactPayslipFiles = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetContactFilesCollectionArgs,
  BaseQueryFn,
  string,
  IGetContactFilesCollectionResponse
> =>
  build.query<
    IGetContactFilesCollectionResponse,
    IGetContactFilesCollectionArgs
  >({
    providesTags: ['ContactPayslipFile'],
    query: (queryArg: IGetContactFilesCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);

      return {
        url: `/api/contact_payslip_files${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IContactPayslipFile>
    ): IGetContactFilesCollectionResponse =>
      transformCollectionResponse<IContactPayslipFile>(response),
  });
export const getContactPayslipFileItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IContactPayslipFileArgs,
  BaseQueryFn,
  string,
  IContactPayslipFile
> =>
  build.query<IContactPayslipFile, IContactPayslipFileArgs>({
    providesTags: ['ContactPayslipFile'],
    query: (queryArg: IContactPayslipFileArgs) => ({
      url: `/api/contact_payslip_files${queryArg.id}`,
    }),
  });

export const deleteContactPayslipFile = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IDeleteContactPayslipFileEntityArgs,
  BaseQueryFn,
  string,
  IContactPayslipFile
> =>
  build.mutation<IContactPayslipFile, IDeleteContactPayslipFileEntityArgs>({
    invalidatesTags: ['ContactPayslipFile'],
    query: (queryArg) => ({
      url: `api/contact_payslip_files${queryArg.id}`,
      method: 'DELETE',
    }),
  });
