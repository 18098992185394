import { styled, css } from 'styled-components';
import { ITeamHeroTheme, Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

const baseFilterElementStyle = css`
  width: 100%;
  margin-bottom: ${pxToRem(10)};
  gap: ${pxToRem(10)};
  min-height: ${pxToRem(40)};
`;

export const FullWidthWrapperStyled = styled.div`
  width: 100%;
  div {
    max-width: 100%;
  }
`;

export const FilterElementStyled = styled.div`
  ${flexFn()};
  ${baseFilterElementStyle};
  & > div {
    padding: 0;
  }
`;

export const FilterLeftElementStyled = styled.div`
  ${flexFn('flex-start')};
  ${baseFilterElementStyle};

  > div:first-child {
    width: 100%;
  }
`;

export const FilterLabelStyled = styled.span`
  color: ${({ theme }) => (theme as ITeamHeroTheme).colors.greyShades.grey2};
`;
