import { FC, useCallback } from 'react';

import type { ISimpleFilter } from 'hooks/useFilters.hook';
import FilterChip from './FilterChip/FilterChip';
import { FilterChipsStyled } from './FilterChips.styled';
import { IFilterChipsStyled } from './FilterChips.types';

const FilterChips: FC<IFilterChipsStyled> = ({
  filters,
  filterDefinitions,
  onClearFilter,
  chipStyle,
}) => {
  const findFilterDefinition = useCallback(
    (filter: ISimpleFilter) => {
      return filterDefinitions.find(
        (filterDef) =>
          filter.key === filterDef.key ||
          filter.key === filterDef.customApiKey ||
          filter.key === `${filterDef.key}[${filterDef.customProperty}]` ||
          filter.key === filterDef.dateRangePickerData?.keyFrom ||
          filter.key === `${filterDef.dateRangePickerData?.keyFrom}[after]` ||
          filter.key === `${filterDef.dateRangePickerData?.keyTo}[before]` ||
          filter.key === filterDef.dateRangePickerData?.keyTo ||
          filter.key === `${filterDef.key}[after]` ||
          filter.key === `${filterDef.key}[before]` ||
          filter.key === filterDef.rangePickerData?.keyTo ||
          filter.key === filterDef.rangePickerData?.keyFrom
      );
    },
    [filterDefinitions]
  );

  return (
    <FilterChipsStyled data-component='filter-chips'>
      {filters.map((el) => {
        const filterDefinition = findFilterDefinition(el);

        if (filterDefinition) {
          return (
            <FilterChip
              key={`${el.key}-${el.value}`}
              filter={el}
              filterDefinition={filterDefinition}
              onClearFilter={onClearFilter}
              chipStyle={chipStyle}
            />
          );
        }
        return null;
      })}
    </FilterChipsStyled>
  );
};

export default FilterChips;
