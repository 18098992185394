import type { TUserRole } from 'interfaces/Role.interface';
import type { IUserState } from 'redux/user/user.slice.type';

interface ICheckAuthProps {
  user?: IUserState;
  requiredRoles: TUserRole[];
}

/**
 * checks, if the current user has the required user roles
 */
const checkAuth = ({ user, requiredRoles }: ICheckAuthProps): boolean => {
  if (!user) {
    return false;
  }
  const allow = requiredRoles.some((role) => user.roles.includes(role));
  return allow && user.isLoggedIn;
};

export default checkAuth;
