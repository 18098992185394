import {
  EndpointBuilder,
  QueryDefinition,
  BaseQueryFn,
  MutationDefinition,
} from '@reduxjs/toolkit/query';

import type { IContactAvailability } from 'interfaces/ContactAvailability.interface';
import type {
  IContactAvailabilityEntityArgs,
  IGetContactAvailabilitiesResponse,
  IPostContactAvailabilityArgs,
  IPutContactAvailabilityArgs,
} from './contactAvailability.api.types';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';
import type { IId } from 'interfaces/IId.interface';

export const getContactAvailabilities = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IContactAvailabilityEntityArgs,
  BaseQueryFn,
  string,
  IGetContactAvailabilitiesResponse
> =>
  build.query<
    IGetContactAvailabilitiesResponse,
    IContactAvailabilityEntityArgs
  >({
    providesTags: [
      { type: 'ContactAvailability' },
      { type: 'ContactAvailability', id: 'LIST' },
    ],
    query: (queryArg: IContactAvailabilityEntityArgs) => {
      const paginationParamObject = {
        contact: queryArg.contactId,
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);
      return {
        url: `/api/contact_availabilities${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IContactAvailability>
    ): IGetContactAvailabilitiesResponse =>
      transformCollectionResponse<IContactAvailability>(response),
  });

export const getContactAvailability = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<number, BaseQueryFn, string, IContactAvailability> =>
  build.query<IContactAvailability, number>({
    providesTags: ['ContactAvailability'],
    query: (id) => {
      return {
        url: `/api/contact_availabilities/${id}`,
      };
    },
  });

export const postContactAvailability = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostContactAvailabilityArgs,
  BaseQueryFn,
  string,
  IContactAvailability
> =>
  build.mutation<IContactAvailability, IPostContactAvailabilityArgs>({
    invalidatesTags: ['ContactAvailability'],
    query: (queryArg) => ({
      url: '/api/contact_availabilities',
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const putContactAvailability = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPutContactAvailabilityArgs,
  BaseQueryFn,
  string,
  IContactAvailability
> =>
  build.mutation<IContactAvailability, IPutContactAvailabilityArgs>({
    invalidatesTags: ['ContactAvailability'],
    query: (queryArg) => ({
      url: `/api/contact_availabilities/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });

export const deleteContactAvailability = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IId, BaseQueryFn, string, void> =>
  build.mutation<void, IId>({
    invalidatesTags: [{ type: 'ContactAvailability', id: 'LIST' }],
    query: (queryArg) => ({
      url: `api/contact_availabilities/${queryArg.id}`,
      method: 'DELETE',
    }),
  });
