import { useTranslation } from 'react-i18next';

import { useErrorHandler } from 'hooks/useDisplayApiError.hook';
import useNotification from 'hooks/useNotification.hook';
import {
  teamHeroApi,
  usePutOptimisticApplicationShiftItemMutation,
} from 'services/teamHeroApi.service';
import { TShiftApplicationClientStatus } from 'interfaces/ShiftApplication.interface';
import { useAppDispatch } from 'store/hooks';

type TSetApplicationClientStatus = ({
  applicationIds,
  status,
}: {
  applicationIds: number[];
  status: TShiftApplicationClientStatus;
}) => Promise<void>;

interface IUseSetApplicationClientStatusProps {
  missionId: number;
}

interface IUseSetApplicationClientStatusReturn {
  setApplicationClientStatus: TSetApplicationClientStatus;
}

const useSetApplicationClientStatus = ({
  missionId,
}: IUseSetApplicationClientStatusProps): IUseSetApplicationClientStatusReturn => {
  const { t } = useTranslation('general');
  const dispatch = useAppDispatch();
  const { handleError } = useErrorHandler();
  const { set: setNotification } = useNotification();

  const [putApplication] = usePutOptimisticApplicationShiftItemMutation();

  const setApplicationClientStatus: TSetApplicationClientStatus = async ({
    applicationIds,
    status,
  }) => {
    Promise.all(
      applicationIds.map((applicationId) =>
        putApplication({
          id: applicationId,
          missionId: missionId,
          body: {
            clientStatus: status,
          },
          skipInvalidateTags: true,
        }).unwrap()
      )
    )
      .then(() => {
        setNotification({
          message: t('action.applicationStatusUpdated'),
          type: 'success',
        });
      })
      .catch((error) => {
        handleError(error, 'action.applicationStatusFailure');
      })
      .finally(() => {
        dispatch(teamHeroApi.util.invalidateTags(['ShiftApplication']));
      });
  };
  return {
    setApplicationClientStatus,
  };
};

export default useSetApplicationClientStatus;
