import { TFunction } from 'i18next';

import type { IContact } from 'interfaces/Contact.interface';
import { IColumnDefinitionType } from 'interfaces/Table/DataTableColumn.interface';
import type { IProjectShiftWorkplanner } from 'interfaces/ProjectShift.interface';
import { IProject } from 'interfaces/Project.interface';
import type { IProjectMission } from 'interfaces/ProjectMission.interface';

export interface IProjectShiftWorkplannerWithMission
  extends IProjectShiftWorkplanner {
  mission?: IProjectMission;
}

export interface IShiftExportRowData {
  contact?: IContact;
  shift: IProjectShiftWorkplannerWithMission;
  project?: IProject;
}

interface IShiftsExportColumnsProps {
  t: TFunction;
  enableClientContacts: boolean;
}

const getDisplayValue = (value: unknown) => {
  if (value === null || value === undefined) {
    return '';
  }
  return value;
};

export const shiftsExportColumns = ({
  enableClientContacts,
}: IShiftsExportColumnsProps): IColumnDefinitionType<IShiftExportRowData>[] => {
  return [
    {
      key: 'gender',
      label: 'gender',
      visibility: true,
      cellType: 'custom',
      displayInExport: enableClientContacts,
      cellExportHandler: (row) => getDisplayValue(row.contact?.gender),
    },
    {
      key: 'firstName',
      label: 'firstName',
      visibility: true,
      cellType: 'custom',
      displayInExport: enableClientContacts,
      cellExportHandler: (row) => getDisplayValue(row.contact?.firstName),
    },
    {
      key: 'lastName',
      label: 'lastName',
      visibility: true,
      cellType: 'custom',
      displayInExport: enableClientContacts,
      cellExportHandler: (row) => getDisplayValue(row.contact?.lastName),
    },
    {
      key: 'title',
      label: 'title',
      visibility: true,
      cellType: 'custom',
      displayInExport: enableClientContacts,
      cellExportHandler: (row) => getDisplayValue(row.contact?.title),
    },
    {
      key: 'projectNumber',
      label: 'projectNumber',
      visibility: true,
      cellType: 'custom',
      cellExportHandler: (row) => getDisplayValue(row.project?.projectNumber),
    },
    {
      key: 'projectTitle',
      label: 'projectTitle',
      visibility: true,
      cellType: 'custom',
      cellExportHandler: (row) => getDisplayValue(row.project?.title),
    },
    {
      key: 'missionTitle',
      label: 'missionTitle',
      visibility: true,
      cellType: 'custom',
      cellExportHandler: (row) => getDisplayValue(row.shift?.mission?.title),
    },
    {
      key: 'orderNumber',
      label: 'orderNumber',
      visibility: true,
      cellType: 'custom',
      cellExportHandler: (row) =>
        getDisplayValue(row.shift?.mission?.orderNumber),
    },
    {
      key: 'addressStreet',
      label: 'addressStreet',
      visibility: true,
      cellType: 'custom',
      cellExportHandler: (row) =>
        getDisplayValue(row.shift?.mission?.addressStreet),
    },
    {
      key: 'addressZip',
      label: 'addressZip',
      visibility: true,
      cellType: 'custom',
      cellExportHandler: (row) =>
        getDisplayValue(row.shift?.mission?.addressZip),
    },
    {
      key: 'addressCity',
      label: 'addressCity',
      visibility: true,
      cellType: 'custom',
      cellExportHandler: (row) =>
        getDisplayValue(row.shift?.mission?.addressCity),
    },
    {
      key: 'addressCountry',
      label: 'addressCountry',
      visibility: true,
      cellType: 'custom',
      cellExportHandler: (row) =>
        getDisplayValue(row.shift?.mission?.addressCountry),
    },
    {
      key: 'leader',
      label: 'leader',
      visibility: true,
      cellType: 'custom',
      cellExportHandler: (row) => {
        const leader = row.shift?.mission?.leader;
        if (!leader) {
          return '';
        }
        return `${leader.firstName} ${leader.lastName}`;
      },
    },
    {
      key: 'start',
      label: 'start',
      visibility: true,
      cellType: 'custom',
      cellExportHandler: (row) =>
        row.shift.start ? new Date(row.shift.start) : '-',
    },
    {
      key: 'end',
      label: 'end',
      visibility: true,
      cellType: 'custom',
      cellExportHandler: (row) =>
        row.shift.end ? new Date(row.shift.end) : '-',
    },
    {
      key: 'position',
      label: 'position',
      visibility: true,
      cellType: 'custom',
      cellExportHandler: (row) => getDisplayValue(row.shift.position),
    },
    {
      key: 'area',
      label: 'area',
      visibility: true,
      cellType: 'custom',
      cellExportHandler: (row) => getDisplayValue(row.shift.area),
    },
  ];
};
