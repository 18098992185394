import { uniqBy } from 'lodash';

import { IShiftApplication } from 'interfaces/ShiftApplication.interface';
import type { IApplicationGroup } from '../MissionApplicationsTab.types';
import { IProjectShift } from 'interfaces/ProjectShift.interface';
import { getShiftIdFromIriOrEmbed } from 'helpers/shifts/getShiftIdFromIriOrEmbed.helper';

export const getGroupedApplications = async (
  applications: IShiftApplication[],
  getShift: (id: number) => Promise<IProjectShift>
): Promise<IApplicationGroup[]> => {
  const result: IApplicationGroup[] = [];

  const allShiftIds = applications.map((application) =>
    getShiftIdFromIriOrEmbed(application.shift)
  );
  // filter out undefined values
  const filteredShiftIds = allShiftIds.filter((el) => !!el) as number[];
  // get unique shift ids
  const uniqueShiftIds = [...new Set(filteredShiftIds)];
  // fetch all shifts data
  const shiftsData = await Promise.all(
    uniqueShiftIds.map((id) => getShift(id))
  );

  applications.forEach((application) => {
    const currentShift = shiftsData.find(
      (el) => el.id === getShiftIdFromIriOrEmbed(application.shift)
    );

    if (!currentShift) {
      return;
    }
    const key = `${currentShift.position}-${currentShift.area}`;

    const existingData = result.find((el) => el.id === key);

    const isOpenApplication = application.clientStatus === 'open';

    if (existingData) {
      existingData.applications.push(application);
      if (isOpenApplication) {
        existingData.openApplications.push(application);
      }
    } else {
      const data = {
        id: key,
        position: currentShift.position,
        area: currentShift.area,
        applications: [application],
        openApplications: isOpenApplication ? [application] : [],
      };

      result.push(data);
    }
  });

  return result;
};

export const getApplicationIdsWithSameContact = (
  applications: IShiftApplication[],
  applicationId: number
): number[] => {
  const application = applications.find((el) => el.id === applicationId);

  if (!application) {
    return [];
  }

  const contactId = application.contact.id;

  const result = applications
    .filter((el) => el.contact.id === contactId)
    .map((el) => el.id);

  return result;
};

export const getUniqueApplications = (applications: IShiftApplication[]) =>
  uniqBy(applications, 'contact.id');
