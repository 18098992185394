import {
  BaseQueryFn,
  MutationDefinition,
  QueryDefinition,
  EndpointBuilder,
} from '@reduxjs/toolkit/query';

import type { IGallery } from 'interfaces/Gallery.inteface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import type {
  ICreateGalleryArgs,
  IGetGalleriesCollectionArgs,
  IGetGalleriesCollectionResponse,
  IGetGalleryArgs,
} from './gallery.api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';

export const getGalleries = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetGalleriesCollectionArgs,
  BaseQueryFn,
  string,
  IGetGalleriesCollectionResponse
> =>
  build.query({
    providesTags: ['Gallery'],
    query: (queryArg: IGetGalleriesCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);
      return `/api/galleries${paginationQuery}${filterQuery}`;
    },
    transformResponse: (response: ICollectionApiResponse<IGallery>) =>
      transformCollectionResponse<IGallery>(response),
  });

export const getGalleryEntity = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<IGetGalleryArgs, BaseQueryFn, string, IGallery> =>
  build.query({
    providesTags: ['Gallery'],
    query: (queryArg: IGetGalleryArgs) => ({
      url: `/api/galleries/${queryArg.id}`,
    }),
  });

export const getPublicGalleryEntity = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<IGetGalleryArgs, BaseQueryFn, string, IGallery> =>
  build.query({
    providesTags: ['Gallery'],
    query: (queryArg: IGetGalleryArgs) => ({
      url: `/api/public_galleries/${queryArg.id}`,
    }),
  });

export const createGallery = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<ICreateGalleryArgs, BaseQueryFn, string, IGallery> =>
  build.mutation<IGallery, ICreateGalleryArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['Gallery'],
    query: (queryArg) => ({
      url: `api/galleries`,
      method: 'POST',
      body: {
        title: queryArg.title,
        mission: queryArg.mission,
      },
    }),
  });
