import {
  BaseQueryFn,
  QueryDefinition,
  MutationDefinition,
  EndpointBuilder,
} from '@reduxjs/toolkit/query';

import type { IRatedCategory } from 'interfaces/RatedCategory.interface';
import type { IRatingCategory } from 'interfaces/RatingCategory.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';
import type {
  IGetRatedCategoryItemArgs,
  IGetRatingCategoriesCollectionArgs,
  IGetRatingCategoriesCollectionResponse,
  IPostRatedCategoriesArgs,
  IPutRatedCategoriesArgs,
} from './RatingCategories.api.types';

export const getRatingCategories = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetRatingCategoriesCollectionArgs,
  BaseQueryFn,
  string,
  IGetRatingCategoriesCollectionResponse
> =>
  build.query<
    IGetRatingCategoriesCollectionResponse,
    IGetRatingCategoriesCollectionArgs
  >({
    providesTags: ['RatingCategory'],
    query: (queryArg: IGetRatingCategoriesCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);
      return `/api/rating_categories${paginationQuery}${filterQuery}`;
    },
    transformResponse: (response: ICollectionApiResponse<IRatingCategory>) =>
      transformCollectionResponse<IRatingCategory>(response),
  });

export const getRatedCategory = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetRatedCategoryItemArgs,
  BaseQueryFn,
  string,
  IRatedCategory
> =>
  build.query<IRatedCategory, IGetRatedCategoryItemArgs>({
    providesTags: ['RatingCategory'],
    query: (queryArg: IGetRatedCategoryItemArgs) => ({
      url: `api/rated_categories/${queryArg.id}`,
    }),
  });

export const postRatedCategories = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostRatedCategoriesArgs,
  BaseQueryFn,
  string,
  IRatingCategory
> =>
  build.mutation({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['RatingCategory'],
    query: (queryArg: IPostRatedCategoriesArgs) => ({
      url: '/api/rated_categories',
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const putRatedCategory = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPutRatedCategoriesArgs,
  BaseQueryFn,
  string,
  IRatingCategory
> =>
  build.mutation<IRatingCategory, IPutRatedCategoriesArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['RatingCategory'],
    query: (queryArg) => ({
      url: `/api/rated_categories/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });
