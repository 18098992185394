import { ChangeEvent, ReactNode, useCallback, useEffect } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import { ITableSelector } from 'interfaces/Table/selector.interface';
import { IColumnDefinitionType } from 'interfaces/Table/DataTableColumn.interface';
import { TRootState } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export interface IMenuElement {
  key: string;
  label: ReactNode;
  visibility: boolean;
  displayInMenu: boolean;
}

const moveArray = (
  array: IMenuElement[],
  fromIndex: number,
  toIndex: number
): IMenuElement[] => {
  const newArray = cloneDeep(array);
  const element = newArray[fromIndex];
  newArray.splice(fromIndex, 1);
  newArray.splice(toIndex, 0, element);
  return newArray;
};

const sortByMenuList = <T>(
  menuList: IMenuElement[],
  columns: IColumnDefinitionType<T>[]
): IColumnDefinitionType<T>[] => {
  const menuKeys: string[] = menuList.map((menuItem) => menuItem.key);

  return columns.sort((columnA, columnB) => {
    if (menuKeys.indexOf(columnA.key) < menuKeys.indexOf(columnB.key)) {
      return -1;
    }
    if (menuKeys.indexOf(columnA.key) > menuKeys.indexOf(columnB.key)) {
      return 1;
    }
    return 0;
  });
};

interface IUseColumnListReturnType<T> {
  menuColumnList: IMenuElement[];
  tableColumns: IColumnDefinitionType<T>[];
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleOnAllClick: () => void;
  handleOnDragEnd: (res: DropResult) => void;
}

export type TDataTableColumnListReduxSlice = ActionCreatorWithPayload<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  `${string}/${string}`
>;

interface IUseColumnListProps<T> {
  columns: IColumnDefinitionType<T>[];
  columnsOffset?: number;
  setColumnListDispatch: TDataTableColumnListReduxSlice;
  paginationSelector: (state: TRootState) => ITableSelector;
}

export const useColumnList = <T>({
  columns,
  columnsOffset = 0,
  setColumnListDispatch,
  paginationSelector,
}: IUseColumnListProps<T>): IUseColumnListReturnType<T> => {
  const dispatch = useAppDispatch();
  const { menuColumnList, initialMenuColumnsSet } =
    useAppSelector(paginationSelector);

  const setInitialColumns = useCallback(() => {
    if (!initialMenuColumnsSet) {
      const menuColumns: IMenuElement[] = columns.map(
        ({ key, label, visibility, displayInMenu }) => {
          return {
            key,
            label,
            visibility,
            displayInMenu: displayInMenu || false,
          };
        }
      );
      dispatch(setColumnListDispatch(menuColumns));
    }
  }, [columns, dispatch, initialMenuColumnsSet, setColumnListDispatch]);

  useEffect(() => {
    setInitialColumns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newColumns = cloneDeep(menuColumnList);
      const updatedItem = newColumns.find((i) => i.key === event.target.name);
      if (updatedItem) {
        updatedItem.visibility = !updatedItem.visibility;
      }
      dispatch(setColumnListDispatch(newColumns));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, menuColumnList, setColumnListDispatch]
  );

  const handleOnDragEnd = useCallback(
    ({ draggableId, destination }: DropResult) => {
      const index = menuColumnList.findIndex(
        (element) => element.key === draggableId
      );
      const newPosition = destination?.index ?? 0;
      const newItems = moveArray(menuColumnList, index, newPosition);
      dispatch(setColumnListDispatch(newItems));
    },
    [dispatch, menuColumnList, setColumnListDispatch]
  );

  const handleOnAllClick = useCallback(() => {
    const newItems = menuColumnList.map((item) => {
      return {
        ...item,
        visibility: true,
      };
    });
    dispatch(setColumnListDispatch(newItems));
  }, [dispatch, menuColumnList, setColumnListDispatch]);

  const transformedTableColumns = useCallback<
    () => IColumnDefinitionType<T>[]
  >(() => {
    const tableColumns = columns.map((column, index) => {
      if (index < columnsOffset) {
        return column;
      }
      return {
        ...column,
        visibility:
          menuColumnList.find((menuColumn) => menuColumn.key === column.key)
            ?.visibility || false,
      };
    });
    return sortByMenuList<T>(menuColumnList, tableColumns);
  }, [columns, columnsOffset, menuColumnList]);

  return {
    menuColumnList,
    tableColumns: transformedTableColumns(),
    handleChange,
    handleOnAllClick,
    handleOnDragEnd,
  };
};
