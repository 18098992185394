import type {
  ICollection,
  ICollectionApiResponse,
} from 'services/api/api.types';

export const transformCollectionResponse = <T>(
  response: ICollectionApiResponse<T>
): ICollection<T> => {
  const defaultTotalCount = 0;
  const defaultItems: T[] = [];
  const totalCount = response['hydra:totalItems'] || defaultTotalCount;
  const items = response['hydra:member'] || defaultItems;
  const view = response['hydra:view'];
  const nextPage = view ? view['hydra:next'] : undefined;
  const prevPage = view ? view['hydra:previous'] : undefined;
  return { items, totalCount, nextPage, prevPage };
};
