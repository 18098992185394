import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { NoInfer } from 'react-redux';

import type { IMenuElement } from 'components/TableView/hooks/useColumnList.hook';
import type { ITableSelector } from 'interfaces/Table/selector.interface';
import type { ISorting } from 'components/TableView/hooks/useSort.hook';
import type { ISimpleFilter } from 'hooks/useFilters.hook';

export const createTableSelectorSlice = (
  name: string,
  currentPageDefault: number,
  itemsPerPageDefault: number,
  defaultSorting?: ISorting,
  extraReducers?: (
    builder: ActionReducerMapBuilder<NoInfer<ITableSelector>>
  ) => void,
  initialFiltersSet = false
) => {
  const initialState: ITableSelector = {
    totalCount: 1,
    pagination: {
      currentPage: currentPageDefault,
      itemsPerPage: itemsPerPageDefault,
    },
    defaultSorting: defaultSorting,
    sorting: defaultSorting || {
      columnKey: null,
      direction: 'asc',
    },
    filters: [],
    initialFiltersSet,
    menuColumnList: [],
    initialMenuColumnsSet: false,
    nestedFilters: {},
    nestedSorting: {},
  };

  return createSlice({
    name,
    initialState,
    reducers: {
      setPagination: (
        state: ITableSelector,
        action: PayloadAction<Omit<ITableSelector, 'sorting'>>
      ) => {
        state.pagination = action.payload.pagination;
      },
      setNextPage: (state: ITableSelector, action: PayloadAction<number>) => {
        state.pagination.currentPage = action.payload;
      },
      setItemsPerPage: (
        state: ITableSelector,
        action: PayloadAction<number>
      ) => {
        if (state.pagination.itemsPerPage !== action.payload) {
          state.pagination.currentPage = currentPageDefault;
        }
        state.pagination.itemsPerPage = action.payload;
      },
      setTotalCount: (state: ITableSelector, action: PayloadAction<number>) => {
        state.totalCount = action.payload;
      },
      setSorting: (state: ITableSelector, action: PayloadAction<ISorting>) => {
        state.sorting = action.payload;
      },
      setFilters: (
        state: ITableSelector,
        action: PayloadAction<ISimpleFilter[]>
      ) => {
        state.filters = action.payload;
        state.initialFiltersSet = true;
      },
      setInitialFiltersSet: (
        state: ITableSelector,
        action: PayloadAction<boolean>
      ) => {
        state.initialFiltersSet = action.payload;
      },
      setMenuColumnList: (
        state: ITableSelector,
        action: PayloadAction<IMenuElement[]>
      ) => {
        state.menuColumnList = action.payload;
        state.initialMenuColumnsSet = true;
      },
    },
    extraReducers,
  });
};
