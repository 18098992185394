import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

export const HeaderWrapperStyled = styled.div`
  ${flexFn('space-between', 'center', 'row')}
  padding: ${pxToRem([0, 20])};
  width: 100%;
  flex-wrap: wrap;
  grid-area: header;
  min-height: ${pxToRem(40)};
`;

export const HeaderNavigationWrapperStyled = styled(HeaderWrapperStyled)`
  padding: ${pxToRem([20, 20])};
  ${flexFn('flex-start', 'start', 'column')};
  gap: ${pxToRem(12)};
  grid-area: header;
`;

export const HeaderSectionStyled = styled.div`
  ${flexFn()}
  gap: ${pxToRem(12)};
  width: auto;
`;

export const HeaderSectionAdditionalRowStyled = styled.div`
  ${flexFn('flex-start', 'center', 'row')}
  width: 100%;
`;
