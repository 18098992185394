import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiSelect, type IMultiselectElement, Loader } from 'team-hero-ui';

import type { IMultiSelectRendererProps } from 'components/FilterPanel/FilterPanel.type';
import { useGetSelectExternalOptionValues } from 'hooks/getSelectExternalOptionValues/getSelectExternalOptionValues.hook';

export const MultiSelectRenderer: FC<IMultiSelectRendererProps> = ({
  label,
  staticValues,
  value,
  onChange,
  styleType,
  externalValuesType,
}) => {
  const { t } = useTranslation('general');
  const [selectItems, setSelectItems] = useState<IMultiselectElement[]>([]);
  const selectLabel = t('selectValue', { filterLabel: label });

  const { data: externalSelectValues, isLoading } =
    useGetSelectExternalOptionValues(externalValuesType, !externalValuesType);

  const setSelectItemsFromExternalValues = useCallback(() => {
    const initialMultiSelectValues: IMultiselectElement[] =
      externalSelectValues.map((externalValue) => {
        return {
          value: externalValue.value,
          name: externalValue.label,
          checked: value?.includes(externalValue.value) || false,
        };
      });
    setSelectItems(initialMultiSelectValues);
  }, [externalSelectValues, value]);

  const setSelectItemsFromStaticValues = useCallback(() => {
    const initialMultiSelectValues: IMultiselectElement[] =
      staticValues?.map((staticValue) => {
        return {
          value: staticValue.value,
          name: staticValue.label,
          checked: value?.includes(staticValue.value) || false,
        };
      }) || [];
    setSelectItems(initialMultiSelectValues);
  }, [staticValues, value]);

  useEffect(() => {
    if (externalValuesType) {
      setSelectItemsFromExternalValues();
    } else if (staticValues) {
      setSelectItemsFromStaticValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, value, staticValues]);

  const onChangeHandler = useCallback(
    (updatedItems: IMultiselectElement[]) => {
      setSelectItems(updatedItems);
      const checkedItems = updatedItems
        .filter((item) => item.checked)
        .map((item) => item.value);
      onChange(checkedItems);
    },
    [onChange]
  );

  return isLoading ? (
    <Loader loaderSize='tiny' />
  ) : (
    <MultiSelect
      inputLabel={selectLabel}
      items={selectItems}
      styleType={styleType === 'modal' ? styleType : 'filter'}
      onChange={onChangeHandler}
    />
  );
};
