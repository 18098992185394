import { MouseEvent as ReactMouseEvent } from 'react';

export const preventEventWrapper = (
  event: ReactMouseEvent<HTMLElement, MouseEvent>,
  callback?: () => void
): void => {
  event.stopPropagation();
  if (callback) {
    callback();
  }
};

export const preventEditEventWrapper = (
  event: ReactMouseEvent<HTMLDivElement, MouseEvent>,
  callback?: () => void
): void => {
  event.stopPropagation();
  if (callback) {
    callback();
  }
};
