import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { TRootState } from 'store';
import type { ISearchViewState } from './search.slice.type';

export const initialState: ISearchViewState = {
  isOpen: false,
  hasBeenOpened: false,
  initialSearch: '',
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    openSearch: (
      state: ISearchViewState,
      {
        payload: { hasBeenOpened, isOpen, initialSearch },
      }: PayloadAction<ISearchViewState>
    ) => {
      state.isOpen = isOpen;
      state.hasBeenOpened = hasBeenOpened;
      state.initialSearch = initialSearch;
    },
    closeSearch: (state: ISearchViewState) => {
      state.isOpen = false;
    },
    resetHasBeenOpened: (state: ISearchViewState) => {
      state.hasBeenOpened = initialState.hasBeenOpened;
    },
  },
});

export const { openSearch, closeSearch, resetHasBeenOpened } =
  searchSlice.actions;

export const searchSelector = (state: TRootState): ISearchViewState =>
  state.search;

export default searchSlice.reducer;
