import { FC, ReactNode } from 'react';
import { Theme } from 'team-hero-ui';

import {
  BackgroundContainerStyled,
  GrayedCellRendererStyled,
} from './GrayedCellRenderer.styled';

export interface IGrayedCellRendererProps {
  children?: ReactNode;
  bgColor?: string;
  secondBgColor?: string;
  color?: string;
  height?: number;
  separatorRight?: number;
  withoutLeftPadding?: boolean;
  roundedCornersLeft?: boolean;
  roundedCornersRight?: boolean;
  bold?: boolean;
  withTextOverflow?: boolean;
}

export const GrayedCellRenderer: FC<IGrayedCellRendererProps> = ({
  bgColor = Theme.colors.greyShades.grey5,
  secondBgColor,
  color,
  withoutLeftPadding,
  roundedCornersLeft,
  roundedCornersRight,
  children,
  height,
  separatorRight = 0,
  bold = false,
  withTextOverflow = false,
}) => {
  return (
    <BackgroundContainerStyled
      $bgColor={secondBgColor}
      $separatorRight={separatorRight}
    >
      <GrayedCellRendererStyled
        $bgColor={bgColor}
        $height={height}
        $color={color}
        $bold={bold}
        $withoutLeftPadding={withoutLeftPadding}
        $roundedCornersLeft={roundedCornersLeft}
        $roundedCornersRight={roundedCornersRight}
        $withTextOverflow={withTextOverflow}
      >
        {children}
      </GrayedCellRendererStyled>
    </BackgroundContainerStyled>
  );
};
