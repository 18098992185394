import { RefObject, useCallback, useEffect, useMemo, useRef } from 'react';

import { doesIdentifierMatchKeyboardEvent } from 'helpers/keys/keys.helper';

type TKeyEvent = 'keydown' | 'keyup' | 'keypress';

const useKey = (
  input: Array<number | string> | number | string,
  callback: (e: KeyboardEvent) => unknown,
  enabled = true,
  eventTypes: TKeyEvent[] = ['keydown'],
  target?: RefObject<HTMLElement>
): void => {
  const keyList: Array<number | string> = useMemo(() => {
    if (Array.isArray(input)) {
      return input;
    } else {
      return [input];
    }
  }, [input]);
  const callbackRef = useRef<(e: KeyboardEvent) => unknown>(callback);

  useEffect(() => {
    callbackRef.current = callback;
  });

  const handle = useCallback(
    (e: KeyboardEvent) => {
      if (
        keyList.some((identifier) =>
          doesIdentifierMatchKeyboardEvent(e, identifier)
        )
      ) {
        callbackRef.current(e);
      }
    },
    [keyList]
  );

  useEffect(() => {
    if (enabled && typeof window !== 'undefined') {
      const targetNode = target ? target.current : window;
      eventTypes.forEach((eventType) => {
        targetNode?.addEventListener(eventType, handle as EventListener);
      });

      return () => {
        eventTypes.forEach((eventType) => {
          targetNode?.removeEventListener(eventType, handle as EventListener);
        });
      };
    }
  }, [enabled, eventTypes, keyList, target, callback, handle]);
};

export default useKey;
