import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

export const SettingWrapperStyled = styled.span`
  ${flexFn('center', 'center', 'row')}
  gap: ${pxToRem(4)};
`;

export const SettingDescriptionStyled = styled.span`
  font-size: ${pxToRem(11)};
  color: ${({ theme }) => theme.colors.greyShades.grey2};
`;
