import { FC, useCallback, useEffect, useRef } from 'react';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Icons, Theme } from 'team-hero-ui';

import useKey from 'hooks/keys/useKey.hook';
import {
  FilterLabelStyled,
  FilterPanelLabelStyled,
  FilterPanelVisibilityWrapper,
} from './FilterPanel.styled';
import { IFilterPanelProps } from './FilterPanel.type';
import { transformNestedValuesToDispatchValues } from './helpers/transformValues.helper';
import { useInitialFilterValues } from './hooks/useInitialFilterValues.hook';
import FilterInputs from './filterRenderers/FilterInputs';

export const FilterPanel: FC<IFilterPanelProps> = ({
  filterDefinitionList,
  filterData,
  hidden,
  styleType,
  filterTopSection,
  maxHeight = '100%',
  customFilterHeader,
  submitCallback,
}) => {
  const { t } = useTranslation('general');
  const { handleSubmit, control, reset } = useForm();
  const { initialValues, initialValuesObject, initialValuesResetObject } =
    useInitialFilterValues(
      filterDefinitionList,
      filterData.filters,
      filterData.initialFiltersSet
    );

  const handleClearFilters = () => {
    filterData.onClearFilters();
    reset(initialValuesResetObject);
  };

  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (nestedValues, e) => {
      e?.preventDefault();

      filterData.onSetFilters(
        transformNestedValuesToDispatchValues(
          nestedValues,
          filterDefinitionList
        )
      );
    },
    [filterData, filterDefinitionList]
  );

  const setupInitialValuesInForm = useCallback(() => {
    reset(initialValuesObject);
    handleSubmit(onSubmit)();
  }, [reset, handleSubmit, onSubmit, initialValuesObject]);

  useEffect(() => {
    if (initialValues.length) {
      setupInitialValuesInForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const enterContainerRef = useRef<HTMLDivElement>(null);

  useKey(
    'Enter',
    () => handleSubmit(onSubmit)(),
    !hidden,
    ['keypress'],
    enterContainerRef
  );

  return (
    <FilterPanelVisibilityWrapper
      $hidden={hidden}
      $styleType={styleType}
      ref={enterContainerRef}
      data-test-id='filter-panel-wrapper'
    >
      {filterTopSection}
      {customFilterHeader ? (
        <>{customFilterHeader}</>
      ) : (
        <FilterPanelLabelStyled>
          <Icons.FilterIcon
            svgColor={Theme.colors.greyShades.grey2}
            svgSize={20}
          />
          <FilterLabelStyled>{t('filters.filter')}</FilterLabelStyled>
        </FilterPanelLabelStyled>
      )}
      <FilterInputs
        control={control}
        filterDefinitionList={filterDefinitionList}
        handleClearFilters={handleClearFilters}
        handleSubmit={handleSubmit(onSubmit)}
        displayType='panel'
        maxHeight={maxHeight}
        styleType={styleType}
        additionalSubmitCallback={submitCallback}
      />
    </FilterPanelVisibilityWrapper>
  );
};
