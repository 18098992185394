import { FC } from 'react';
import { IconButton, Icons, SwitchDescriptive } from 'team-hero-ui';
import { useTranslation } from 'react-i18next';

import {
  SettingDescriptionStyled,
  SettingWrapperStyled,
} from './HeadbarSettings.styled';
import { IHeadbarSettingsProps } from './HeadbarSettings.type';

const HeadbarSettings: FC<IHeadbarSettingsProps> = ({
  groupToggle,
  showSummedShifts,
  toggleSummedShifts,
}) => {
  const { t } = useTranslation('general');
  return (
    <>
      <SettingWrapperStyled data-test-id='group-toggle-wrapper'>
        <IconButton onClick={groupToggle} data-test-id='group-toggle'>
          <Icons.SettingsIcon svgSize={15} svgColor='currentColor' />
        </IconButton>
        <SettingDescriptionStyled>
          {t('workplanner.timeline.headbar.left.grouping')}
        </SettingDescriptionStyled>
      </SettingWrapperStyled>
      <SettingWrapperStyled data-test-id='toggle-summing-shifts'>
        <SwitchDescriptive
          size='small'
          checked={showSummedShifts}
          onChange={toggleSummedShifts}
          styleType={'bright'}
          fontSize={11}
          label={t('workplanner.timeline.headbar.left.summing')}
          dataTestId={'workplanner-summing-switch'}
        />
      </SettingWrapperStyled>
    </>
  );
};

export default HeadbarSettings;
