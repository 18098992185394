import {
  EndpointBuilder,
  MutationDefinition,
  QueryDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type { IAbsenceFileResource } from 'interfaces/File.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';
import type {
  IAbsenceFileEntityArgs,
  IPostAbsenceFileEntryArgs,
  IDeleteAbsenceFileArgs,
  IPostAbsenceFileArgs,
  IPutAbsenceFileEntryArgs,
  IGetAbsenceFilesResponse,
  IGetAbsenceFilesArgs,
} from './absenceFiles.api.types';

export const getAbsenceFilesEntity = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetAbsenceFilesArgs,
  BaseQueryFn,
  string,
  IGetAbsenceFilesResponse
> =>
  build.query<IGetAbsenceFilesResponse, IGetAbsenceFilesArgs>({
    providesTags: ['AbsenceFile'],
    query: (queryArg) => {
      const paginationParamObject = {
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);

      return {
        url: `/api/out_of_office_files${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IAbsenceFileResource>
    ): IGetAbsenceFilesResponse =>
      transformCollectionResponse<IAbsenceFileResource>(response),
  });

export const getAbsenceFileEntity = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IAbsenceFileEntityArgs,
  BaseQueryFn,
  string,
  IAbsenceFileResource
> =>
  build.query<IAbsenceFileResource, IAbsenceFileEntityArgs>({
    providesTags: ['AbsenceFile'],
    query: (queryArg: IAbsenceFileEntityArgs) => ({
      url: `/api/out_of_office_files/${queryArg.id}`,
    }),
  });

export const postAbsenceFileEntityItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostAbsenceFileEntryArgs,
  BaseQueryFn,
  string,
  IAbsenceFileResource
> =>
  build.mutation<IAbsenceFileResource, IPostAbsenceFileEntryArgs>({
    query: (queryArg) => ({
      url: `api/out_of_office_files`,
      method: 'POST',
      body: queryArg,
    }),
  });

export const putAbsenceFileEntityItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPutAbsenceFileEntryArgs,
  BaseQueryFn,
  string,
  IAbsenceFileResource
> =>
  build.mutation<IAbsenceFileResource, IPutAbsenceFileEntryArgs>({
    query: (queryArg) => ({
      url: `api/out_of_office_files/${queryArg.id}`,
      method: 'PUT',
      body: queryArg,
    }),
  });

export const deleteAbsenceFileItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IDeleteAbsenceFileArgs, BaseQueryFn, string, unknown> =>
  build.mutation<unknown, IDeleteAbsenceFileArgs>({
    query: (queryArg) => ({
      url: `/api/out_of_office_files/${queryArg.id}`,
      method: 'DELETE',
    }),
  });

export const postAbsenceFileItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostAbsenceFileArgs,
  BaseQueryFn,
  string,
  IAbsenceFileResource
> =>
  build.mutation<IAbsenceFileResource, IPostAbsenceFileArgs>({
    query: (queryArg) => ({
      url: `api/out_of_office_files/${queryArg.id}/file`,
      method: 'POST',
      body: queryArg.body,
      extraOptions: { maxRetries: 0 },
    }),
  });
