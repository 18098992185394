import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

const { flexFn } = Utils;

export const ConversationStyled = styled.div`
  ${flexFn('flex-end', 'stretch', 'column')};
  background-color: ${Theme.colors.primary.white};
  height: 100%;
  flex-grow: 1;
`;
