import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

export const MissionApplicationsStyled = styled.div`
  ${flexFn('flex-start', 'stretch')};
  padding: ${pxToRem(24)};
  overflow-y: auto;
  height: calc(100dvh - ${pxToRem(108)});
`;
