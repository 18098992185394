import { createApi, retry } from '@reduxjs/toolkit/query/react';

import { noAuthCustomBaseQueryFn } from './customBaseQuery/customBaseQuery';
import { getPublicSystemSettings } from 'services/systemSettings/systemSettings.api';
import { getPublicGalleryEntity } from 'services/gallery/gallery.api';
import { getPublicGalleryImages } from 'services/galleryImages/galleryImages.api';
import { postPublicApplicationItem } from 'services/applications/applications.api';
import {
  postApplicationImagePublicEntityItem,
  postApplicationImagePublicItem,
} from 'services/applicationImages/applicationImages.api';
import { getPublicApplicationJobAds } from 'services/jobAds/jobAds.api';
import { postPublicApplicationDocumentItem } from 'services/applicationDocuments/applicationDocuments.api';
import {
  postPublicApplicationDocumentFileEntityItem,
  postPublicApplicationDocumentFileItem,
} from 'services/applicationDocumentsFiles/applicationDocumentFiles.api';
import { getMagicLoginCredentials } from 'services/user/user.api';

export const publicTeamHeroApi = createApi({
  baseQuery: retry(noAuthCustomBaseQueryFn, { maxRetries: 3 }),
  tagTypes: [
    'PublicSystemSetting',
    'Application',
    'ApplicationImage',
    'ApplicationDocument',
    'ApplicationDocumentFile',
    'Gallery',
    'GalleryImage',
    'JobAd',
  ],
  reducerPath: 'publicTeamHeroApi',
  endpoints: (build) => ({
    getMagicLoginCredentials: getMagicLoginCredentials(build),
    getPublicApplicationJobAds: getPublicApplicationJobAds(build),
    getPublicSystemSettings: getPublicSystemSettings(build),
    getPublicGalleryEntity: getPublicGalleryEntity(build),
    getPublicGalleryImages: getPublicGalleryImages(build),
    postPublicApplicationItem: postPublicApplicationItem(build),
    postApplicationImagePublicEntityItem:
      postApplicationImagePublicEntityItem(build),
    postApplicationImagePublicItem: postApplicationImagePublicItem(build),
    postPublicApplicationDocumentItem: postPublicApplicationDocumentItem(build),
    postPublicApplicationDocumentFileEntityItem:
      postPublicApplicationDocumentFileEntityItem(build),
    postPublicApplicationDocumentFileItem:
      postPublicApplicationDocumentFileItem(build),
  }),
});

export const {
  useGetMagicLoginCredentialsQuery,
  useLazyGetMagicLoginCredentialsQuery,
  useGetPublicApplicationJobAdsQuery,
  useGetPublicSystemSettingsQuery,
  useLazyGetPublicSystemSettingsQuery,
  useGetPublicGalleryEntityQuery,
  useGetPublicGalleryImagesQuery,
  usePostPublicApplicationItemMutation,
  usePostApplicationImagePublicEntityItemMutation,
  usePostApplicationImagePublicItemMutation,
  usePostPublicApplicationDocumentItemMutation,
  usePostPublicApplicationDocumentFileItemMutation,
  usePostPublicApplicationDocumentFileEntityItemMutation,
} = publicTeamHeroApi;
