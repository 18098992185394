import { useCallback, useState } from 'react';

export interface IUseToggleReturn {
  toggleValue: boolean;
  on: () => void;
  off: () => void;
  toggle: () => void;
}

export const useToggle = (initialValue?: boolean): IUseToggleReturn => {
  const [value, setValue] = useState(initialValue || false);

  const on = useCallback(() => {
    setValue(true);
  }, []);

  const off = useCallback(() => {
    setValue(false);
  }, []);

  const toggle = useCallback(() => {
    setValue((prev) => !prev);
  }, []);

  return {
    toggleValue: value,
    on,
    off,
    toggle,
  };
};
