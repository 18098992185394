import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  type IOutputNumberProps,
  type IGetPercentageStringProps,
  getOutputNumber,
  getPercentageString,
} from 'helpers/number/numberToString.helper';

interface IUseNumberFormatReturn {
  formatNumber: (props: IOutputNumberProps) => string;
  formatPercentageNumber: (props: IGetPercentageStringProps) => string;
}

const useNumberFormat = (): IUseNumberFormatReturn => {
  const { i18n } = useTranslation();

  const formatNumber = useCallback(
    ({ value, maxDigits, minDigits }: IOutputNumberProps): string => {
      return getOutputNumber({
        value,
        maxDigits,
        minDigits,
        locale: i18n.languages[0],
      });
    },
    [i18n.languages]
  );

  const formatPercentageNumber = useCallback(
    (props: IGetPercentageStringProps): string => {
      return getPercentageString({ ...props, locale: i18n.languages[0] });
    },
    [i18n.languages]
  );

  return {
    formatNumber,
    formatPercentageNumber,
  };
};

export default useNumberFormat;
