import {
  BaseQueryFn,
  EndpointBuilder,
  QueryDefinition,
} from '@reduxjs/toolkit/query';

import type {
  IContactHolidayPlansEntityArgs,
  IGetContactHolidayPlansResponse,
} from './contactHolidayPlans.api.types';
import type { ICollectionApiResponse } from 'services/api/api.types';
import type { IContactHolidayPlan } from 'interfaces/ContactHolidayPlan.interface';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';

export const getContactHolidayPlans = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IContactHolidayPlansEntityArgs,
  BaseQueryFn,
  string,
  IGetContactHolidayPlansResponse
> =>
  build.query<IGetContactHolidayPlansResponse, IContactHolidayPlansEntityArgs>({
    providesTags: ['ContactHolidayPlan'],
    query: (queryArg: IContactHolidayPlansEntityArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        contact: queryArg.contactId,
        year: queryArg.year,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);
      return {
        url: `/api/contact_out_of_office_plans${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IContactHolidayPlan>
    ): IGetContactHolidayPlansResponse =>
      transformCollectionResponse<IContactHolidayPlan>(response),
  });
