import { ChangeEvent, useCallback } from 'react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import type { TRootState } from 'store';
import type { ISelector } from 'interfaces/Selector.interface';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export interface IPagination {
  currentPage: number;
  itemsPerPage: number;
}

type TDataCollectionReduxSlicePagination = ActionCreatorWithPayload<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  `${string}/${string}`
>;

export interface IUsePaginationReturn {
  page: number;
  itemsPerPage: number;
  onChangePage: (event: ChangeEvent | null, nextPage: number) => void;
  onSetItemsPerPage: (itemsPerPage: number) => void;
  onResetPage: () => void;
}

export const usePagination = (
  initialPage: number,
  setNextPageDispatch: TDataCollectionReduxSlicePagination,
  setItemsPerPageDispatch: TDataCollectionReduxSlicePagination,
  paginationSelector: (state: TRootState) => ISelector
): IUsePaginationReturn => {
  const dispatch = useAppDispatch();

  const { pagination } = useAppSelector(paginationSelector);

  const onChangePage = useCallback(
    (_event: ChangeEvent | null, nextPage: number) => {
      dispatch(setNextPageDispatch(nextPage));
    },
    [dispatch, setNextPageDispatch]
  );

  const onSetItemsPerPage = useCallback(
    (itemsPerPage: number) => {
      dispatch(setItemsPerPageDispatch(itemsPerPage));
    },
    [dispatch, setItemsPerPageDispatch]
  );

  const onResetPage = useCallback(() => {
    dispatch(setNextPageDispatch(initialPage));
  }, [dispatch, initialPage, setNextPageDispatch]);

  return {
    page: pagination.currentPage,
    itemsPerPage: pagination.itemsPerPage,
    onChangePage,
    onSetItemsPerPage,
    onResetPage,
  };
};
