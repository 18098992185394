import type { IApplicationEmbed } from 'interfaces/Applications.interface';
import type {
  IContactEmbed,
  ISystemContact,
} from 'interfaces/Contact.interface';
import {
  checkEmbedIsApplication,
  checkEmbedIsContact,
  checkEmbedIsSystemContact,
} from 'helpers/embed/embedTypeCheck.helper';
import { teamHeroApi } from 'services/teamHeroApi.service';
import { store } from 'store';
import { getContactIdFromIriOrContact } from 'helpers/contact/getContactId.helper';

type TContactExportEmbed =
  | IContactEmbed
  | IApplicationEmbed
  | ISystemContact
  | null
  | undefined;

export const contactCellExport = async (
  contactEmbed?: TContactExportEmbed | string
): Promise<string> => {
  if (typeof contactEmbed === 'string') {
    const contactEndpoint = store.dispatch(
      teamHeroApi.endpoints.getContactItem.initiate({
        id: getContactIdFromIriOrContact(contactEmbed),
      })
    );
    contactEndpoint.unsubscribe();

    const contactData = await contactEndpoint.unwrap();

    return `${contactData?.firstName || ''} ${contactData?.lastName || ''} ${
      contactData?.email || ''
    }`;
  }

  if (checkEmbedIsSystemContact(contactEmbed)) {
    return 'system';
  }

  if (checkEmbedIsContact(contactEmbed)) {
    return `${contactEmbed?.firstName || ''} ${contactEmbed?.lastName || ''} ${
      contactEmbed?.email || ''
    }`;
  }

  if (checkEmbedIsApplication(contactEmbed)) {
    return `${contactEmbed?.firstName || ''} ${contactEmbed?.lastName || ''}`;
  }

  return '-';
};
