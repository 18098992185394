import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

interface IRatingStyledProps {
  $fill: string;
}

interface IScoreTextStyledProps {
  size: number;
}

export const RatingStyled = styled.div<IRatingStyledProps>`
  ${Utils.flexFn('flex-start')}
  gap: ${Utils.pxToRem(5)};
  cursor: pointer;

  .cls-2 {
    fill: ${({ $fill }) => $fill} !important;
  }
`;

export const ScoreTextStyled = styled.span<IScoreTextStyledProps>`
  font-size: ${({ size }) => Utils.pxToRem(size)};
`;
