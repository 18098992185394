import axios, { AxiosInstance, CreateAxiosDefaults } from 'axios';
import { buildMemoryStorage, setupCache } from 'axios-cache-interceptor';

import { getToken } from 'services/user/user.helper';
import endpointsToSkip from './endpointExcludedList';

class HttpClient {
  public httpClientFactory = (): AxiosInstance => {
    const defaultOptions: CreateAxiosDefaults = {
      baseURL: import.meta.env.VITE_API_URI,
    };
    const instance = axios.create(defaultOptions);

    instance.interceptors.request.use((config) => {
      const token = getToken();

      if (!token || endpointsToSkip.includes(config.url as string)) {
        return config;
      }
      config.headers.set('Authorization', `Bearer ${token}`);

      return config;
    });

    const storage = buildMemoryStorage();

    setupCache(instance, {
      ttl: 1000 * 60 * 5,
      methods: ['get'],
      storage,
      cacheTakeover: false,
    });

    return instance;
  };
}

export const axiosClient = new HttpClient().httpClientFactory();
