import {
  EndpointBuilder,
  QueryDefinition,
  MutationDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type {
  IApplicationDocumentFileEntityArgs,
  IApplicationDocumentFileEntry,
  IApplicationDocumentFileEntryArgs,
  IPostApplicationDocumentFileArgs,
  IDeleteApplicationDocumentFileArgs,
} from './applicationDocumentFiles.api.types';

export const getApplicationDocumentFileEntityItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IApplicationDocumentFileEntityArgs,
  BaseQueryFn,
  string,
  IApplicationDocumentFileEntry
> =>
  build.query<
    IApplicationDocumentFileEntry,
    IApplicationDocumentFileEntityArgs
  >({
    providesTags: ['ApplicationDocumentFile'],
    query: (queryArg: IApplicationDocumentFileEntityArgs) => ({
      url: `api/application_document_files/${queryArg.id}`,
    }),
  });

export const postApplicationDocumentFileEntityItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IApplicationDocumentFileEntryArgs,
  BaseQueryFn,
  string,
  IApplicationDocumentFileEntry
> =>
  build.mutation<
    IApplicationDocumentFileEntry,
    IApplicationDocumentFileEntryArgs
  >({
    invalidatesTags: ['ApplicationDocumentFile'],
    query: (queryArg) => ({
      url: `api/application_document_files`,
      method: 'POST',
      body: queryArg,
    }),
  });

export const postPublicApplicationDocumentFileEntityItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IApplicationDocumentFileEntryArgs,
  BaseQueryFn,
  string,
  IApplicationDocumentFileEntry
> =>
  build.mutation<
    IApplicationDocumentFileEntry,
    IApplicationDocumentFileEntryArgs
  >({
    invalidatesTags: ['ApplicationDocumentFile'],
    query: (queryArg) => ({
      url: `api/application_document_files/public`,
      method: 'POST',
      body: queryArg,
    }),
  });

export const postApplicationDocumentFileItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostApplicationDocumentFileArgs,
  BaseQueryFn,
  string,
  IApplicationDocumentFileEntry
> =>
  build.mutation<
    IApplicationDocumentFileEntry,
    IPostApplicationDocumentFileArgs
  >({
    invalidatesTags: ['ApplicationDocumentFile'],
    query: (queryArg) => ({
      url: `api/application_document_files/${queryArg.id}/file`,
      method: 'POST',
      body: queryArg.body,
      extraOptions: { maxRetries: 0 },
    }),
  });

export const postPublicApplicationDocumentFileItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostApplicationDocumentFileArgs,
  BaseQueryFn,
  string,
  IApplicationDocumentFileEntry
> =>
  build.mutation<
    IApplicationDocumentFileEntry,
    IPostApplicationDocumentFileArgs
  >({
    invalidatesTags: ['ApplicationDocumentFile'],
    query: (queryArg) => ({
      url: `api/application_document_files/public/${queryArg.id}/file`,
      method: 'POST',
      body: queryArg.body,
      extraOptions: { maxRetries: 0 },
    }),
  });

export const deleteApplicationDocumentFileItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IDeleteApplicationDocumentFileArgs,
  BaseQueryFn,
  string,
  unknown
> =>
  build.mutation<unknown, IDeleteApplicationDocumentFileArgs>({
    invalidatesTags: ['ApplicationDocumentFile'],
    query: (queryArg) => ({
      url: `api/application_document_files/${queryArg.id}`,
      method: 'DELETE',
    }),
  });
