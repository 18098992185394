import {
  EndpointBuilder,
  MutationDefinition,
  QueryDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type { IContact } from 'interfaces/Contact.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import type {
  IGetContactCollectionArgs,
  IGetContactCollectionResponse,
  IDeleteContactItemArgs,
  IGetContactItemArgs,
  IContactPostArgs,
  IContactPutArgs,
} from './contacts.api.type';

const defaultTotalCount = 0;
const defaultItems: IContact[] = [];

export const getContacts = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetContactCollectionArgs,
  BaseQueryFn,
  string,
  IGetContactCollectionResponse
> =>
  build.query<IGetContactCollectionResponse, IGetContactCollectionArgs>({
    providesTags: ['Contact'],
    query: (queryArg: IGetContactCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);
      return {
        url: `/api/contacts${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IContact>
    ): IGetContactCollectionResponse => {
      const totalCount =
        (response['hydra:totalItems'] as number) || defaultTotalCount;
      const items = (response['hydra:member'] as IContact[]) || defaultItems;
      return { items, totalCount };
    },
  });

export const getContactItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<IGetContactItemArgs, BaseQueryFn, string, IContact> =>
  build.query<IContact, IGetContactItemArgs>({
    providesTags: ['Contact'],
    query: (queryArg: IGetContactItemArgs) => ({
      url: `/api/contacts/${queryArg.id}`,
    }),
  });

export const postContactItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IContactPostArgs, BaseQueryFn, string, IContact> =>
  build.mutation<IContact, IContactPostArgs>({
    invalidatesTags: ['Contact'],
    query: (queryArg) => ({
      url: '/api/contacts',
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const putContactItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IContactPutArgs, BaseQueryFn, string, IContact> =>
  build.mutation<IContact, IContactPutArgs>({
    invalidatesTags: ['Contact'],
    query: (queryArg) => ({
      url: `/api/contacts/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });

export const deleteContactItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IDeleteContactItemArgs, BaseQueryFn, string, unknown> =>
  build.mutation<unknown, IDeleteContactItemArgs>({
    invalidatesTags: ['Contact'],
    query: (queryArg) => ({
      url: `/api/contacts/${queryArg.id}`,
      method: 'DELETE',
    }),
  });
