import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const ApplicationCardActiveStyled = styled.div`
  background-color: ${Theme.colors.primary.grey};
  ${flexFn('flex-start', 'center')};
  gap: ${pxToRem(0)};
  padding: ${pxToRem([12, 8])};
  border-radius: ${pxToRem(4)};
  height: ${pxToRem(64)};

  button {
    padding: 0;
  }
`;
