import { styled } from 'styled-components';
import { DropdownItem, Utils } from 'team-hero-ui';

const { pxToRem } = Utils;

export const TimelineSizeDropdownStyled = styled(DropdownItem)`
  &.MuiMenuItem-root {
    font-size: ${pxToRem(12)};
  }
`;
