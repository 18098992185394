import { TFunction } from 'i18next';
import Joi, { AnySchema } from 'joi';

const hasOnlyDigits = (value: string | number): boolean => {
  return /^[0-9]+$/.test(value.toString());
};

export const isValidNumberLength = (
  value: string | number,
  length: number
): boolean => {
  if (hasOnlyDigits(value)) {
    return value.toString().length === length;
  }

  return false;
};

const getNumberLengthValidation =
  (length: number, isFieldRequired: boolean) =>
  (value: string | number, helpers: Joi.CustomHelpers<unknown>) => {
    if (!value) {
      return isFieldRequired ? helpers.error('any.required') : value;
    }

    return isValidNumberLength(value, length)
      ? value
      : helpers.error('any.fieldLength');
  };

export const numberLengthValidationStrategy = (
  length: number,
  isFieldRequired: boolean,
  t: TFunction
): AnySchema =>
  Joi.custom(getNumberLengthValidation(length, isFieldRequired)).messages({
    'any.required': t('errors:validation.required'),
    'any.fieldLength': t('errors:validation.numberFieldLength', {
      length: length,
    }),
  });
