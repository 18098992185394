import {
  EndpointBuilder,
  QueryDefinition,
  MutationDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type { ICollectionApiResponse } from 'services/api/api.types';
import type {
  IShiftInventoriesCollectionResponse,
  IShiftInventoriesCollectionArgs,
  IGetShiftInventoryItemArgs,
  IShiftInventoryPostArgs,
  IShiftInventoryPutArgs,
  IShiftInventoryDeleteArgs,
} from './shiftInventory.api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import type { IShiftInventory } from 'interfaces/ShiftInventory.interface';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';

const baseUrl = 'api/project_shift_inventories';
const baseTag = 'ShiftInventory';

export const getShiftInventories = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IShiftInventoriesCollectionArgs,
  BaseQueryFn,
  string,
  IShiftInventoriesCollectionResponse
> =>
  build.query<
    IShiftInventoriesCollectionResponse,
    IShiftInventoriesCollectionArgs
  >({
    providesTags: [baseTag],
    query: (queryArg: IShiftInventoriesCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);

      return {
        url: `${baseUrl}${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IShiftInventory>
    ): IShiftInventoriesCollectionResponse =>
      transformCollectionResponse<IShiftInventory>(response),
  });

export const getShiftInventoryItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetShiftInventoryItemArgs,
  BaseQueryFn,
  string,
  IShiftInventory
> =>
  build.query<IShiftInventory, IGetShiftInventoryItemArgs>({
    providesTags: [baseTag],
    query: (queryArg: IGetShiftInventoryItemArgs) => ({
      url: `${baseUrl}/${queryArg.id}`,
    }),
  });

export const postShiftInventoryItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IShiftInventoryPostArgs,
  BaseQueryFn,
  string,
  IShiftInventory
> =>
  build.mutation<IShiftInventory, IShiftInventoryPostArgs>({
    invalidatesTags: [baseTag],
    query: (queryArg) => ({
      url: baseUrl,
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const putShiftInventoryItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IShiftInventoryPutArgs,
  BaseQueryFn,
  string,
  IShiftInventory
> =>
  build.mutation<IShiftInventory, IShiftInventoryPutArgs>({
    invalidatesTags: [baseTag],
    query: (queryArg) => ({
      url: `${baseUrl}/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });

export const deleteShiftInventoryItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IShiftInventoryDeleteArgs,
  BaseQueryFn,
  string,
  unknown
> =>
  build.mutation<unknown, IShiftInventoryDeleteArgs>({
    invalidatesTags: [baseTag],
    query: (queryArg) => ({
      url: `${baseUrl}/${queryArg.id}`,
      method: 'DELETE',
    }),
  });
