import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IDatePickerProps,
  DatePicker,
  AdapterMoment,
  LocalizationProvider,
} from 'team-hero-ui';
import moment from 'moment';

import { TLocale } from 'interfaces/Locale.interface';

const LocalizedDatePicker: FC<IDatePickerProps> = (props) => {
  const { i18n } = useTranslation();
  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={i18n.languages[0]}
      dateLibInstance={moment}
    >
      <DatePicker {...props} locale={i18n.languages[0] as TLocale} />
    </LocalizationProvider>
  );
};

export default LocalizedDatePicker;
