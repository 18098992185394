import { LanguageMessages } from 'joi';
import { TFunction } from 'i18next';

export const validationFieldRequired = (
  t: TFunction,
  ...messageKeys: string[]
): LanguageMessages => ({
  'string.base': t('modal.validation.fieldRequired'),
  'string.empty': t('modal.validation.fieldRequired'),
  'any.required': t('modal.validation.fieldRequired'),
  'object.base': t('modal.validation.fieldRequired'),
  ...(messageKeys || []).reduce(
    (acc, key) => ({ ...acc, [key]: t('modal.validation.fieldRequired') }),
    {}
  ),
});
