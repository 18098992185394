import { FC, useEffect } from 'react';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import type { TUserRole } from 'interfaces/Role.interface';
import useNotification from 'hooks/useNotification.hook';
import { useCheckAuth } from 'hooks/auth/useCheckAuth.hook';

export interface IRequireAuthProps {
  children: JSX.Element;
  requiredRoles?: TUserRole[];
  redirectPath?: string;
  silentCheck?: boolean;
}

const RequireAuth: FC<IRequireAuthProps> = ({
  children,
  requiredRoles = [],
  redirectPath = '/login',
  silentCheck = false,
}) => {
  const { t } = useTranslation();
  const { check, checkPasswordChangeRequired } = useCheckAuth();
  const { set } = useNotification();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (checkPasswordChangeRequired()) {
      navigate('/password-update', { state: { from: location } });
    }
    if (!check(requiredRoles) && !silentCheck) {
      set({
        type: 'error',
        message: t('errors:error.notAuthorized'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!check(requiredRoles)) {
    return <Navigate to={redirectPath} state={{ from: location }} />;
  }
  return children;
};

export default RequireAuth;
