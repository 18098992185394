import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { TRootState } from 'store';
import type { IUserState } from './user.slice.type';
import { getTokenPayload } from 'services/user/user.helper';

export const initialState: IUserState = {
  email: getTokenPayload()?.email || '',
  roles: getTokenPayload()?.roles || [],
  isLoggedIn: !!getTokenPayload()?.email,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (
      state: IUserState,
      {
        payload: {
          email = initialState.email,
          roles = initialState.roles,
          isLoggedIn,
        },
      }: PayloadAction<IUserState>
    ) => {
      state.email = email;
      state.roles = roles;
      state.isLoggedIn = isLoggedIn;
    },
    clearUser: (state: IUserState) => {
      state.email = '';
      state.roles = [];
      state.isLoggedIn = false;
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;

export const userSelector = (state: TRootState): IUserState => state.user;

export default userSlice.reducer;
