import { FC, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

import { useGetPublicSystemSettingsQuery } from 'services/publicTeamHeroApi.service';

const SentryProvider: FC = () => {
  const { data } = useGetPublicSystemSettingsQuery();

  useEffect(() => {
    if (data?.settings.sentry_dsn) {
      Sentry.init({
        dsn: data.settings.sentry_dsn,
        integrations: [
          Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          }),
        ],
        initialScope: {
          tags: {
            system_host: data.settings.system_host || 'unset',
          },
        },
        environment: 'herologin-' + import.meta.env.MODE,
        tracesSampleRate: 0.2,
        maxBreadcrumbs: 50,
        debug: import.meta.env.DEV,
      });
    }
  }, [data?.settings.sentry_dsn, data?.settings.system_host]);

  return null;
};

export default SentryProvider;
