import { FC } from 'react';
import {
  Box,
  Button,
  device,
  DraftArea,
  EditorState,
  IconButton,
  Icons,
  Input,
  Loader,
  TextField,
  Theme,
  UploadFile,
  Utils,
} from 'team-hero-ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import type {
  ICreateMessageState,
  IMessageInputProps,
} from './MessageInput.types';
import {
  MessageInputStyled,
  MobileContentInputWrapperStyled,
  MobileInputStyled,
  PlainMessageInputStyled,
  SendButtonStyled,
  SubjectMobileStyled,
  SubjectStyled,
} from './MessageInput.styled';
import { toolbarConfig } from './MessageInput.helpers';
import { useCreateMessageFormStrategies } from './useCreateMessageFormStrategies.hook';
import { useErrorHandler } from 'hooks/useDisplayApiError.hook';
import { useAppSelector } from 'store/hooks';
import { messagesSelector } from 'redux/messages/messages.slice';
import { getConversationIdFromIriOrEmbed } from 'helpers/conversation/getConversationIdFromIriOrEmbed.helper';
import { createMessageSchema } from 'pages/Messages/Conversation/MessageInput/MessageInput.schema';
import ModalValidationWrapper from 'components/Modals/AddEditModal/ModalValidationWrapper';

const { useMediaQuery } = Utils;

const MessageInput: FC<IMessageInputProps> = ({
  conversationView,
  conversationIri,
  fromContactIri,
  onCreateConversation,
}) => {
  const navigate = useNavigate();

  const isNewMessageOrConversation = [
    'new-message',
    'new-conversation',
  ].includes(conversationView);

  const { mission, contact: assignee } = useAppSelector(messagesSelector);

  const isMdScreenSize = useMediaQuery(device.md);
  const { t } = useTranslation('general');
  const { handleError } = useErrorHandler();

  const {
    initialCreateMessageState,
    isSubmittingMessage,
    createMessageStrategy,
    createMessageConversationStrategy,
  } = useCreateMessageFormStrategies();

  const { control, handleSubmit, reset, watch } = useForm<ICreateMessageState>({
    defaultValues: initialCreateMessageState,
    resolver: joiResolver(createMessageSchema(t, isNewMessageOrConversation), {
      allowUnknown: true,
    }),
  });

  const content: EditorState = watch('content');
  const mobileInput: string = watch('mobileInput');

  const hasContent =
    mobileInput.length || content?.getCurrentContent().hasText() || false;

  const onSendClick = async (state: ICreateMessageState) => {
    if (isSubmittingMessage || !fromContactIri || !hasContent) {
      return;
    }
    try {
      if (conversationView === 'new-conversation') {
        const result = await createMessageConversationStrategy({
          state,
          fromContactIri,
          isFromDesktop: isMdScreenSize,
          to: assignee ? [assignee?.['@id']] : undefined,
          assignee: assignee?.['@id'],
          related: mission ? `/api/project_missions/${mission.id}` : undefined,
        });

        if (result) {
          if (onCreateConversation) {
            onCreateConversation(result);
          } else {
            navigate(`/messages/${result.id}/details`);
          }
        }
      } else {
        const result = await createMessageStrategy({
          state,
          fromContactIri,
          isNewConversation: conversationView === 'new-message',
          isFromDesktop: isMdScreenSize,
          conversationIri,
        });
        if (result) {
          const conversationId = getConversationIdFromIriOrEmbed(
            result.conversation
          );
          if (conversationId) {
            navigate(`/messages/${conversationId}/details`);
          }
        }
      }
    } catch (error) {
      handleError(error);
    }

    reset();
  };

  return (
    <>
      {isMdScreenSize ? (
        <>
          <MessageInputStyled>
            {isNewMessageOrConversation && (
              <Controller
                key={'subject'}
                name={'subject'}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <SubjectStyled>
                    <ModalValidationWrapper text={error?.message}>
                      <Input
                        name='subject'
                        type='text'
                        width='100%'
                        value={value}
                        onChange={onChange}
                        placeholder={t('messages.subjectOrTopic')}
                      />
                    </ModalValidationWrapper>
                  </SubjectStyled>
                )}
              />
            )}
            <Controller
              key={'content'}
              name={'content'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <DraftArea
                  value={value}
                  onChange={onChange}
                  toolbarConfig={toolbarConfig}
                  theme='light'
                  toolbarButtons={
                    <Box display='flex' flex={1} justifyContent='space-between'>
                      <Box flex={1}>
                        <Controller
                          key='attachments'
                          name='attachments'
                          control={control}
                          render={({
                            field: {
                              onChange: onChangeFiles,
                              value: filesValue,
                            },
                          }) => (
                            <UploadFile
                              label={t('messages.attachFile')}
                              elementListLabel={t('messages.fileName')}
                              value={filesValue}
                              onChange={onChangeFiles}
                              styleType='light'
                              minHeight={0}
                              filesDropdown
                              filesDropdownLabel={t('fileDropdown.filesLabel')}
                              filesInline
                              iconPosition='left'
                            />
                          )}
                        />
                      </Box>
                      <Button
                        color='green'
                        onClick={() => handleSubmit(onSendClick)()}
                        disabled={!hasContent || isSubmittingMessage}
                      >
                        {isSubmittingMessage ? (
                          <Loader loaderSize={'tiny'} loaderType={'static'} />
                        ) : (
                          <>{t('messages.send')}</>
                        )}
                      </Button>
                    </Box>
                  }
                />
              )}
            />
          </MessageInputStyled>
        </>
      ) : (
        <MobileContentInputWrapperStyled>
          {isNewMessageOrConversation && (
            <Controller
              key={'subject'}
              name={'subject'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <SubjectMobileStyled>
                  <ModalValidationWrapper text={error?.message}>
                    <TextField
                      type='text'
                      value={value}
                      onChange={onChange}
                      styleType='default'
                      label={t('messages.subjectOrTopic')}
                    />
                  </ModalValidationWrapper>
                </SubjectMobileStyled>
              )}
            />
          )}
          <MobileInputStyled>
            <Controller
              key='attachments'
              name='attachments'
              control={control}
              render={({
                field: { onChange: onChangeFiles, value: filesValue },
              }) => (
                <UploadFile
                  label=''
                  elementListLabel={t('messages.fileName')}
                  value={filesValue}
                  onChange={onChangeFiles}
                  styleType='light'
                  minHeight={50}
                  filesDropdown
                  iconPosition='left'
                />
              )}
            />
            <Controller
              key={'mobileInput'}
              name={'mobileInput'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <PlainMessageInputStyled>
                  <Input
                    width='100%'
                    name='message'
                    value={value}
                    type='text'
                    onChange={onChange}
                    placeholder={t('messages.placeholder')}
                  />
                </PlainMessageInputStyled>
              )}
            />
            <SendButtonStyled>
              <IconButton
                onClick={() => handleSubmit(onSendClick)()}
                disabled={!hasContent || isSubmittingMessage}
              >
                {isSubmittingMessage ? (
                  <Loader loaderSize={'tiny'} loaderType={'static'} />
                ) : (
                  <Icons.SendIcon
                    svgColor={Theme.colors.primary.grey}
                    svgSize={24}
                  />
                )}
              </IconButton>
            </SendButtonStyled>
          </MobileInputStyled>
        </MobileContentInputWrapperStyled>
      )}
    </>
  );
};

export default MessageInput;
