import { TFunction } from 'i18next';
import Joi, { AnySchema } from 'joi';
import moment, { Moment } from 'moment';

export const isValidDate = (value: Moment): boolean =>
  value && moment(value).isValid();

const getDateValidation =
  () => (value: Moment, helpers: Joi.CustomHelpers<unknown>) => {
    return isValidDate(value) ? value : helpers.error('any.required');
  };

export const dateValidationStrategy = (t: TFunction): AnySchema =>
  Joi.custom(getDateValidation()).messages({
    'any.required': t('errors:validation.required'),
  });
