import {
  EndpointBuilder,
  QueryDefinition,
  MutationDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type { IProjectTimesheet } from 'interfaces/ProjectTimesheet.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';
import type {
  IChangeProjectTimesheetsArgs,
  IGetProjectTimesheetArgs,
  IPostProjectTimesheetsArgs,
  IProjectTimesheetCollectionArgs,
  IProjectTimesheetCollectionResponse,
} from './projectTimesheets.api.types';

export const getProjectTimesheets = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IProjectTimesheetCollectionArgs,
  BaseQueryFn,
  string,
  IProjectTimesheetCollectionResponse
> =>
  build.query<
    IProjectTimesheetCollectionResponse,
    IProjectTimesheetCollectionArgs
  >({
    providesTags: ['ProjectTimesheet'],
    query: (queryArg: IProjectTimesheetCollectionArgs) => {
      const paginationParamObject = {
        contact: queryArg.contact,
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };
      const queryParams = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);

      return {
        url: `api/project_timesheets${queryParams}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IProjectTimesheet>
    ): IProjectTimesheetCollectionResponse =>
      transformCollectionResponse<IProjectTimesheet>(response),
  });

export const getProjectTimesheetItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetProjectTimesheetArgs,
  BaseQueryFn,
  string,
  IProjectTimesheet
> =>
  build.query<IProjectTimesheet, IGetProjectTimesheetArgs>({
    providesTags: ['ProjectTimesheet'],
    query: (queryArg: IGetProjectTimesheetArgs) => ({
      url: `api/project_timesheets/${queryArg.id}`,
    }),
  });

export const postProjectTimesheets = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostProjectTimesheetsArgs,
  BaseQueryFn,
  string,
  IProjectTimesheet
> =>
  build.mutation<IProjectTimesheet, IPostProjectTimesheetsArgs>({
    invalidatesTags: ['ProjectTimesheet', 'ProjectShift'],
    query: (queryArg) => ({
      url: `/api/project_timesheets`,
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const deleteProjectTimesheets = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IChangeProjectTimesheetsArgs,
  BaseQueryFn,
  string,
  IProjectTimesheet
> =>
  build.mutation<IProjectTimesheet, IChangeProjectTimesheetsArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['ProjectTimesheet', 'ProjectShift'],
    query: (queryArg) => ({
      url: `/api/project_timesheets/${queryArg.id}`,
      method: 'DELETE',
    }),
  });

export const putProjectTimesheets = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IChangeProjectTimesheetsArgs,
  BaseQueryFn,
  string,
  IProjectTimesheet
> =>
  build.mutation<IProjectTimesheet, IChangeProjectTimesheetsArgs>({
    invalidatesTags: ['ProjectTimesheet', 'ProjectShift'],
    query: (queryArg) => ({
      url: `/api/project_timesheets/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });
