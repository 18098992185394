import { Theme } from 'team-hero-ui';

interface IStarColor {
  color: string;
  fill: string;
}

const colors = {
  grey4: Theme.colors.greyShades.grey4,
  grey2: Theme.colors.greyShades.grey2,
  yellow2: Theme.colors.others.yellow.yellow2,
  yellow1: Theme.colors.others.yellow.yellow1,
};

export const getStarColor = (score: number): IStarColor => {
  switch (Math.floor(score)) {
    case 0:
    case 1: {
      return { color: colors.grey4, fill: 'none' };
    }
    case 2: {
      return { color: colors.grey4, fill: colors.grey4 };
    }
    case 3: {
      return { color: colors.grey2, fill: colors.grey2 };
    }
    case 4: {
      return { color: colors.yellow2, fill: colors.yellow2 };
    }
    case 5: {
      return { color: colors.yellow1, fill: colors.yellow1 };
    }
    default: {
      return { color: colors.grey4, fill: 'none' };
    }
  }
};
