import { createGlobalStyle } from 'styled-components';
import { theme, Utils } from 'team-hero-ui';

const { pxToRem } = Utils;

const GlobalStyle = createGlobalStyle`
  *, *::after, *::before {
    margin: 0;
    padding: 0;
    font-size: ${pxToRem(16)};
    box-sizing:border-box;
    font-family: 'Mulish', sans-serif;
  }

  /* Scroll bar stylings */
  ::-webkit-scrollbar {
    width: ${pxToRem(10)};
    height: ${pxToRem(6)};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${theme.colors.greyShades.grey4};
    border-radius: ${pxToRem(5)};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.greyShades.grey3};
    border-radius: ${pxToRem(5)};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${theme.colors.greyShades.grey2};
  }
`;

export default GlobalStyle;
