import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

interface IValidationWrapperProps {
  $validationStyles: boolean;
  $flex?: number;
}

export const ValidationWrapperStyled = styled.div<IValidationWrapperProps>`
  position: relative;
  border-style: solid;
  border-radius: 5px;
  border-width: ${pxToRem(1)};
  border-color: ${({ $validationStyles }) =>
    $validationStyles ? Theme.colors.primary.red : 'transparent'};
  flex: ${({ $flex }) => $flex};
  ${flexFn('flex-start', 'stretch', 'column')};
`;

export const InputWrapperStyled = styled.div`
  flex: 1;
`;

export const ValidationLabelStyled = styled.div`
  font-size: ${pxToRem(12)};
  // position: absolute;
  padding: ${pxToRem([0, 12])};
  color: ${Theme.colors.primary.red};
  font-weight: 800;
`;
