import { FC } from 'react';
import { Loader } from 'team-hero-ui';

import { ApplicationSedcardStyled } from './ApplicationSedcard.styled';
import type { IApplicationSedcardProps } from './ApplicationSedcard.types';
import ApplicationSedcardSection from './ApplicationSedcardSection';

const ApplicationSedcard: FC<IApplicationSedcardProps> = ({
  application,
  missionId,
  contact,
  isLoading,
  connectedApplicationIds,
  onClose,
}) => {
  return (
    <ApplicationSedcardStyled>
      {!isLoading && contact && missionId ? (
        <ApplicationSedcardSection
          clientStatus={application?.clientStatus}
          missionId={missionId}
          contact={contact}
          connectedApplicationIds={connectedApplicationIds}
          onClose={onClose}
        />
      ) : (
        <Loader loaderType='absolute' />
      )}
    </ApplicationSedcardStyled>
  );
};

export default ApplicationSedcard;
