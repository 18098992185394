import {
  BaseQueryFn,
  MutationDefinition,
  EndpointBuilder,
  QueryDefinition,
} from '@reduxjs/toolkit/query';

import { transformCollectionResponse } from 'services/teamHeroApi.helpers';
import type {
  IGetMessagesArgs,
  IGetMessagesResponse,
  IPostMessageArgs,
  IPutMessageArgs,
} from './messages.api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import type { ICollectionApiResponse } from 'services/api/api.types';
import type { IMessage } from 'interfaces/Message.interface';

export const getMessages = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetMessagesArgs,
  BaseQueryFn,
  string,
  IGetMessagesResponse
> =>
  build.query<IGetMessagesResponse, IGetMessagesArgs>({
    providesTags: ['Message'],
    query: (queryArg: IGetMessagesArgs) => {
      const paginationParamObject = {
        conversation: queryArg?.conversation,
        page: queryArg?.page,
        itemsPerPage: queryArg?.itemsPerPage,
        pagination: queryArg?.pagination,
        ...queryArg?.order,
      };
      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg?.filters);
      return {
        url: `/api/messages${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IMessage>
    ): IGetMessagesResponse => transformCollectionResponse<IMessage>(response),
  });

export const getMessage = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<number, BaseQueryFn, string, IMessage> =>
  build.query<IMessage, number>({
    providesTags: ['Message'],
    query: (id) => {
      return {
        url: `/api/messages/${id}`,
      };
    },
  });

export const postMessage = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IPostMessageArgs, BaseQueryFn, string, IMessage> =>
  build.mutation<IMessage, IPostMessageArgs>({
    invalidatesTags: ['Message', 'MessageConversation'],
    query: (queryArg) => ({
      url: '/api/messages',
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const putMessage = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IPutMessageArgs, BaseQueryFn, string, IMessage> =>
  build.mutation<IMessage, IPutMessageArgs>({
    invalidatesTags: ['Message', 'MessageConversation'],
    query: (queryArg) => ({
      url: `/api/messages/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });

export const deleteMessage = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<number, BaseQueryFn, string, void> =>
  build.mutation<void, number>({
    invalidatesTags: ['Message'],
    query: (id) => ({
      url: `/api/messages/${id}`,
      method: 'DELETE',
    }),
  });
