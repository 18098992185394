import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const ContactSummaryWrapperStyled = styled.div`
  flex-wrap: wrap;
  gap: ${pxToRem(5)};
  ${flexFn('flex-start', 'end', 'row')}
  align-content: flex-end;
  align-self: stretch;
  flex-grow: 1;
`;

export const ContactSummaryMoreIndicatorStyled = styled.div`
  padding: ${pxToRem([0, 3])};
  font-size: ${pxToRem(9)};
  font-weight: 800;
  height: ${pxToRem(30)};
  ${flexFn('center', 'center')}
`;
