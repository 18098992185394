import {
  EndpointBuilder,
  MutationDefinition,
  QueryDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type { IApplication } from 'interfaces/Applications.interface';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';
import type {
  IDeleteApplicationItemArgs,
  IGetApplicationCollectionArgs,
  IGetApplicationCollectionResponse,
  IGetApplicationItemArgs,
  IPostApplicationItemArgs,
  IPutApplicationItemArgs,
} from './applications.api.type';

export const getApplications = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetApplicationCollectionArgs,
  BaseQueryFn,
  string,
  IGetApplicationCollectionResponse
> =>
  build.query<IGetApplicationCollectionResponse, IGetApplicationCollectionArgs>(
    {
      providesTags: ['Application'],
      query: (queryArg: IGetApplicationCollectionArgs) => {
        const paginationParamObject = {
          page: queryArg.page,
          itemsPerPage: queryArg.itemsPerPage,
          pagination: queryArg.pagination,
          ...queryArg.order,
        };
        const paginationQuery = serializeFlatObject(paginationParamObject, '?');
        const filterQuery = serializeFilters(queryArg.filters);
        return {
          url: `api/applications${paginationQuery}${filterQuery}`,
        };
      },
      transformResponse: (
        response: ICollectionApiResponse<IApplication>
      ): IGetApplicationCollectionResponse =>
        transformCollectionResponse<IApplication>(response),
    }
  );

export const getApplicationItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetApplicationItemArgs,
  BaseQueryFn,
  string,
  IApplication
> =>
  build.query<IApplication, IGetApplicationItemArgs>({
    providesTags: ['Application'],
    query: (queryArg: IGetApplicationItemArgs) => ({
      url: `api/applications/${queryArg.id}`,
    }),
  });

export const postApplicationItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostApplicationItemArgs,
  BaseQueryFn,
  string,
  IApplication
> =>
  build.mutation<IApplication, IPostApplicationItemArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['Application'],
    query: (queryArg) => ({
      url: `api/applications`,
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const postPublicApplicationItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPostApplicationItemArgs,
  BaseQueryFn,
  string,
  IApplication
> =>
  build.mutation<IApplication, IPostApplicationItemArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['Application'],
    query: (queryArg) => ({
      url: `api/applications/public`,
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const putApplicationItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IPutApplicationItemArgs,
  BaseQueryFn,
  string,
  IApplication
> =>
  build.mutation<IApplication, IPutApplicationItemArgs>({
    invalidatesTags: (_result, _error, args) =>
      args.skipInvalidateTags ? [] : ['Application'],
    query: (queryArg) => ({
      url: `api/applications/${queryArg.id}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });

export const deleteApplicationItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<
  IDeleteApplicationItemArgs,
  BaseQueryFn,
  string,
  unknown
> =>
  build.mutation<unknown, IDeleteApplicationItemArgs>({
    invalidatesTags: ['Application'],
    query: (queryArg) => ({
      url: `api/applications/${queryArg.id}`,
      method: 'DELETE',
    }),
  });
