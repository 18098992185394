import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { TRootState } from 'store';
import type { INotificationState } from './notification.slice.type';

export const initialState: INotificationState = {
  type: undefined,
  message: '',
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification: (
      state: INotificationState,
      {
        payload: { message = '', type = undefined },
      }: PayloadAction<INotificationState>
    ) => {
      state.message = message;
      state.type = type;
    },
    clearNotification: (state: INotificationState) => {
      state.type = initialState.type;
      state.message = initialState.message;
    },
  },
});

export const { setNotification, clearNotification } = notificationSlice.actions;

export const notificationSelector = (state: TRootState): INotificationState =>
  state.notification;

export default notificationSlice.reducer;
