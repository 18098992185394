import { createAsyncThunk } from '@reduxjs/toolkit';

import { removeUserSession } from 'services/user/user.helper';
import { teamHeroApi } from 'services/teamHeroApi.service';
import { setNotification } from 'redux/notification/notification.slice';
import { clearUser } from './user.slice';

export const logout = createAsyncThunk(
  'user/logout',
  async (_, { dispatch }) => {
    removeUserSession();
    dispatch(clearUser());
    // resets all teamHeroApi Caches and invalidates all tags
    dispatch(teamHeroApi.util.resetApiState());
  }
);

export const logoutByForce = createAsyncThunk(
  'user/logoutByForce',
  async (_, { dispatch }) => {
    removeUserSession();
    dispatch(clearUser());
    dispatch(
      setNotification({
        type: 'error',
        message: `You have been logged out`,
      })
    );
    // reset all teamHeroApi Caches and invalidates all tags
    dispatch(teamHeroApi.util.resetApiState());
  }
);
