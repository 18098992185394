import React, { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

type TSearchParamName = 'view' | 'reportType' | 'modal';
interface IUseSearchUrlParamsReturn<T extends string> {
  selectedSearchParam: T;
  deleteParam: (name: string) => void;
  onSetSearchParam: (_event: React.ChangeEvent | null, val: T) => void;
  onApplySearchParam: (val: T) => void;
}

interface IUseSearchUrlParamsProps<T extends string> {
  defaultValue?: T;
  searchParamName?: TSearchParamName;
}

export const useSearchUrlParams = <T extends string>({
  defaultValue,
  searchParamName = 'view',
}: IUseSearchUrlParamsProps<T>): IUseSearchUrlParamsReturn<T> => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onApplySearchParam = useCallback(
    (value: T) => {
      searchParams.set(searchParamName, value);
      setSearchParams(searchParams);
    },
    [searchParams, searchParamName, setSearchParams]
  );

  const onSetSearchParam = useCallback(
    (_event: React.ChangeEvent | null, val: T, replace = false) => {
      setSearchParams({ [searchParamName]: val }, { replace });
    },
    [searchParamName, setSearchParams]
  );

  const deleteParam = (name: string) => {
    searchParams.delete(name);
    setSearchParams(searchParams);
  };

  const selectedSearchParam = ((searchParams.get(searchParamName) as T) ||
    defaultValue) as T;

  useEffect(() => {
    if (searchParams.has(searchParamName)) {
      return;
    }
    if (defaultValue) {
      onSetSearchParam(null, defaultValue, true);
    }
  }, [defaultValue, onSetSearchParam, searchParamName, searchParams]);

  return {
    selectedSearchParam,
    onSetSearchParam,
    onApplySearchParam,
    deleteParam,
  };
};
