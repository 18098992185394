import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

interface IApplicationAreaStyledProps {
  $isDraggingOver: boolean;
  $isNoFit: boolean;
}

export const ApplicationAreaStyled = styled.div<IApplicationAreaStyledProps>`
  ${flexFn('flex-start', 'stretch', 'column')};
  padding: ${pxToRem(24)};
  gap: ${pxToRem(24)};
  border: ${pxToRem(2)} dashed ${Theme.colors.greyShades.grey4};
  border-radius: ${pxToRem(4)};
  flex: 1;
  overflow: auto;

  background-color: ${({ $isDraggingOver, $isNoFit }) =>
    $isDraggingOver
      ? $isNoFit
        ? Theme.colors.others.red.red3
        : Theme.colors.others.green.green3
      : `${Theme.colors.greyShades.grey4}80`};

  transition: all 0.2s ease-in-out;
`;

export const ApplicationAreaHeaderStyled = styled.div`
  ${flexFn('flex-start', 'center')};
  gap: ${pxToRem(8)};
  text-transform: uppercase;
  font-weight: 600;
`;

export const ApplicationDroppableAreaStyled = styled.div`
  ${flexFn('flex-start', 'start')};
  gap: ${pxToRem(12)};
  flex-wrap: wrap;
  min-height: ${pxToRem(64)};
  height: fit-content;
  flex: 1 1 0;
  overflow: auto;
`;

interface IAvatarDraggableStyledProps {
  $isNoFit: boolean;
}

export const AvatarDraggableStyled = styled.div<IAvatarDraggableStyledProps>`
  opacity: ${({ $isNoFit }) => ($isNoFit ? 0.5 : 1)};
`;
