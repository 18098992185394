import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

export const ContactHeaderAvatarStyled = styled.div`
  flex: 0;
  height: 100%;
  ${flexFn('center', 'center', 'column')}
  gap: ${pxToRem(20)};
  width: 100%;
`;

export const AvatarWrapperStyled = styled.div`
  width: ${pxToRem(300)};
  min-height: ${pxToRem(180)};
  text-align: -moz-center;
  text-align: -webkit-center;

  & .MuiBadge-root {
    ${flexFn()};
  }
`;

export const NationalityContainerStyled = styled.div`
  color: ${Theme.colors.primary.grey};
  margin: ${pxToRem([0, 0, 25, 30])};
  ${flexFn()}
  gap: ${pxToRem(3)};
  align-self: flex-start;
`;
