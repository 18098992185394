import { styled, css } from 'styled-components';
import { Utils } from 'team-hero-ui';

import { CommonCellContentStyled } from 'components/cellRenderers/Common.styled';

const { flexFn, pxToRem } = Utils;

interface IGrayedCellRendererStyledProps {
  $height?: number;
  $bgColor: string;
  $color?: string;
  $bold: boolean;
  $withoutLeftPadding?: boolean;
  $roundedCornersLeft?: boolean;
  $roundedCornersRight?: boolean;
  $withTextOverflow?: boolean;
}

interface IBackgroundContainerProps {
  $bgColor?: string;
  $separatorRight: number;
}

const roundedCornersLeftCSS = css`
  border-top-left-radius: ${pxToRem(6)};
  border-bottom-left-radius: ${pxToRem(6)};
`;

const roundedCornersRightCSS = css`
  border-top-right-radius: ${pxToRem(6)};
  border-bottom-right-radius: ${pxToRem(6)};
`;

const textOverflowCSS = css`
  z-index: 1;
  white-space: nowrap;
`;

export const BackgroundContainerStyled = styled.div<IBackgroundContainerProps>`
  height: 100%;
  width: 100%;
  ${flexFn('center', 'stretch', 'column')}
  background-color: ${({ $bgColor }) => $bgColor ?? 'transparent'};
  padding-right: ${({ $separatorRight }) => pxToRem($separatorRight)};
`;

export const GrayedCellRendererStyled = styled(
  CommonCellContentStyled
)<IGrayedCellRendererStyledProps>`
  font-weight: ${({ $bold }) => ($bold ? 600 : 400)};
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ $color }) => $color || 'inherit'};
  width: 100%;
  height: ${({ $height }) => ($height ? pxToRem($height) : '100%')};
  ${flexFn('flex-start', 'center')}
  gap:${pxToRem(5)};
  padding-left: ${({ $withoutLeftPadding }) =>
    pxToRem($withoutLeftPadding ? 0 : 10)};
  ${({ $roundedCornersLeft }) => $roundedCornersLeft && roundedCornersLeftCSS};
  ${({ $roundedCornersRight }) =>
    $roundedCornersRight && roundedCornersRightCSS};
  ${({ $withTextOverflow }) => $withTextOverflow && textOverflowCSS};
`;
