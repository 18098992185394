const doesIdentifierMatchKeyboardEvent = (
  keyboardEvent: KeyboardEvent,
  identifier: string | number
): boolean => {
  return (
    keyboardEvent.key === identifier ||
    keyboardEvent.code === identifier ||
    keyboardEvent.keyCode === identifier ||
    keyboardEvent.which === identifier ||
    keyboardEvent.charCode === identifier
  );
};

export { doesIdentifierMatchKeyboardEvent };
