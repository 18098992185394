import { FC } from 'react';
import moment from 'moment';

import { useDateFormat } from 'hooks/useDateFormat.hook';
import {
  OverlappingDaysStyled,
  ShiftEndStyled,
  ShiftStartStyled,
  ShiftEndOverlayStyled,
  WrapperStyled,
  OverlappingDaysNumberStyled,
} from 'components/cellRenderers/DurationCellRenderer/DurationCellRenderer.styled';

interface IDurationCellRendererProps {
  start?: string; // date-string
  end?: string; // date-string
  showOverlapping?: boolean;
}

export const DurationCellRenderer: FC<IDurationCellRendererProps> = ({
  start,
  end,
  showOverlapping = true,
}) => {
  const { formatDate } = useDateFormat();

  const durationDays =
    start && end
      ? Math.floor(
          moment
            .duration(moment(end).diff(moment(start).startOf('day')))
            .asDays()
        )
      : 0;

  return (
    <WrapperStyled>
      <ShiftStartStyled>
        {start ? formatDate(start, 'time') : ''}&nbsp;-&nbsp;
      </ShiftStartStyled>
      <ShiftEndStyled>
        {showOverlapping && durationDays > 0 ? (
          <OverlappingDaysStyled>
            <ShiftEndOverlayStyled>
              {end ? formatDate(end, 'time') : ''}
            </ShiftEndOverlayStyled>
            <OverlappingDaysNumberStyled>
              +{durationDays}
            </OverlappingDaysNumberStyled>
          </OverlappingDaysStyled>
        ) : (
          <>{end ? formatDate(end, 'time') : ''}</>
        )}
      </ShiftEndStyled>
    </WrapperStyled>
  );
};
