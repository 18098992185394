import { FC, useMemo } from 'react';

import CollapsibleGroup from 'components/Timeline/components/CollapsibleGroup';
import { IGroupWrapperProps } from './GroupWrapper.types';

const GroupWrapper: FC<IGroupWrapperProps> = ({
  children,
  groupLabel,
  timelineWidth = 200,
  leftLegendWidth = 200,
  rightLegendWidth = 200,
  open = false,
}) => {
  const centerTopBarWidth = useMemo(
    () => timelineWidth - leftLegendWidth - rightLegendWidth,
    [leftLegendWidth, rightLegendWidth, timelineWidth]
  );

  return (
    <CollapsibleGroup
      open={open}
      centerWidth={centerTopBarWidth}
      leftLegendWidth={leftLegendWidth}
      rightLegendWidth={rightLegendWidth}
      groupLabel={groupLabel}
      children={children}
    />
  );
};

export default GroupWrapper;
