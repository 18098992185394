import { FC } from 'react';

import useNumberFormat from 'hooks/numbers/useNumberFormat.hook';

interface IFormattedNumberProps {
  value: number;
  maxDigits?: number;
  minDigits?: number;
}

const FormattedNumber: FC<IFormattedNumberProps> = ({
  value,
  maxDigits,
  minDigits,
}) => {
  const { formatNumber } = useNumberFormat();

  return <>{formatNumber({ value, maxDigits, minDigits })}</>;
};

export default FormattedNumber;
