import { FC } from 'react';
import { Controller } from 'react-hook-form';

import {
  FromToDateRenderer,
  SelectRenderer,
  SwitchRenderer,
  TextInputRenderer,
  DatePickerRenderer,
  DateTimePickerRenderer,
  MultiSelectRenderer,
  MultiSelectFreeFlowRenderer,
  SwitchNullableRenderer,
} from 'components/FilterPanel/filterRenderers';
import {
  FilterElementStyled,
  FilterLeftElementStyled,
} from '../filterRenderers/FilterElement.styled';
import { FromToNumberRenderer } from '../filterRenderers/FromToNumberRenderer/FromToNumberRenderer';
import { IFilterItemProps } from './FilterItem.type';

export const FilterItem: FC<IFilterItemProps> = ({
  filterDefinition,
  control,
  styleType,
}) => {
  const {
    key,
    label,
    filterType,
    selectData,
    multiSelectData,
    externalValueType,
    switchData,
  } = filterDefinition;
  switch (filterType) {
    case 'input':
    case 'text':
    case 'float':
    case 'number':
      return (
        <Controller
          key={key}
          name={key}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextInputRenderer
              key={key}
              label={label}
              onChange={onChange}
              value={value}
              styleType={styleType}
            />
          )}
        />
      );
    case 'select':
      return (
        <Controller
          key={key}
          name={key}
          control={control}
          render={({ field: { onChange, value } }) => (
            <FilterElementStyled>
              <SelectRenderer
                key={key}
                label={label}
                staticValues={selectData?.values || []}
                externalValuesType={externalValueType}
                onChange={onChange}
                value={value}
                styleType={styleType}
              />
            </FilterElementStyled>
          )}
        />
      );
    case 'contactMultiSelect':
    case 'multiSelect':
      return (
        <Controller
          key={key}
          control={control}
          name={key}
          render={({ field: { onChange, value } }) => (
            <FilterElementStyled>
              <MultiSelectRenderer
                key={key}
                label={label}
                staticValues={multiSelectData?.values || []}
                externalValuesType={externalValueType}
                onChange={onChange}
                value={value}
                styleType={styleType}
              />
            </FilterElementStyled>
          )}
        />
      );
    case 'multiSelectFreeFlow':
      return (
        <Controller
          key={key}
          control={control}
          name={key}
          render={({ field: { onChange, value } }) => (
            <FilterLeftElementStyled>
              <div>
                <MultiSelectFreeFlowRenderer
                  key={key}
                  label={label}
                  value={value}
                  onChange={onChange}
                  styleType={styleType}
                  staticValues={selectData?.values || []}
                />
              </div>
            </FilterLeftElementStyled>
          )}
        />
      );
    case 'fromToDate':
      return (
        <Controller
          key={key}
          name={key}
          control={control}
          render={({ field: { onChange, value } }) => (
            <FromToDateRenderer
              key={key}
              label={label}
              onChange={onChange}
              value={value}
              styleType={styleType}
            />
          )}
        />
      );
    case 'fromToNumber':
      return (
        <Controller
          key={key}
          name={key}
          control={control}
          render={({ field: { onChange, value } }) => (
            <FromToNumberRenderer
              key={key}
              label={label}
              onChange={onChange}
              value={value}
              styleType={styleType}
            />
          )}
        />
      );
    case 'datePicker':
    case 'date':
      return (
        <Controller
          key={key}
          name={key}
          control={control}
          render={({ field: { onChange, value } }) => (
            <DatePickerRenderer
              key={key}
              label={label}
              onChange={onChange}
              value={value}
              styleType={styleType}
            />
          )}
        />
      );
    case 'dateTimePicker':
      return (
        <Controller
          key={key}
          name={key}
          control={control}
          render={({ field: { onChange, value } }) => (
            <DateTimePickerRenderer
              key={key}
              label={label}
              onChange={onChange}
              value={value}
              styleType={styleType}
            />
          )}
        />
      );
    case 'switch':
      return (
        <Controller
          key={key}
          name={key}
          control={control}
          render={({ field: { onChange, value } }) => {
            if (switchData?.isNullable) {
              return (
                <SwitchNullableRenderer
                  key={key}
                  label={label}
                  onChange={onChange}
                  value={value}
                  styleType={styleType}
                />
              );
            }
            return (
              <SwitchRenderer
                key={key}
                label={label}
                onChange={onChange}
                value={value}
                styleType={styleType}
              />
            );
          }}
        />
      );
    default:
      return <div>filter Type not implemented yet</div>;
  }
};
