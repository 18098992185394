import { BeginSeparatorCellRendererStyled } from './BeginSeparatorCellRenderer.styled';

export const BeginSeparatorCellRenderer = (
  onClick?: () => void,
  disabled?: boolean
): JSX.Element => {
  return (
    <BeginSeparatorCellRendererStyled disabled={disabled} onClick={onClick} />
  );
};
