import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'team-hero-ui';

import { SliderStyled, TabStyled, TabsWrapperStyled } from './Tabs.styled';

export type TTabsView = 'new' | 'applied' | 'active' | 'done';

interface ITabsProps {
  selectedTabValue: TTabsView;
  onSelectTab: (val: TTabsView) => void;
}

const tabsWidth: { [key in TTabsView]: string } = {
  new: '0%',
  applied: '25%',
  active: '50%',
  done: '75%',
};

export const Tabs: FC<ITabsProps> = ({ onSelectTab, selectedTabValue }) => {
  const { t } = useTranslation('general');

  return (
    <TabsWrapperStyled $sliderStartValue={tabsWidth[selectedTabValue]}>
      <Button color='ripple' onClick={() => onSelectTab('new')}>
        <TabStyled
          $isSelected={selectedTabValue === 'new'}
          data-test-id='search-new-tab'
        >
          {t('work.tabs.new')}
        </TabStyled>
      </Button>
      <Button color='ripple' onClick={() => onSelectTab('applied')}>
        <TabStyled
          $isSelected={selectedTabValue === 'applied'}
          data-test-id='search-applied-tab'
        >
          {t('work.tabs.applied')}
        </TabStyled>
      </Button>
      <Button color='ripple' onClick={() => onSelectTab('active')}>
        <TabStyled
          $isSelected={selectedTabValue === 'active'}
          data-test-id='search-active-tab'
        >
          {t('work.tabs.active')}
        </TabStyled>
      </Button>
      <Button color='ripple' onClick={() => onSelectTab('done')}>
        <TabStyled
          $isSelected={selectedTabValue === 'done'}
          data-test-id='search-done-tab'
        >
          {t('work.tabs.done')}
        </TabStyled>
      </Button>
      <SliderStyled />
    </TabsWrapperStyled>
  );
};
