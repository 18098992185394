import { FC } from 'react';
import { TextField } from 'team-hero-ui';

import { IFilterRendererProps } from 'components/FilterPanel/FilterPanel.type';
import { FilterElementStyled } from 'components/FilterPanel/filterRenderers/FilterElement.styled';

export const TextInputRenderer: FC<IFilterRendererProps> = ({
  label,
  onChange,
  value,
  styleType,
}) => {
  return (
    <FilterElementStyled>
      <TextField
        onChange={onChange}
        value={value}
        label={label}
        styleType={styleType}
      />
    </FilterElementStyled>
  );
};
