import type {
  IProjectShiftWorkplanner,
  IProjectShiftWorkplannerApiResponse,
} from 'interfaces/ProjectShift.interface';
import type {
  ICollection,
  ICollectionApiResponse,
} from 'services/api/api.types';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';

export const transformShiftWorkplannerCollectionResponse = (
  response: ICollectionApiResponse<IProjectShiftWorkplannerApiResponse>
): ICollection<IProjectShiftWorkplanner> => {
  const { items, totalCount, nextPage, prevPage } =
    transformCollectionResponse(response);

  const transformedItems: IProjectShiftWorkplanner[] = items.map((item) => ({
    ...item,
    isAllDay: item.allDay,
    isBriefed: item.briefed,
    hasSwapRequested: item.swapRequested,
    isReminded: item.reminded,
  }));

  return {
    items: transformedItems,
    totalCount,
    nextPage,
    prevPage,
  };
};
