import { TFunction } from 'i18next';
import Joi, { AnySchema, ErrorReport } from 'joi';

const checkRedundancy = (ca: number[], value: string[]): number => {
  ca.forEach((_, index) => {
    ca[+value[index]]++;
  });

  let ng1 = 0;
  let ng3 = 0;

  ca.forEach((caItem) => {
    if (caItem > 1) {
      ng1++;

      if (caItem > 3) {
        ng3++;
      }
    }
  });

  let err = 0;

  if (ng1 < 1) err += 1;
  if (ng1 > 1) err += 2;
  if (ng3 > 0) err += 4;

  return err;
};

const getTaxIdentificationNumberValidation =
  (fieldRequired = false) =>
  (
    value: string,
    helpers: Joi.CustomHelpers<unknown>
  ): string | ErrorReport => {
    const invalidValueError = helpers.error('any.valueInvalid');
    const fieldRequiredError = helpers.error('any.required');

    if (!value) {
      return fieldRequired ? fieldRequiredError : value;
    }

    if (!/^[0-9]*$/.test(value)) return invalidValueError;

    const ca = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const valueArray = value.split('');
    const redundancy = checkRedundancy(ca, valueArray);

    if (redundancy === 1) return invalidValueError;
    else if (redundancy > 3) return invalidValueError;
    else if (redundancy === 2) return invalidValueError;

    let sum = 0;
    let prod = 10;

    ca.forEach((_, index) => {
      sum = (+valueArray[index] + prod) % 10;

      if (sum < 1) sum = 10;

      prod = (sum * 2) % 11;
    });

    if (!((11 - prod) % 10 === +valueArray[10])) return invalidValueError;

    return value;
  };

export const taxIdentificationNumberValidation = (
  isFieldRequired: boolean,
  t: TFunction
): AnySchema =>
  Joi.custom(getTaxIdentificationNumberValidation(isFieldRequired)).messages({
    'any.valueInvalid': t('modal.labels.invalidTaxIdentificationNumber'),
    'any.required': t('modal.validation.fieldRequired'),
  });
