import { FC, useCallback, useEffect } from 'react';
import { SubmitHandler, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SidePopup } from 'team-hero-ui';

import useKey from 'hooks/keys/useKey.hook';
import { ModalContentStyled } from './FilterModal.styled';
import { IFilterModalProps } from './FilterPanel.type';
import { transformNestedValuesToDispatchValues } from './helpers/transformValues.helper';
import { useInitialFilterValues } from './hooks/useInitialFilterValues.hook';
import FilterInputs from './filterRenderers/FilterInputs';

export const FilterModal: FC<IFilterModalProps> = ({
  filterDefinitionList,
  filterData,
  hidden,
  onClose = () => {},
  control,
  handleSubmit,
  reset,
}) => {
  const { t } = useTranslation('general');
  const { initialValues, initialValuesObject, initialValuesResetObject } =
    useInitialFilterValues(
      filterDefinitionList,
      filterData.filters,
      filterData.initialFiltersSet
    );

  const handleClearFilters = () => {
    filterData.onClearFilters();
    reset(initialValuesResetObject);
    onClose();
  };

  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (nestedValues, e) => {
      e?.preventDefault();

      filterData.onSetFilters(
        transformNestedValuesToDispatchValues(
          nestedValues,
          filterDefinitionList
        )
      );
      onClose();
    },
    [filterData, filterDefinitionList, onClose]
  );

  const setupInitialValuesInForm = useCallback(() => {
    reset(initialValuesObject);
    handleSubmit(onSubmit)();
  }, [reset, handleSubmit, onSubmit, initialValuesObject]);

  useEffect(() => {
    if (initialValues.length) {
      setupInitialValuesInForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useKey('Enter', () => handleSubmit(onSubmit)(), !hidden, ['keypress']);

  return (
    <SidePopup
      popupDirection='left'
      titleLabel={t('filters.filter')}
      isOpen={!hidden}
      onClose={onClose}
      popupColor='grey'
      dataTestId='filter-modal'
    >
      <ModalContentStyled>
        <FilterInputs
          control={control}
          filterDefinitionList={filterDefinitionList}
          handleClearFilters={handleClearFilters}
          handleSubmit={handleSubmit(onSubmit)}
          displayType='modal'
        />
      </ModalContentStyled>
    </SidePopup>
  );
};
