import {
  EndpointBuilder,
  QueryDefinition,
  MutationDefinition,
  BaseQueryFn,
} from '@reduxjs/toolkit/query';

import type {
  IContactKfbDaysPostArgs,
  IContactKfbDaysPutArgs,
  IGetKfbDaysCollectionArgs,
  IGetKfbDaysCollectionResponse,
} from './contactKfbDays.api.types';
import type { ICollectionApiResponse } from 'services/api/api.types';
import {
  serializeFilters,
  serializeFlatObject,
} from 'services/helper/getPaginationParams.helper';
import type { IContact } from 'interfaces/Contact.interface';
import { transformCollectionResponse } from 'services/teamHeroApi.helpers';
import type { IContactKfbDay } from 'interfaces/ContactKfbDay.interface';

export const getContactKfbDays = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): QueryDefinition<
  IGetKfbDaysCollectionArgs,
  BaseQueryFn,
  string,
  IGetKfbDaysCollectionResponse
> =>
  build.query<IGetKfbDaysCollectionResponse, IGetKfbDaysCollectionArgs>({
    providesTags: ['ContactKfbDays'],
    query: (queryArg: IGetKfbDaysCollectionArgs) => {
      const paginationParamObject = {
        page: queryArg.page,
        itemsPerPage: queryArg.itemsPerPage,
        pagination: queryArg.pagination,
        ...queryArg.order,
      };

      const paginationQuery = serializeFlatObject(paginationParamObject, '?');
      const filterQuery = serializeFilters(queryArg.filters);

      return {
        url: `/api/contact_kfb_days${paginationQuery}${filterQuery}`,
      };
    },
    transformResponse: (
      response: ICollectionApiResponse<IContactKfbDay>
    ): IGetKfbDaysCollectionResponse => transformCollectionResponse(response),
  });

export const postContactKfbDaysItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IContactKfbDaysPostArgs, BaseQueryFn, string, IContact> =>
  build.mutation<IContact, IContactKfbDaysPostArgs>({
    invalidatesTags: ['ContactKfbDays'],
    query: (queryArg) => ({
      url: '/api/contact_kfb_days',
      method: 'POST',
      body: queryArg.body,
    }),
  });

export const putContactKfbDaysItem = (
  build: EndpointBuilder<BaseQueryFn, string, string>
): MutationDefinition<IContactKfbDaysPutArgs, BaseQueryFn, string, IContact> =>
  build.mutation<IContact, IContactKfbDaysPutArgs>({
    invalidatesTags: ['ContactKfbDays'],
    query: (queryArg) => ({
      url: `/api/contact_kfb_days/${queryArg.kfbId}`,
      method: 'PUT',
      body: queryArg.body,
    }),
  });
