import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IDatePickerRangeProps,
  DatePickerRange,
  AdapterMoment,
  LocalizationProvider,
} from 'team-hero-ui';
import moment from 'moment';

import { TLocale } from 'interfaces/Locale.interface';

const LocalizedDatePickerRange: FC<IDatePickerRangeProps> = (props) => {
  const { i18n } = useTranslation();
  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={i18n.languages[0]}
      dateLibInstance={moment}
    >
      <DatePickerRange {...props} locale={i18n.languages[0] as TLocale} />
    </LocalizationProvider>
  );
};

export default LocalizedDatePickerRange;
